import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createLogger } from 'redux-logger';
import { isLowerEnv } from '@/config/utils';
import appStateSlice from './slice.app-state';
import groupSlice from './slice.group';
import userReducer from './slice.user';
import workspaceReducer from './slice.workspace';

const logger = createLogger({
  level: 'log',
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  middleware: getDefaultMiddleware => {
    const mw = getDefaultMiddleware();
    if (isLowerEnv()) {
      return mw.concat(logger);
    }
    return mw;
  },
  reducer: {
    appState: appStateSlice,
    group: groupSlice,
    user: userReducer,
    workspace: workspaceReducer,
  },
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;