import { z } from 'zod';

export enum InteractionTypeEnum {
  CallCenterInteraction = 'call-center-interaction',
  FbmsInteraction = 'fbms-interaction',
  MedicalInsight = 'medical-insight',
  DocumentedInterest = 'documented-interest',
  WebInteraction = 'web-interaction',
}

export const InteractionTypeModel = z.nativeEnum(InteractionTypeEnum);
export type InteractionType = z.infer<typeof InteractionTypeModel>;

export const interactionTypesMap: Record<InteractionType, string> = {
  [InteractionTypeEnum.FbmsInteraction]: 'MSL Interaction',
  [InteractionTypeEnum.CallCenterInteraction]: 'Call Center Interaction',
  [InteractionTypeEnum.WebInteraction]: 'Web Interaction',
  [InteractionTypeEnum.MedicalInsight]: 'Medical Insight',
  [InteractionTypeEnum.DocumentedInterest]: 'Documented Interest',
};

export enum InteractionSubTypeEnum {
  Introduction = 'introduction',
  Other = 'other',
  ScientificExchange = 'scientific-exchange',

  GeneralInquiry = 'general-inquiry',
  GlobalComplaint = 'global-complaint',
  MIR = 'mir',
  Settlement = 'settlement',
};
export const InteractionSubTypeModel = z.nativeEnum(InteractionSubTypeEnum);
export type InteractionSubType = z.infer<typeof InteractionSubTypeModel>;

export const interactionSubTypesMap: Record<InteractionSubType, string> = {
  [InteractionSubTypeEnum.Introduction]: 'Introduction',
  [InteractionSubTypeEnum.Other]: 'Other',
  [InteractionSubTypeEnum.ScientificExchange]: 'Scientific Exchange',

  [InteractionSubTypeEnum.GeneralInquiry]: 'General Inquiry',
  [InteractionSubTypeEnum.GlobalComplaint]: 'Global Complaint',
  [InteractionSubTypeEnum.MIR]: 'MIR',
  [InteractionSubTypeEnum.Settlement]: 'Settlement',
};

export enum CallCenterSource {
  Chat = 'chat',
  Email = 'email',
  Fax = 'fax',
  iConnect = 'iConnect',
  Legend = 'legend',
  Mail = 'mail',
  Mobile = 'mobile',
  Phone = 'phone',
  Relypsa = 'relypsa',
  USWeb = 'US Web',
  Veeva = 'Veeva',
  WalkIn = 'walk-in',
  Web = 'web',
}

export const callCenterSourcesMap: Record<CallCenterSource, string> = {
  [CallCenterSource.Chat]: 'Chat',
  [CallCenterSource.Email]: 'Email',
  [CallCenterSource.Fax]: 'Fax',
  [CallCenterSource.iConnect]: 'iConnect',
  [CallCenterSource.Legend]: 'Legend',
  [CallCenterSource.Mail]: 'Mail',
  [CallCenterSource.Mobile]: 'Mobile',
  [CallCenterSource.Phone]: 'Phone',
  [CallCenterSource.Relypsa]: 'Relypsa',
  [CallCenterSource.USWeb]: 'US Web',
  [CallCenterSource.Veeva]: 'Veeva',
  [CallCenterSource.WalkIn]: 'Walk-In',
  [CallCenterSource.Web]: 'Web',
};