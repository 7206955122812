import { ChatCustomFilter, ChatDateFilter, ChatFilterOperator } from '@/enums/chat.filters';
import {
  callCenterSourcesMap,
  CallCenterSource,
  interactionSubTypesMap,
  InteractionTypeEnum,
  InteractionSubTypeEnum,
} from '@/enums/interactions';
import { interactionTypesMap } from '@/enums/interactions';
import { getEnumValues } from '@/enums/utils';
import type { CustomFilter } from '@/types/chat.filters';
import { toTitleCase } from '@/utils/format';

export function getCallCenterSources() {
  return getEnumValues(CallCenterSource).map((m: CallCenterSource) => ({ id: m, name: callCenterSourcesMap[m] }));
}

export function getCallCenterSubSources() {
  const values = [{
    source: CallCenterSource.Chat,
    values: [
      'AskJanssen.com',
      'JanssenMD.com',
      'Janssen Science',
      'Other',
      'stelarahcp.com',
      'tecvaylihcp.com',
      'tremfyahcp.com',
    ],
  }, {
    source: CallCenterSource.Email,
    values: [
      '800 Number',
      'Ashfield',
      'BMS Med Info',
      'CCCntr@ompus.jnj.com',
      'Compendia',
      'eDossier',
      'FDA',
      'Field Based Medical Staff',
      'Internal Employee',
      'janssenmedinfo@its,jnj.com',
      'JS Contact Us',
      'Medical',
      'Medinfocenter@its.jnj.com',
      'Non-J&J Company',
      'Other',
      'Other J&J Company',
      'Pharmacyclics',
      'Sales Representative',
      'Settlements',
      'SFA',
      'Vendor',
    ],
  }, {
    source: CallCenterSource.Fax,
    values: [
      '800 Number',
      'Convention',
      'Internal Employee',
      'MediMedia',
      'Medinfocenter@its.jnj.com',
      'Non-J&J Company',
      'Other',
      'Other J&J Company',
      'Sales Representative',
      'Settlements',
      'stelarahcp.com',
      'Vendor',
    ],
  }, {
    source: CallCenterSource.iConnect,
    values: [
      'Compendia',
      'MIC',
    ],
  }, {
    source: CallCenterSource.Legend,
    values: [
      'Commercial',
      'janssenmedinfo@its,jnj.com',
      'Medical',
      'Other',
      'Sub Source=Commercial',
    ],
  }, {
    source: CallCenterSource.Mail,
    values: [
      '800 Number',
      'Non-J&J Company',
      'Other',
      'Settlements',
      'SFA',
    ],
  }, {
    source: CallCenterSource.Mobile,
    values: [
      'JanssenMD',
      'Other',
      'www.darzalexhcp.com',
      'www.janssenmd.com',
    ],
  }, {
    source: CallCenterSource.Phone,
    values: [
      '800 Number',
      'After Hours Program',
      'Ashfield',
      'CNS Vendor',
      'Convention',
      'CV Vendor',
      'Immunology Vendor',
      'Internal Employee',
      'Janssen Carepath',
      'Janssen Connect',
      'JGCC',
      'J&J Internal Transfers',
      'JJPAF',
      'JS-CallMeNow',
      'JS Contact Us',
      'Oncneph Vendor',
      'Other',
      'REMS',
      'Sales Support Line AEPQC',
      'Sales Support Line Med Info',
      'Sales Suppport Line AEPQC',
      'SFA',
      'TrialCard',
      'Vendor AEPQC',
    ],
  }, {
    source: CallCenterSource.Relypsa,
    values: [
      'Commercial',
      'Medical',
    ],
  }, {
    source: CallCenterSource.USWeb,
    values: [
      'US Web Employee',
      'US Web ESP',
    ],
  }, {
    source: CallCenterSource.Veeva,
    values: [
      'Other',
      'SFA',
      'US Web Call',
      'US Web MIR',
    ],
  }, {
    source: CallCenterSource.WalkIn,
    values: [
      'Convention',
      'HCP',
      'Internal Employee',
      'Other',
    ],
  }, {
    source: CallCenterSource.Web,
    values: [
      'AskJanssen.com',
      'Click to Call',
      'JanssenMD.com',
      'JanssenMD <donotreply@janssenmd.com>',
      'JS-CallMeNow',
      'JS Contact Us',
      'JS Video Call',
      'MedComm NonHCP',
      'Medical',
      'medinfo@ompus.jnj.com',
      'news.darzalexhcp.com',
      'Other',
      'Twitter',
      'unchealth.webex.com',
      'www.darzalexhcp.com',
      'www.google.com',
      'www.janssenmd.com',
      'www.janssenmedicalinformation.com',
      'www.janssenmsl.com',
      'www.phactmi.org',
      'www.procrit.com',
      'www.remicadehcp.com',
      'www.simponihcp.com',
      'www.sirturo.com',
    ],
  }];

  return values.map(({ source, values }) => values.map(m => ({
    id: m,
    name: m,
    parent1: callCenterSourcesMap[source],
  }))).flat();
};

export function getInteractionTypes() {
  return [
    InteractionTypeEnum.CallCenterInteraction,
    InteractionTypeEnum.FbmsInteraction,
    InteractionTypeEnum.WebInteraction,
    InteractionTypeEnum.DocumentedInterest,
    InteractionTypeEnum.MedicalInsight,
  ].map(m => ({ id: m, name: interactionTypesMap[m] }));
}

function getInteractionTypeMetadata() {
  return [{
    type: InteractionTypeEnum.FbmsInteraction,
    subTypes: [
      {
        type: InteractionSubTypeEnum.Introduction,
        details: [
          'Introduction',
          'Other',
        ],
      },
      {
        type: InteractionSubTypeEnum.ScientificExchange,
        details: [
          'Documented Interest',
          'Introduction',
          'Other',
          'Proactive Clinical',
          'Unsolicited Question Direct',
          'Unsolicited Question From Field',
        ],
      },
    ],
  }, {
    type: InteractionTypeEnum.CallCenterInteraction,
    subTypes: [
      {
        type: InteractionSubTypeEnum.GeneralInquiry,
        details: [
          'CARMA',
          'Chat-No Question',
          'Complaint',
          'Compliance',
          'Compliment',
          'Expiry Request',
          'General Business',
          'Hang Up/Wrong Number',
          'Non-J&J Prod AE',
          'Other',
          'Outbound Contact',
          'Privacy Request',
          'Product Information',
          'Suggestion',
          'Transfer / Refer',
          'Unspecified',
        ],
      },
      {
        type: InteractionSubTypeEnum.GlobalComplaint,
        details: [
          'Follow-up AE',
          'Follow-up AE and PQC',
          'Follow-up PQC',
          'Initial AE',
          'Initial AE and PQC',
          'Initial PQC',
        ],
      },
      {
        type: InteractionSubTypeEnum.MIR,
        details: [
          'Unsolicited Question Direct',
          'Unsolicited Question From Field',
        ],
      },
      {
        type: InteractionSubTypeEnum.Settlement,
        details: [
          'Credit',
          'Replacement',
        ],
      },
    ],
  }, {
    type: InteractionTypeEnum.WebInteraction,
    subTypes: [],
  }];
}

export function getInteractionSubTypes() {

  const metadata = getInteractionTypeMetadata();

  const items: CustomFilter.ItemValue[] = [];
  for (const { type, subTypes } of metadata) {
    for (const subType of subTypes) {
      items.push({
        id: subType.type,
        name: interactionSubTypesMap[subType.type],
        parent1: interactionTypesMap[type],
      });
    }
  }

  return items;
}

export function getInteractionDetails() {
  const metadata = getInteractionTypeMetadata();

  const items: CustomFilter.ItemValue[] = [];
  for (const { type, subTypes } of metadata) {
    for (const subType of subTypes) {
      for (const detail of subType.details) {
        items.push({
          id: detail,
          name: detail,
          parent1: interactionSubTypesMap[subType.type],
          parent2: interactionTypesMap[type],
        });
      }
    }
  }

  return items;
}

export function getPracticeSettings() {
  return [
    {
      id: '1',
      name: 'Option 1',
    },
    {
      id: '2',
      name: 'Option 2',
    },
  ];
}

export function getInsightTypes() {
  return [
    'COVID-19',
    'Category Management',
    'Clinical',
    'Competitor Product',
    'Cost/Reimbursement',
    'DE&I',
    'Disease State',
    'Emerging Stakeholders',
    'Healthcare Landscape',
    'Healthcare reform',
    'J&J Product',
    'Med Expert Input',
    'New Business Opportunities',
    'Other',
    'Pipeline / Clinical Development',
    'Quality',
    'RWE',
    'Research',
    'Safety',
    'Tools',
  ].sort((a, b) => a.localeCompare(b)).map((m, i) => ({ id: (i + 1).toString(), name: m }));
}

enum Sentiment {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export function getSentimentValues() {
  return [
    Sentiment.Positive,
    Sentiment.Neutral,
    Sentiment.Negative,
  ].map(m => ({ id: m, name: toTitleCase(m) }));
}

export function getCustomFilterOperators(type: ChatCustomFilter) {
  const operatorsMap: Record<ChatCustomFilter, ChatFilterOperator[]> = {
    [ChatCustomFilter.CallCenterSource]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.CallCenterSubSource]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InteractionDate]: [ChatFilterOperator.MoreRecentThan, ChatFilterOperator.PriorTo],
    [ChatCustomFilter.PracticeSetting]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InsightType]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Product]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Source]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.TherapeuticArea]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.TherapeuticSubArea]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Sentiment]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InterestArea]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],

    [ChatCustomFilter.InteractionType]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InteractionSubType]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InteractionDetail]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
  };

  return operatorsMap[type] || [];
}

export function getDefaultOperator(type: ChatCustomFilter) {
  return getCustomFilterOperators(type)[0];
}

export function getDefaultCustomFilterValue<T extends ChatCustomFilter>(type: T): CustomFilter.Item {
  switch (type) {
    case ChatCustomFilter.InteractionDate:
      return {
        type: ChatCustomFilter.InteractionDate,
        operator: ChatFilterOperator.MoreRecentThan,
        value: ChatDateFilter.Last30Days,
      };

    case ChatCustomFilter.PracticeSetting:
    case ChatCustomFilter.InsightType:
    case ChatCustomFilter.Product:
    case ChatCustomFilter.Source:
    case ChatCustomFilter.TherapeuticArea:
    case ChatCustomFilter.TherapeuticSubArea:
    case ChatCustomFilter.Sentiment:
    case ChatCustomFilter.InterestArea:
    case ChatCustomFilter.InteractionType:
    case ChatCustomFilter.InteractionSubType:
    case ChatCustomFilter.InteractionDetail:
    case ChatCustomFilter.CallCenterSource:
    case ChatCustomFilter.CallCenterSubSource:
      return {
        operator: ChatFilterOperator.Eq,
        value: [],
        type,
      };

    default:
      throw new UnreachableCaseError(type);
  }
}

export function validateCustomFilterValue<T extends ChatCustomFilter>(value: CustomFilter.Item<T>) {
  switch (value.type) {
    case ChatCustomFilter.InteractionDate:
      return !!value.value;

    case ChatCustomFilter.PracticeSetting:
    case ChatCustomFilter.InsightType:
    case ChatCustomFilter.Product:
    case ChatCustomFilter.Source:
    case ChatCustomFilter.TherapeuticArea:
    case ChatCustomFilter.TherapeuticSubArea:
    case ChatCustomFilter.Sentiment:
    case ChatCustomFilter.InterestArea:
    case ChatCustomFilter.InteractionType:
    case ChatCustomFilter.InteractionSubType:
    case ChatCustomFilter.InteractionDetail:
    case ChatCustomFilter.CallCenterSource:
    case ChatCustomFilter.CallCenterSubSource:
      return !!value.value.length;

    default:
      throw new UnreachableCaseError(value);
  }
}