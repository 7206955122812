import { useContext, useMemo } from 'react';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { colors, theme } from '@/components/Theme/theme';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { RefGroupTypeEnum } from '@/enums/group';
import { useAppSelector } from '@/store';

type Props =
  ChildrenProps;

export const MuiThemeProvider = ({ children }: Props) => {

  const muiTheme = createTheme(theme);
  const group = useAppSelector(s => s.group);

  const wl = useContext(AppThemingContext);

  const overwrite = useMemo(() => {
    if (wl.query.isInitialLoading || !group) {
      return {
        ...muiTheme,
        components: {
          ...muiTheme.components,
          MuiTextField: {
            styleOverrides: {
              root: {
                '--TextField-insightsBorderColor': muiTheme.palette.black.light2,
                '--TextField-insightsBorderHoverColor': muiTheme.palette.primary.light1,
                '--TextField-insightsBorderFocusedColor': muiTheme.palette.primary.main,
              },
            },
          },
        },
        palette: {
          ...muiTheme.palette,
          blue: {
            ...muiTheme.palette.blue,
            ...wl.palette?.blue,
            ...wl.palette?.green,
          },
          primary: {
            ...muiTheme.palette.primary,
            main: muiTheme.palette.gray.main,
            dark: muiTheme.palette.gray.dark1,
            light: muiTheme.palette.gray.light1,
            light1: muiTheme.palette.gray.light1,
            light2: muiTheme.palette.gray.light2,
            light3: muiTheme.palette.gray.light3,
            light4: muiTheme.palette.gray.light4,
            light5: muiTheme.palette.gray.light5,
            light6: muiTheme.palette.gray.light6,
          },
          sentiment: {
            ...muiTheme.palette.sentiment,
            primary: {
              main: muiTheme.palette.gray.main,
              dark: muiTheme.palette.gray.dark1,
              light: muiTheme.palette.gray.light1,
              lighter: muiTheme.palette.gray.light2,
            },
          },
          badge: {
            ...muiTheme.palette.badge,
            citation: {
              bg: muiTheme.palette.gray.light1,
              color: muiTheme.palette.gray.main,
              hover: {
                bg: muiTheme.palette.gray.light2,
                color: muiTheme.palette.gray.main,
              },
            },
          },
          btnPrimary: {
            ...muiTheme.palette.btnPrimary,
            color: muiTheme.palette.gray.main,
            bg: muiTheme.palette.gray.light1,
            bg1: muiTheme.palette.gray.light1,
            border: muiTheme.palette.gray.main,
            active: {
              color: muiTheme.palette.gray.main,
              bg: muiTheme.palette.gray.light2,
              border: muiTheme.palette.gray.main,
            },
          },
          btnSecondary: {
            ...muiTheme.palette.btnSecondary,
            color: colors.gray.main,
            bg: colors.white.main,
            bg1: colors.white.main,
            border: colors.gray.main,
            active: {
              color: colors.gray.main,
              bg: colors.gray.light2,
              border: colors.gray.main,
            },
          },
          loader: {
            main: muiTheme.palette.gray.main,
          },
          skeleton: {
            main: muiTheme.palette.gray.light6,
          },
        },
      };
    }

    if (!wl.theme?.enabled) {
      muiTheme.isTrinityThemed = true;
      return muiTheme;
    }

    return {
      ...muiTheme,
      components: {
        ...muiTheme.components,
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-insightsBorderColor': muiTheme.palette.black.light2,
              '--TextField-insightsBorderHoverColor': wl.palette?.primary?.light || muiTheme.palette.primary.light1,
              '--TextField-insightsBorderFocusedColor': wl.palette?.primary?.main || muiTheme.palette.primary.main,
            },
          },
        },
      },
      isTrinityThemed: group.typeId === RefGroupTypeEnum.Demo,
      palette: {
        ...muiTheme.palette,
        wl: wl.palette,
        blue: {
          ...muiTheme.palette.blue,
          ...wl.palette?.blue,
          ...wl.palette?.green,
        },
        primary: {
          ...muiTheme.palette.primary,
          main: wl.palette?.primary?.main,
          dark: wl.palette?.primary?.dark,
          light: wl.palette?.primary?.light,
          light1: wl.palette?.shade?.light1,
          light2: wl.palette?.shade?.light2,
          light3: wl.palette?.shade?.light3,
          light4: wl.palette?.shade?.light4,
          light5: wl.palette?.shade?.light5,
          light6: wl.palette?.shade?.light6,
        },
        sentiment: {
          ...muiTheme.palette.sentiment,
          primary: {
            main: wl.palette?.primary?.main,
            dark: wl.palette?.primary?.dark,
            light: wl.palette?.primary?.light,
            lighter: wl.palette?.primary?.lighter,
          },
        },
        badge: {
          ...muiTheme.palette.badge,
          citation: {
            bg: wl.palette?.prompt?.main,
            color: wl?.palette?.prompt?.icon,
            hover: {
              bg: wl?.palette?.prompt?.dark1,
              color: wl?.palette?.prompt?.icon,
            },
          },
        },
        btnPrimary: {
          ...muiTheme.palette.btnPrimary,
          color: wl?.palette?.prompt?.icon,
          bg: wl?.palette?.prompt?.main,
          bg1: wl?.palette?.prompt?.main,
          border: wl?.palette?.prompt?.icon,
          active: {
            color: wl?.palette?.prompt?.icon,
            bg: wl?.palette?.prompt?.dark1,
            border: wl?.palette?.prompt?.icon,
          },
        },
        btnSecondary: {
          ...muiTheme.palette.btnSecondary,
          color: colors.gray.main,
          bg: colors.white.main,
          bg1: colors.white.main,
          border: colors.gray.main,
          active: {
            color: colors.gray.main,
            bg: colors.gray.light2,
            border: colors.gray.main,
          },
        },
        loader: {
          main: wl?.palette?.primary?.main,
        },
        skeleton: {
          main: wl?.palette?.prompt?.main,
        },
      },
    };
  }, [group, muiTheme, wl.palette, wl.query.isInitialLoading, wl.theme?.enabled]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={overwrite}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MuiThemeProvider;