import { useEffect } from 'react';
import { useEnterpriseSubmitMutation } from '@/containers/Auth';

export function OAuth2Login() {

  const loginMutation = useEnterpriseSubmitMutation();

  useEffect(() => {
    loginMutation.mutateAsync({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <></>
  );
}