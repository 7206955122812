import { useMemo } from 'react';
import { FileTabView } from './File.TabView';
import { FileTabPreview } from './File.Tab.Preview';
/* import { FileTabSummary } from './File.Tab.Summary'; */
import { FileTabDetails } from './File.Tab.Details';
/* import { FileTabReferences } from './File.Tab.References'; */
import type { SceneMap } from './interfaces';
import { RouteKey } from './interfaces';

type Props = {
  paths?: {
    preview: string;
    summary: string;
    details: string;
    references: string;
  };
};

export const FileTabViewContainer = ({
  paths = {
    preview: `preview`,
    summary: `summary`,
    details: `details`,
    references: `references`,
  },
}: Props) => {
  const map: SceneMap = useMemo(() => ({
    [RouteKey.Preview]: {
      component: FileTabPreview,
      path: paths.preview,
      title: 'Preview',
    },
    // [RouteKey.Summary]: {
    //   component: () => null,
    //   path: paths.summary,
    //   title: 'Summary',
    // },
    [RouteKey.Details]: {
      component: FileTabDetails,
      path: paths.details,
      title: 'Details',
    },
    // [RouteKey.References]: {
    //   component: () => null,
    //   path: paths.references,
    //   title: 'References',
    // },
  }), [paths]);

  return <FileTabView map={map} />;
};