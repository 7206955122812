import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as $sessionHttp from '@/auth/session-http';
import { ActivityIndicator } from '@/components/presentation';
import { ProvideDefaultPathsContext } from '@/router/context';
import { actions } from '@/store/actions';

export const LogoutScreen = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const defaultPaths = useContext(ProvideDefaultPathsContext);

  const mutation = useMutation({
    mutationFn: () => {
      return $sessionHttp.logout();
    },
    onSuccess: (res, vars) => {
      Sentry.setUser(null);
      dispatch(actions.logout());
      queryClient.cancelQueries()
        .then(() => {
          queryClient.clear();
        });
    },
    onSettled: () => {
      // hack for now until refactor
      queryClient.refetchQueries(['get:app/theming']);
      navigate(defaultPaths.homePath);
    },
  });

  useEffect(() => {
    mutation.mutateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <ActivityIndicator />
    </Root>
  );
};

const Root = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - var(--navbar-height))`,
});