import { useCallback, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ActiveChatSessionContext, ChatSessionFiltersContext } from '@/components/Chat/context';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { generateEmptyChatSessionFiltersState } from '@/components/Chat.State/state.initial-state';
import { ChatContextType } from '@/enums/chat';
import { ChatSource, ChatSourcesMap } from '@/enums/chat.filters';
import type { CustomFilter, SourceFilter } from '@/types/chat.filters';
import { AddCustomFilter } from './CustomFilter.AddButton';
import { CustomFilterForm } from './CustomFilter.Form';
import { CustomFilterValue } from './CustomFilter.Value';
import { DataSource } from './DataSource';
import { PublishedDate } from './PublishedDate';
import { Record } from './Record';
import { Source } from './Source';

type Props = {
  active: boolean;
};

export function FilterPaneProfile(props: Props) {

  const dispatch = useContext(ChatStateDispatchContext);
  const [hasUnsavedCustomFilter, setHasUnsavedCustomFilter] = useState(false);

  const handleAddCustomFilter = useCallback(() => {
    setHasUnsavedCustomFilter(true);
  }, []);

  const handleRemoveUnsavedFilter = useCallback(() => {
    setHasUnsavedCustomFilter(false);
  }, []);

  const handleSaveCustomFilter = useCallback((filter: CustomFilter.Item) => {
    dispatch({
      type: 'filters/add-custom-filter',
      payload: {
        value: filter,
      },
    });
    setHasUnsavedCustomFilter(false);
  }, [dispatch]);

  return (
    <Root>
      <Wrap>
        <Header>
          <HeaderLabel>Source Filters</HeaderLabel>
          <ResetController {...props} />
        </Header>
        <Filters>
          <DataSourceController />
          <SourceController />
          <RecordController />
        </Filters>
      </Wrap>
      <CustomFiltersController />
      {!hasUnsavedCustomFilter && <AddCustomFilter onClick={handleAddCustomFilter} />}
      {hasUnsavedCustomFilter && (
        <StyledNewCustomFilterForm
          onSubmit={handleSaveCustomFilter}
          onCancel={handleRemoveUnsavedFilter} />
      )}
    </Root>
  );
}

function CustomFiltersController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const [editing, setEditing] = useState<number>(null);

  const handleRemoveFilter = useCallback((index: number) => () => {
    dispatch({
      type: 'filters/remove-custom-filter',
      payload: {
        id: index,
      },
    });
    setEditing(null);
  }, [dispatch]);

  const handleSaveCustomFilter = useCallback((filter: CustomFilter.Item) => {
    dispatch({
      type: 'filters/update-custom-filter',
      payload: {
        id: editing,
        value: filter,
      },
    });
    setEditing(null);
  }, [dispatch, editing]);

  const handleToggleEdit = useCallback((index: number) => () => {
    setEditing(index);
  }, []);

  const renderCustomFilter = useCallback((item: CustomFilter.Item, index: number) => {
    const isEditing = index === editing;

    if (isEditing) {
      return (
        <CustomFilterForm
          initialValue={item}
          onSubmit={handleSaveCustomFilter}
          onCancel={() => setEditing(null)} />
      );
    }

    return (
      <CustomFilterValue
        editable={editing === null}
        onEdit={handleToggleEdit(index)}
        onRemove={handleRemoveFilter(index)}
        value={item} />
    );
  }, [editing, handleRemoveFilter, handleSaveCustomFilter, handleToggleEdit]);

  return (
    <>
      {filters.custom.map((m, i) => (
        <CustomFilterRow key={i}>
          {renderCustomFilter(m, i)}
        </CustomFilterRow>
      ))}
    </>
  );
}

function RecordController() {
  const session = useContext(ActiveChatSessionContext);

  const values = useMemo(() => {
    if (session.context.type !== ChatContextType.KolProfile) {
      return [];
    }
    if (!session.context.metadata.name) {
      return [];
    }

    return [{
      id: session.context.data.id?.toString(),
      type: 'kol-profile',
      name: session.context.metadata.name,
    }];
  }, [session.context]);

  return (
    <>
      <Record readOnly value={values} />
    </>
  );
}

function DataSourceController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const options = filters.dataSources;

  return (
    <DataSource
      readOnly
      options={options}
      value={filters.dataSources}
      onChange={values => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              dataSources: values.length ? values : options,
            },
          },
        });
      }} />
  );
}

function SourceController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const options = useMemo(() => {
    return [
      ChatSource.MslInteractions,
      ChatSource.Profiles,
    ].map(o => ({
      id: o,
      name: ChatSourcesMap.get(o),
    }));
  }, []);

  const handleSourceChange = useCallback((sources: SourceFilter.Item[]) => {
    dispatch({
      type: 'filters/updated',
      payload: {
        filters: {
          sources: sources.length ? sources : options,
        },
      },
    });
  }, [dispatch, options]);

  return (
    <Source
      options={options}
      value={filters.sources}
      onChange={handleSourceChange} />
  );
}

function PublishedDateController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const defaultValue = useMemo(() => generateEmptyChatSessionFiltersState({ context }).minDocumentDate, [context]);

  return (
    <PublishedDate
      defaultValue={defaultValue}
      value={filters.minDocumentDate}
      onChange={minDocumentDate => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              minDocumentDate,
            },
          },
        });
      }} />
  );
}

function ResetController({ active }: Props) {
  const dispatch = useContext(ChatStateDispatchContext);

  return (
    <HeaderReset
      disabled={!active}
      onClick={() => dispatch({ type: 'filters/reset' })}>
      Reset
    </HeaderReset>
  );
}

const basePadding = 12;

const Root = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '5px',
  border: `1px solid ${theme.palette.gray.light1}`,
  boxShadow: '0 2px 6px 2px rgba(60, 64, 67, .15)',
  boxSizing: 'border-box',
  minWidth: 450,
  maxWidth: 600,
}));

const Wrap = styled.div(({ theme }) => ({
  padding: basePadding,
  // borderBottom: `1px solid ${theme.palette.gray.light1}`,
}));

const Header = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const HeaderLabel = styled.h1(({ theme }) => ({
  fontSize: '18px',
  fontFamily: theme.fonts.semiBold,
}));

const HeaderReset = styled.button(({ disabled, theme }) => ({
  color: !disabled ? theme.palette.text.primary : theme.palette.gray.light1,
  fontSize: '14px',
  transition: 'text-decoration 150ms, color 150ms',

  ':hover': {
    textDecoration: disabled ? 'underline' : 'none',
  },
}));

const Filters = styled.div({
  boxSizing: 'border-box',
  padding: '0 2px',
});

const CustomFilterRow = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.gray.light1}`,
}));

const StyledNewCustomFilterForm = styled(CustomFilterForm)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.gray.light1}`,
}));

export { FilterPaneWorkspace } from './FilterPane.Workspace';