import { useContext } from 'react';
import { WorkspaceFilePreviewLoadingContext } from '@/containers/Workspace.File.Preview/Context';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject';
import { Breadcrumb, Breadcrumbs, SkeletonBreadcrumbs } from '@/components/presentation/Breadcrumbs';
import { MaybeLink } from '@/components/presentation';
import { WorkspaceObjectType } from '@/enums';
import type { WorkspaceObject } from '@/types';
import styles from './style/Header.module.css';

export const FileBreadcrumbs = () => {
  const breadcrumbs = useContext(WorkspaceBreadcrumbsContext);
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  return (
    <>
      {isLoading
        ? <SkeletonBreadcrumbs />
        : (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs>
              {breadcrumbs.map(x => {
                const to = generateLink(x);

                return (
                  <MaybeLink
                    key={x.id}
                    to={to}>
                    <Breadcrumb>
                      {x.name}
                    </Breadcrumb>
                  </MaybeLink>
                );
              })}
            </Breadcrumbs>
          </div>
        )
      }
    </>
  );
};

function generateLink(object: Pick<WorkspaceObject, 'entityId' | 'typeId'>) {
  switch (object.typeId) {
    case WorkspaceObjectType.Workspace:
      return `/workspaces`;

    case WorkspaceObjectType.Folder:
      return `/workspaces/folders/${object.entityId}`;

    case WorkspaceObjectType.File:
      return `/workspaces/files/${object.entityId}`;

    default:
      return null;
  }
}