import { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Edit2, XCircle } from 'react-feather';
import { ChatFilterOperator, chatCustomFilterNamesMap } from '@/enums/chat.filters';
import { ChatCustomFilter, chatDateFiltersMap } from '@/enums/chat.filters';
import type { CustomFilter } from '@/types/chat.filters';
import { shouldForwardProp } from '@/utils';

type Props = {
  editable: boolean;
  onEdit: () => void;
  onRemove: () => void;
  value: CustomFilter.Item;
};

export const CustomFilterValue = memo(({ editable, onEdit, onRemove, value }: Props) => {

  const renderFilterValue = useCallback(() => {
    switch (value.type) {
      case ChatCustomFilter.TherapeuticArea:
      case ChatCustomFilter.TherapeuticSubArea:
      case ChatCustomFilter.PracticeSetting:
      case ChatCustomFilter.Product:
      case ChatCustomFilter.Source:
      case ChatCustomFilter.InsightType:
      case ChatCustomFilter.Sentiment:
      case ChatCustomFilter.InterestArea:
      case ChatCustomFilter.InteractionType:
      case ChatCustomFilter.InteractionSubType:
      case ChatCustomFilter.InteractionDetail:
      case ChatCustomFilter.CallCenterSource:
      case ChatCustomFilter.CallCenterSubSource:
        return value.value.map(m => m.name).join(', ');

      case ChatCustomFilter.InteractionDate:
        return chatDateFiltersMap[value.value];

      default:
        throw new UnreachableCaseError(value);
    }
  }, [value]);

  const renderFilterText = useCallback(() => {
    const operatorsMap: Record<ChatFilterOperator, string> = {
      [ChatFilterOperator.Eq]: 'includes',
      [ChatFilterOperator.Neq]: `doesn't include`,
      // [ChatFilterOperator.Gt]: 'is greater than',
      // [ChatFilterOperator.Gte]: 'is greater than or equal to',
      // [ChatFilterOperator.Lt]: 'is less than',
      // [ChatFilterOperator.Lte]: 'is less than or equal to',

      [ChatFilterOperator.MoreRecentThan]: 'is more recent than',
      [ChatFilterOperator.PriorTo]: 'is prior to',
    };

    const operator = operatorsMap[value.operator];

    const displayValue = renderFilterValue();

    return (
      <span>
        <Bold>{`"${chatCustomFilterNamesMap[value.type]}"`}</Bold>
        {' '}
        {operator}
        {' '}
        <Bold>{`"${displayValue}"`}</Bold>
      </span>
    );
  }, [renderFilterValue, value]);

  return (
    <Root>
      <div>
        {renderFilterText()}
      </div>

      <Actions>
        <StyledEdit2
          size={18}
          disabled={!editable}
          onClick={editable ? onEdit : undefined} />
        <StyledXCircle size={22} onClick={onRemove} />
      </Actions>
    </Root>
  );
});

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  padding: 12,
}));

const Actions = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  paddingLeft: 10,
});

const StyledXCircle = styled(XCircle)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.gray.dark1,
}));

const StyledEdit2 = styled(Edit2, { shouldForwardProp: shouldForwardProp('disabled') })<{ disabled: boolean }>(({ theme, disabled }) => ({
  cursor: disabled ? undefined : 'pointer',
  color: disabled ? theme.palette.gray.main : theme.palette.blue.main,
}));

const Bold = styled.span(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));