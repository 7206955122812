import { z } from 'zod';
import { MyInsightsVeevaOnlineUtils, type MyInsightsVeevaUtils } from './myinsights.utils';

export interface MyInsights {
  getSSOToken(): Promise<string>;
  refreshSSOToken(oldToken: string): Promise<string>;
  getUserInfo(): Promise<unknown>;
  getUserId(): Promise<string>;
}

export class MyInsightsOnline implements MyInsights {
  #utils: MyInsightsVeevaUtils;

  constructor() {
    window.myinsights.init();
    this.#utils = new MyInsightsVeevaOnlineUtils();
  }

  #getClient() {
    if (!window.ds) throw new Error('Attemping to use Veeva DataService API but the library is not available.');
    return window.ds;
  }

  async getSSOToken() {
    const result = await this.#getClient().getSSOAccessToken('PingOne_Oauth', 'Open Id Connect');
    return result.data.token;
  }

  async refreshSSOToken(oldToken: string) {
    const result = await this.#getClient().getSSOAccessToken('PingOne_Oauth', 'Open Id Connect', oldToken);
    return result.data.token;
  }

  async getUserInfo() {
    const userId = await this.getUserId();

    const details = await this.getUserDetails([userId]);
    return details;
  }

  async getUserDetails(userIds: string[]) {
    const StringField = z.object({
      dataType: z.literal('string'),
      display: z.string(),
      label: z.string(),
      value: z.string(),
    });
    const Response = z.object({
      data: z.object({
        Id: StringField,
        Department: StringField,
        Division: StringField,
        'JnJ_External_ID__c': StringField,
        'Territory_JNJ__c': StringField,
      }).array(),
    });
    type Response = z.infer<typeof Response>;

    const response = await this.#getClient().runQuery<Response>({
      object: 'User',
      fields: [
        'Id',
        'Department',
        'Division',
        'JnJ_External_ID__c',
        'Territory_JNJ__c',
      ],
      where: `Id in ${this.#utils.formatInArrayStatement(userIds)}`,
    });
    return Response.parseAsync(response).then(r => r.data);
  }

  async getUserId() {
    type Response = {
      User: {
        Id: string;
      };
    };
    return this.#getClient().getDataForCurrentObject<Response>('User', 'Id').then(result => result.User.Id);
  }
}

export class MyInsightsMock implements MyInsights {
  async getSSOToken() {
    return 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImZlZGxvZ2luIiwieDV0IjoiSTBYeFNERXp2NXhrNVFmSVNoMFplQkFOdjZzIiwicGkuYXRtIjoiejNsaCJ9.eyJzY29wZSI6InByb2ZpbGUiLCJhdXRob3JpemF0aW9uX2RldGFpbHMiOltdLCJjbGllbnRfaWQiOiJSRVBDT1BJTE9ULVBST0QiLCJpc3MiOiJodHRwczovL2ZlZGxvZ2luLmpuai5jb20iLCJqbmpNU1VzZXJuYW1lIjoiQldhbGVuZHoiLCJhdWQiOiJSRVBDT1BJTE9ULVBST0QiLCJjbiI6IjcwMjQ3Nzg5MiIsImdpdmVuX25hbWUiOiJCcmlhbiIsImZhbWlseV9uYW1lIjoiV2FsZW5kemluc2tpIiwiZW1haWwiOiJCV2FsZW5kekBJVFMuSk5KLmNvbSIsImV4cCI6MTczMjIzNDg3Nn0.Mz23UN-1-v4LfaE1FCquguQfnYp9zeuFrNRANuTvOG8gIhTQlkUh-vfg7gnDwNdZLGud7e0HYjx2qRQCSquhWQBn5aGZo4gw9KnX-uaDVWF-DxWpvxvD__TQp3Mcc4OHceFzEj-q5_K4euh2OI0YEnXhDjKQjsPl-ZBoecGX5AlmkjLVkEAIO4UKOVXWi1f_iUgZhJ5MphCoq7pK15wJ9inaGYMgsfk8hztyl2Jd-7ZHyINKjkp1FGYmDGtZfeH9HHgN7Klrv3lOUJIS0PJI0qv4k7gQG0ooepQ9UOAiOMdzifF3psq_40etXPDwj2SAeK-JUchDCNC6Lp2bKHok7w';
  }
  async refreshSSOToken(oldToken: string) {
    return 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImZlZGxvZ2luIiwieDV0IjoiSTBYeFNERXp2NXhrNVFmSVNoMFplQkFOdjZzIiwicGkuYXRtIjoiejNsaCJ9.eyJzY29wZSI6InByb2ZpbGUiLCJhdXRob3JpemF0aW9uX2RldGFpbHMiOltdLCJjbGllbnRfaWQiOiJSRVBDT1BJTE9ULVBST0QiLCJpc3MiOiJodHRwczovL2ZlZGxvZ2luLmpuai5jb20iLCJqbmpNU1VzZXJuYW1lIjoiQldhbGVuZHoiLCJhdWQiOiJSRVBDT1BJTE9ULVBST0QiLCJjbiI6IjcwMjQ3Nzg5MiIsImdpdmVuX25hbWUiOiJCcmlhbiIsImZhbWlseV9uYW1lIjoiV2FsZW5kemluc2tpIiwiZW1haWwiOiJCV2FsZW5kekBJVFMuSk5KLmNvbSIsImV4cCI6MTczMjIzNDg3Nn0.Mz23UN-1-v4LfaE1FCquguQfnYp9zeuFrNRANuTvOG8gIhTQlkUh-vfg7gnDwNdZLGud7e0HYjx2qRQCSquhWQBn5aGZo4gw9KnX-uaDVWF-DxWpvxvD__TQp3Mcc4OHceFzEj-q5_K4euh2OI0YEnXhDjKQjsPl-ZBoecGX5AlmkjLVkEAIO4UKOVXWi1f_iUgZhJ5MphCoq7pK15wJ9inaGYMgsfk8hztyl2Jd-7ZHyINKjkp1FGYmDGtZfeH9HHgN7Klrv3lOUJIS0PJI0qv4k7gQG0ooepQ9UOAiOMdzifF3psq_40etXPDwj2SAeK-JUchDCNC6Lp2bKHok7w';
  }
  async getUserInfo() {
    return [
      {
        'Id': {
          'dataType': 'string',
          'display': '0056f00000AXNz6AAH',
          'label': 'User ID',
          'value': '0056f00000AXNz6AAH',
        },
        'Department': {
          'dataType': 'string',
          'display': '200',
          'label': 'Department',
          'value': '200',
        },
        'Division': {
          'dataType': 'string',
          'display': 'SD',
          'label': 'Division',
          'value': 'SD',
        },
        'JnJ_External_ID__c': {
          'dataType': 'string',
          'display': '702477892',
          'label': 'WWID',
          'value': '702477892',
        },
        'Territory_JNJ__c': {
          'dataType': 'string',
          'display': '200-SD-4510185',
          'label': 'Territory #',
          'value': '200-SD-4510185',
        },
      },
    ];
  }
  async getUserId() {
    return '0056f00000AXNz6AAH';
  }
}