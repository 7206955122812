import * as $api from '@/api';
import { useQueryDownloader } from '@/containers/QueryDownloader/hooks';

type Params = {
  chatInstanceId: number;
  name: string;
};

export const useDownloadChatInstance = (params: Params) => {

  return useQueryDownloader({
    queryKey: [
      `brand-insights:chat:download`,
      params.chatInstanceId,
    ],
    queryFn: ({ queryKey: [, chatInstanceId] }) => {
      return $api.downloadChatInstance({ chatInstanceId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: `${params.name}.docx`,
        type: 'blob',
        value: res.data,
      }),
    },
  });

};