import { useCallback, useContext } from 'react';
import { cx } from '@/utils';
import type { IWorkspaceFileItem, IWorkspaceFolderItem } from '@/types/workspace.table';
import { WorkspaceObjectType } from '@/enums';
import type { IFilesTable } from './interfaces';
import { File } from './Files.Table.Grid.File';
import { Folder } from './Files.Table.Grid.Folder';
import styles from './style/Files.Table.Grid.module.css';

type FilesTableGridProps = {
  classes?: {
    root?: string;
    files?: string;
  };
  items: IFilesTable.Item[];
  canUpload: boolean;
  onUpload?: (files: File[]) => unknown;
};

export const FilesTableGrid = ({ classes = {}, ...props }: FilesTableGridProps) => {
  const renderItem = useCallback((data: IFilesTable.Item) => {
    switch (data.object.typeId) {
      case WorkspaceObjectType.File: {
        const item = data as IWorkspaceFileItem;
        return (
          <File key={data.object.id} item={item} />
        );
      }

      case WorkspaceObjectType.Folder:
        return <Folder key={data.object.id} item={data as IWorkspaceFolderItem} />;

      case WorkspaceObjectType.Workspace:
        return null;

      default:
        // @ts-ignore
        throw new UnreachableCaseError(data.object.typeId);
    }
  }, []);

  return (
    <div className={cx(styles.root, classes.root)}>
      <div className={cx(styles.files, classes.files)}>
        {props.items.map(renderItem)}
      </div>
    </div>
  );
};