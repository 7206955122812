import { useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import * as $oauth2 from '@/auth/oauth2';
import * as $sessionHttp from '@/auth/session-http';
import type { Enterprise } from './interfaces';

export function useEnterpriseSubmitMutation() {
  const location = useLocation();

  const search = queryString.parse(location.search) as Search;

  return useMutation({
    mutationFn: (values: Enterprise.Login.State) => {
      return $sessionHttp.getMetadata<'enterprise-login'>({
        action: 'enterprise-login',
        email: values.email,
        fingerprint: $oauth2.getOrCreateOAuthFlowFingerprint(),
        provider: values.provider,
        returnUrl: search.returnUrl,
      });
    },
    onSuccess: data => {
      if (data.success) {
        return $oauth2.authorize({ url: data.authorizationUrl });
      } else if (data.success === false) {
        throw new Error(data.error);
      }
    },
  });
}

type Search = {
  returnUrl: string;
};