import styled from '@emotion/styled';
import { ChatQueryStatus } from '@/enums';
import type { Chat } from '@/types';
import { SystemUserMessage } from './Message.System';
import { SystemMessageContainer } from './Message.System.Container';
import { SystemUserErrorMessage } from './Message.System.Error';
import { SystemUserPlaceholderMessage } from './Message.System.Placeholder';
import { UserMessage } from './Message.User';
import { QueryFollowupsContainer } from './Query.FollowupsContainer';
import { QueryEventsContainer } from './QueryEventsContainer';

type Props = {
  canRegenerate: boolean;
  item: Chat.InstanceQueryItem;
};

export const QueryItem = ({ canRegenerate, item }: Props) => {

  return (
    <>
      <MessageContainer>
        <UserMessage
          value={item.query.value} />
      </MessageContainer>
      <MessageContainer>
        <MessageResponse
          item={item.query}
          hasRegenerateFooter={canRegenerate} />
      </MessageContainer>
    </>
  );
};

type MessageResponseProps = {
  item: Chat.Query;
  hasRegenerateFooter: boolean;
};

// todo: possibly refactor to inverse the layout and have the current inner UI elements
// like variant selector and other actions contain the message response component
const MessageResponse = ({ item, hasRegenerateFooter }: MessageResponseProps) => {
  switch (item.status) {
    case ChatQueryStatus.LoadingExtended:
    case ChatQueryStatus.Loading: {
      const isTakingLong = item.status === ChatQueryStatus.LoadingExtended;
      return (
        <QueryEventsContainer queryIdentifier={item.identifier}>
          <SystemUserPlaceholderMessage isTakingLong={isTakingLong} />
        </QueryEventsContainer>
      );
    }

    case ChatQueryStatus.Error:
      return (
        <SystemUserErrorMessage
          item={item}
          traceId={item.sentryTraceId}
          hasRegenerateFooter={false} /> // hide for now, can be shown for errored variants but hidden for normal errored queries
      );

    case ChatQueryStatus.Complete:
    case ChatQueryStatus.FollowupsAvailable:
      return (
        <QueryFollowupsContainer queryIdentifier={item.identifier} queryStatus={item.status}>
          <SystemMessageContainer citations={item.citations}>
            <SystemUserMessage
              item={item}
              hasRegenerateFooter={hasRegenerateFooter} />
          </SystemMessageContainer>
        </QueryFollowupsContainer>
      );
  }
};

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});