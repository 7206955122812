import type { Chat } from '@/types';
import { markdownToRawText } from '@/utils/markdown';

export function getQueryResponseAsText(response: Chat.QueryResponse): string {
  switch (response.type) {
    case 'raw-text': return response.value;
    case 'markdown': return markdownToRawText(response.value);

    default: {
      throw new UnreachableCaseError(response);
    }
  }
}