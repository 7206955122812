import { useContext } from 'react';
import { WorkspaceFilePreviewContainer } from '@/containers/Workspace.File.Preview/FilePreviewContainer';
import { WorkspaceFilePreviewLoadingContext } from '@/containers/Workspace.File.Preview/Context';
import { TabViewSkeleton } from '@/components/TabView';
import { FileTabViewContainer } from './File.TabView.Container';
import { Header } from './Header';
import styles from './style/Workspace.File.module.css';

const StateDependent = () => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {isLoading
            ? <TabViewSkeleton />
            : <FileTabViewContainer />}
      </div>
    </div>
  );
};

const WorkspaceFileContainer = (props: ChildrenProps) => (
  <WorkspaceFilePreviewContainer>
    {props.children}
  </WorkspaceFilePreviewContainer>
);

export const WorkspaceFile = () => {
  return (
    <WorkspaceFileContainer>
      <StateDependent />
    </WorkspaceFileContainer>
  );
};