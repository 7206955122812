import { forwardRef, memo } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Loader } from 'react-feather';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

const Spinner = memo(forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <Root className={props.className} ref={ref}>
      <Wrap>
        <Loader color={props.color} size={props.size} />
      </Wrap>
    </Root>
  );
}));

const clockwiseAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Root = styled.div(({ theme }) => ({
  animationName: clockwiseAnimation,
  animationIterationCount: 'infinite',
  animationDuration: '1.1s',
  animationTimingFunction: 'linear',
  color: theme.palette.sentiment.primary.main,
}));

const Wrap = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export { Spinner };
export default Spinner;