import { AlertCircle } from 'react-feather';
import type { ToastComponentProps } from './interfaces';
import styles from './style.module.css';
import * as Toast from './Toast.Base';

type Props = ToastComponentProps;

export const Info = (props: Props) => {
  return (
    <Toast.Base
      Icon={AlertCircle}
      iconClassName={styles.info}
      body={props.body}
      title={props.title} />
  );
};

Info.displayName = 'Toast.Info';