import { useMemo, useEffect } from 'react';
import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { GroupTable } from '@/components//presentation/GroupTable';
import { cx } from '@/utils';
import { useFilesTableColumns } from './hooks/useFilesTableColumns';
import type { IFilesTable } from './interfaces';
import styles from './style/Files.Table.module.css';

type Props = {
  classes?: {
    root?: string;
  };
  canUpload: boolean;
  items: IFilesTable.Item[];
  onUpload?: (files: File[]) => unknown;
  pagination: {
    pageIndex: number;
    pageSize: number;
    sortBy: IFilesTable.SortingRule[];
    updateSortBy: (sortBy: IFilesTable.SortingRule) => unknown;
  };
};

export const FilesTableList = ({ canUpload, items, pagination: { pageIndex, pageSize, sortBy, updateSortBy }, onUpload, classes = {} }: Props) => {
  const columns = useFilesTableColumns();

  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: items,
      initialState: {
        // @ts-ignore
        pageIndex,
        pageSize,
        sortBy: sortBy.map(sb => ({ id: sb.field, desc: sb.desc })),
      },
      manualSortBy: true,
    },
    useFlexLayout,
    useSortBy,
  );

  useEffect(() => {
    // @ts-ignore
    const sort = tableInstance.state.sortBy[0] as IFilesTable.SortingRule;
    updateSortBy({
      // @ts-ignore
      field: sort.id as IFilesTable.SortingRule['field'],
      desc: sort.desc,
    });
  }, [
    // @ts-ignore
    tableInstance.state.sortBy as IFilesTable.SortingRule[],
    updateSortBy,
  ]);

  const tableClasses = useMemo(() => ({
    body: cx(styles.body, classes.root),
    td: styles.td,
  }), [classes.root]);

  return (
    <GroupTable
      classes={tableClasses}
      instance={tableInstance} />
  );
};

export default FilesTableList;