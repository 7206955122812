export const workspaces = {
  file: (data: { fileId: number }) => ({
    pathname: `/workspaces/files/${data.fileId}`,
    state: {
      from: currentLocation(),
    },
  }),
  folder: (data: { folderId: number }) => ({
    pathname: `/workspaces/folders/${data.folderId}`,
    state: {
      from: currentLocation(),
    },
  }),
};

export function currentLocation() {
  return `${window.location.pathname}${window.location.search}`;
}