import { createNamedContext } from '@/utils';
import type { WorkspaceFolder, WorkspaceObject } from '@/types/workspace';

export type FolderActionsContextValue = {
  changeOwner: () => unknown;
  createFolder: () => unknown;
  createProject: () => unknown;
  delete: () => unknown;
  manageAccess: () => unknown;
  rename: () => unknown;
  move: () => unknown;
};

export type FolderMenuItemsContextValue = {
  canAddChildren: boolean;
  canDelete: boolean;
  canRename: boolean;
  canManageAccess: boolean;
  canMove: boolean;
};

export type RenameFolderContextValue = {
  folder: Pick<WorkspaceFolder, 'id' | 'name'>;
  rename: (name: string) => Promise<unknown>;
};

type DeleteFolder = () => Promise<unknown>;

type FolderStateContextValue = {
  /* capabilities: FolderCapabilities; */
  folder: WorkspaceFolder;
  /* meta: {
    accessCount: number;
    projectId: number;
  }; */
  object: WorkspaceObject;
};

export const DeleteFolderContext = createNamedContext<DeleteFolder>(undefined, 'WorkspaceFolders.DeleteFolder.Context');
export const FolderActionsContext = createNamedContext<FolderActionsContextValue>(undefined, 'WorkspaceFolders.FolderActions.Context');
export const FolderMenuItemsContext = createNamedContext<FolderMenuItemsContextValue>(undefined, 'WorkspaceFolders.FolderMenuItems.Context');
export const RenameFolderContext = createNamedContext<RenameFolderContextValue>(undefined, 'WorkspaceFolders.RenameFolder.Context');

export const FolderLoadingContext = createNamedContext<boolean>(undefined, 'WorkspaceFolders.FolderLoading.Context');
export const FolderStateContext = createNamedContext<FolderStateContextValue>(undefined, 'WorkspaceFolders.FolderState.Context');