/* eslint-disable react-refresh/only-export-components */
import { useContext, useMemo } from 'react';
import { createMemoryRouter, Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { AppSubscriber } from '@/containers/Subscriber/AppSubscriber';
import { RootVendors } from '@/containers/Subscriber/RootVendors';
import { Layout } from '@/Layout';
import { ChatScreen } from '@/screens/Chat';
import { VeevaLogin } from '@/screens/Inbound/Auth.Veeva.Login';
import { AppInitializing } from '@/screens/Initializing';
import { LogoutScreen } from '@/screens/Logout';
import { useAppSelector } from '@/store';
import { ProvideDefaultPathsContext, type ProvideDefaultPathsValue } from './context';

const sentryCreateMemoryRouter = Sentry.wrapCreateBrowserRouter(createMemoryRouter);

const defaultHomePath = '/';
const loginPath = '/login';

export function createRouter() {
  return sentryCreateMemoryRouter([
    {
      path: '/',
      element: (
        <RootVendors>
          <AppSubscriber>
            <ProvideDefaultPaths>
              <Layout />
            </ProvideDefaultPaths>
          </AppSubscriber>
        </RootVendors>
      ),
      errorElement: null, // note: trigger fallback component
      children: [
        { index: true, element: <Navigate to="/chat" /> },
        {
          path: 'chat/*',
          children: [
            {
              path: ':chatIdentifier?',
              element: (
                <AuthenticatedGuard>
                  <AppInitializedGuard>
                    <ChatScreen />
                  </AppInitializedGuard>
                </AuthenticatedGuard>
              ),
            },
          ],
        },
        {
          path: loginPath.substring(1),
          element: (
            <BrandingInitializedGuard>
              <VeevaLogin />
            </BrandingInitializedGuard>
          ),
        },
        {
          path: 'logout',
          element: <LogoutScreen />,
        },

        // note: last catch all route
        { path: '*', element: <Navigate to={defaultHomePath} replace /> },
      ],
    },
  ]);
}

function ProvideDefaultPaths(props: ChildrenProps) {
  const value = useMemo<ProvideDefaultPathsValue>(() => ({
    loginPath: loginPath,
    homePath: defaultHomePath,
  }), []);

  return (
    <ProvideDefaultPathsContext.Provider value={value}>
      {props.children}
    </ProvideDefaultPathsContext.Provider>
  );
}

function AppInitializedGuard(props: ChildrenProps) {
  const appState = useAppSelector(state => state.appState);
  const appInitialized = useMemo(() => appState.initialized, [appState.initialized]);

  if (!appInitialized) return <AppInitializing />;

  return props.children;
}

function BrandingInitializedGuard(props: ChildrenProps) {
  const { query } = useContext(AppThemingContext);
  const brandingInitialized = useMemo(() => !query.isPlaceholderData && !query.isFetching, [query.isPlaceholderData, query.isFetching]);

  if (!brandingInitialized) return <AppInitializing />;

  return props.children;
}

type AuthenticatedGuardProps = {
  children: React.ReactNode;
  unauthenticatedRedirectPath?: string;
};

function AuthenticatedGuard(props: AuthenticatedGuardProps) {
  const location = useLocation();
  const appState = useAppSelector(state => state.appState);
  const defaultPaths = useContext(ProvideDefaultPathsContext);

  if (!appState.authenticated) {
    return (
      <Navigate
        to={props.unauthenticatedRedirectPath || defaultPaths?.loginPath}
        state={{ from: location }}
        replace />
    );
  }

  return props.children;
}