import { type Cell } from 'react-table';
import { ContrainedGroupTags } from '@/components/presentation/TruncatedTags';
import type { IFilesTable } from './interfaces';

export const TagsCell = ({ row: { original: data } }: Cell<IFilesTable.Item>) => {
  if (!data.tags?.length) {
    return (
      <div>
        <span style={{
          fontStyle: `italic`,
          color: `var(--gray-l)`,
        }}>No tags</span>
      </div>
    );
  }

  return <ContrainedGroupTags tags={data.tags} maxTags={1} />;
};