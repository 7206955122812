import { memo, useMemo, useState, useCallback, useRef } from 'react';
import { X } from 'react-feather';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { cx } from '@/utils';
import type { Tag } from '@/types';
import styles from './style/TruncatedTags.module.css';

type Props
  = GroupTagProps;

export const GroupTag = memo(({ className, children, selected, onClick, onRemove }: Props) => (
  <button className={cx(styles.root, { [styles.selected]: selected }, className)}>
    <div className={cx(styles.content, { [styles.clickable]: !!onClick })} onClick={onClick}>
      {children}
    </div>
    {selected && (
      <div className={styles.remove} onClick={onRemove}>
        <X size={11} strokeWidth={3} />
      </div>)}
  </button>
));

export const GroupTags = memo(({ className, children }: GroupTagsProps) => (
  <div className={cx(styles.tags, className)}>
    {children}
  </div>
));

export const ContrainedGroupTags = ({ tags, maxTags, onClick }: ConstrainedGroupTagsProps) => {
  const visibleTags = useMemo(() => tags.slice(0, maxTags), [maxTags, tags]);
  const hiddenTags = useMemo(() => tags.slice(maxTags), [maxTags, tags]);

  return (
    <div className={styles.tags}>
      {visibleTags.map(t => (<GroupTag key={t.id} onClick={onClick ? () => onClick(t) : null}>{t.name}</GroupTag>))}
      {hiddenTags.length ? <HiddenTags tags={hiddenTags} onClick={onClick} /> : null}
    </div>
  );
};

type HiddenTagProps = {
  tags: Tag[];
  onClick?: (tag: Tag) => void;
};

const HiddenTags = ({ tags, onClick }: HiddenTagProps) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handleClickAway = useCallback(() => setPopperOpen(false), []);
  const handleTogglePopper = useCallback(() => setPopperOpen(prev => !prev), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ marginLeft: '6px' }}>
        <button className={cx(styles.root, styles.tag)} ref={ref}>
          <div className={cx(styles.content, styles.clickable)} onClick={handleTogglePopper}>
            + {tags.length}
          </div>
        </button>
        <Popper
          className={styles.hiddenPopper}
          open={popperOpen}
          anchorEl={ref.current}>
          <GroupTags>
            {tags.map(tag => (
              <GroupTag
                key={tag.id}
                onClick={onClick ? () => onClick(tag) : null}>
                {tag.name}
              </GroupTag>
            ))}
          </GroupTags>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default GroupTag;

type GroupTagProps = {
  className?: string;
  selected?: boolean;
  onClick?: () => unknown;
  onRemove?: () => unknown;
} & ChildrenProps;

type GroupTagsProps = {
  className?: string;
} & ChildrenProps;

type ConstrainedGroupTagsProps = {
  tags: Tag[];
  onClick?: (tag: Tag) => void;
  maxTags: number;
};