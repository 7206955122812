import { memo } from 'react';
import { UploadCloud } from 'react-feather';
// import { useFileDragDrop } from '@/components/FileDragDrop/hooks/useFileDragDrop';
import { cx } from '@/utils';
// import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import styles from './style/FilesTableEmptyState.module.css';

type Props = {
  // className?: string;
};

export const FilesTableEmptyState = memo((props: Props) => {

  return (
    <div className={cx(styles.root)}>
      <div className={styles.iconWrap}>
        <UploadCloud
          className={styles.icon} />
      </div>
      <div className={styles.title}>Organize your files</div>
    </div>
  );
});
/*
type UploadProps = {
  // className?: string;
};

export const FilesTableEmptyStateUpload = (props: UploadProps) => {
  const onUpload = useContext(UploadFilesContext);
  const { query } = useContext(RateLimitingContext);

  const { dragging, dropRef } = useFileDragDrop({
    disabled: query.data?.documents?.exceeded,
    onDrop: onUpload,
  });

  return (
    <div
      ref={dropRef}
      className={cx(styles.root, {
        [styles.dragging]: dragging,
      })}>
      <div className={styles.iconWrap}>
        <UploadCloud
          className={styles.icon} />
      </div>
      <div className={styles.title}>Organize your files in Sentiment</div>
      <div className={styles.text}>Drop files here to get started</div>
    </div>
  );
};

export default FilesTableEmptyState;
*/