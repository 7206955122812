import { SentryErrorBoundary } from '@/containers/Subscriber/Sentry.ErrorBoundary';
import { GlobalStylesContainer } from './GlobalStyles';
import { QueryParamsClient } from './QueryParamsClient';
import ReactQueryClient from './ReactQueryClient';
import { ReduxProvider } from './ReduxProvider';

type Props = ChildrenProps;

export default function RootVendors({ children }: Props) {
  return (
    <SentryErrorBoundary>
      <ReactQueryClient>
        <QueryParamsClient>
          <ReduxProvider>
            <GlobalStylesContainer>
              {children}
            </GlobalStylesContainer>
          </ReduxProvider>
        </QueryParamsClient>
      </ReactQueryClient>
    </SentryErrorBoundary>
  );
}

export { RootVendors };