import { memo, useCallback } from 'react';
import { cx } from '@/utils';
import styles from './style/GroupTable.module.css';

type Props = {
  isSorted: boolean;
  isSortedDesc: boolean;
  toggleSort: (desc: boolean, multi: boolean) => void;
} & ChildrenProps;

export const GroupTableSortBy = memo(({ children, isSorted, isSortedDesc, toggleSort }: Props) => {

  const chevronClass = cx({
    [styles.chevronDown]: isSortedDesc,
    [styles.chevronUp]: !isSortedDesc,
  });

  const toggle = useCallback(() => {
    toggleSort(!isSortedDesc, false);
  }, [
    isSortedDesc,
    toggleSort,
  ]);

  return (
    <div className={styles.sortBy} onClick={toggle}>
      {children}
      {isSorted && <span className={chevronClass} />}
    </div>
  );
});

export default GroupTableSortBy;