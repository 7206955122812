import { useCallback } from 'react';
import type { TableInstance, Row } from 'react-table';
import { cx } from '@/utils';
import { GroupTableCell, GroupTableRow } from './Body';
import { GroupTableHeader, GroupTableHeaderCell, GroupTableHeaderRow } from './Header';
import { GroupTableSortBy } from './SortBy';

type TableClasses = {
  body?: string;
  header?: string;
  table?: string;
  td?: string;
  th?: string;
  tr?: string;
};

type Props = {
  classes?: TableClasses;
  instance: (TableInstance & {
    page?: Row[];
  });
  header?: boolean;
  renderRow?: (row: Row) => JSX.Element;
};

export const GroupTable = ({
  classes = {},
  instance: {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    rows,
  },
  header = true,
  renderRow,
}: Props) => {

  const renderBody = useCallback(() => {
    return (
      <>
        {(page || rows).map(row => {
          prepareRow(row);

          if (renderRow) {
            return renderRow(row);
          }

          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <GroupTableRow
              key={rowKey}
              {...rowProps}
              className={cx(rowProps.className, classes.tr)}>
              {row.cells.map(cell => {
                const { key: cellKey, ...cellProps } = cell.getCellProps();
                return (
                  <GroupTableCell
                    key={cellKey}
                    {...cellProps}
                    className={cx(cellProps.className, classes.td)}>
                    {cell.render('Cell')}
                  </GroupTableCell>
                );
              })}
            </GroupTableRow>
          );
        })}
      </>
    );
  }, [
    classes,
    page,
    prepareRow,
    renderRow,
    rows,
  ]);

  const renderHeader = useCallback(() => {
    return (
      <GroupTableHeader className={classes.header}>
        {headerGroups.map(headerGroup => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <GroupTableHeaderRow
              key={key}
              {...headerGroupProps}>
              {headerGroup.headers.map(column => {
                const { key: headerGroupKey, ...rest } = column.getHeaderProps();
                const col = column as (typeof column & {
                  canSort: boolean;
                  isSorted: boolean;
                  isSortedDesc: boolean;
                  toggleSortBy: () => void;
                });

                return (
                  <GroupTableHeaderCell
                    key={headerGroupKey}
                    {...rest}
                    className={cx(rest.className, classes.th)}>
                    {col.canSort
                      ? (
                        <GroupTableSortBy
                          isSorted={col.isSorted}
                          isSortedDesc={col.isSortedDesc}
                          toggleSort={col.toggleSortBy}>
                          {column.render('Header')}
                        </GroupTableSortBy>
                      )
                      : column.render('Header')
                    }
                  </GroupTableHeaderCell>
                );
              })}
            </GroupTableHeaderRow>
          );
        })}
      </GroupTableHeader>
    );
  }, [
    classes,
    headerGroups,
  ]);

  const tableProps = getTableProps();

  return (
    <div {...tableProps} className={cx(tableProps.className, classes.table)}>
      {header && renderHeader()}
      <div className={classes.body}>
        {renderBody()}
      </div>
    </div>
  );
};

export default GroupTable;