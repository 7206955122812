import { createElement } from 'react';
import { Check, X } from 'react-feather';
import type { ToastComponentProps } from './interfaces';
import styles from './style.module.css';
import { Icon as ToastIcon } from './Toast.Base.Icon';

type Props<T = unknown> = {
  Icon: React.ComponentType<T>;
  iconClassName?: string;
  iconSize?: number;
} & ToastComponentProps;

const ToastBase = ({
  Icon = Check,
  ...props
}: Props) => {
  const icon = createElement(ToastIcon as Props['Icon'], {
    Icon,
    className: props.iconClassName,
    size: props.iconSize,
  } as unknown);

  return (
    <div className={styles.item}>
      {icon}
      <div className={styles.main}>
        <div className={styles.title}>{props.title}</div>
        {props.body && <div className={styles.body}>{props.body}</div>}
      </div>
      <div className={styles.close}>
        <X />
      </div>
    </div>
  );
};

ToastBase.displayName = 'Toast.Base';

ToastBase.Icon = ToastIcon;

export { ToastBase as Base };