import { useContext } from 'react';
import { SecondaryButton } from '@/components/presentation/Button.Secondary';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { useModal } from '@/hooks/useModal';
import { Cropper } from './Logo.Cropper';
import * as Landscape from './Logo.Landscape';
import * as Square from './Logo.Square';
import styles from './style/Settings.Branding.Logo.module.css';

type Props = unknown;

export const Logo = (props: Props) => {
  const { query } = useContext(AppThemingContext);
  const [toggle, UploadModal] = useModal(Cropper);

  if (query.isLoading) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Logos</h2>
        <div className={styles.main}>
          <div className={styles.upload}>
            <SecondaryButton
              className={styles.btn}
              color="silver"
              onClick={toggle}>
              Manage Logos
            </SecondaryButton>
          </div>
          <div className={styles.frame}>
            <Square.Image imageSrc={query.data?.logo?.square} />
          </div>
          <div className={styles.frame}>
            <Landscape.Image imageSrc={query.data?.logo?.horizontal} />
          </div>
        </div>
      </div>
      <UploadModal />
    </div>
  );
};

Logo.displayName = 'Settings.Branding.Logo';