import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { ChevronLeft, ChevronRight } from 'react-feather';

type Props = {
  className?: string;
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => unknown;
  pageIndex: number;
  pageSize: number;
  previousPage: () => unknown;
  totalCount: number;
};

export const TablePagination = memo(({
  canNextPage,
  canPreviousPage,
  className,
  nextPage,
  pageIndex,
  pageSize,
  previousPage,
  totalCount,
}: Props) => {

  const display = useMemo(() => {
    const start = pageIndex * pageSize;
    const end = Math.min(start + pageSize, totalCount);
    const startDisplay = Math.min(start + 1, totalCount);
    return `${startDisplay} - ${end} of ${totalCount}`;
  }, [
    pageIndex,
    pageSize,
    totalCount,
  ]);

  return (
    <Root className={className}>
      <Count>{display}</Count>
      <Navigation>
        <StyledChevronLeft
          aria-disabled={!canPreviousPage}
          onClick={canPreviousPage ? previousPage : undefined} />
        <StyledChevronRight
          aria-disabled={!canNextPage}
          onClick={canNextPage ? nextPage : undefined} />
      </Navigation>
    </Root>
  );
});

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Count = styled.div(({ theme }) => ({
  marginRight: 15,
  color: theme.palette.gray.dark1,
}));

const Navigation = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: 60,
});

const StyledChevronLeft = styled(ChevronLeft)(props => ({
  cursor: props['aria-disabled'] ? 'default' : 'pointer',
  borderRadius: '50%',
  color: props['aria-disabled'] ? props.theme.palette.gray.main : props.theme.palette.black.light2,
  width: 25,
  height: 25,
  transitionProperty: 'color, background-color',
  transitionDuration: '200ms',

  ':hover': {
    backgroundColor: props['aria-disabled'] ? 'unset' : props.theme.palette.gray.light2,
  },
}));

const StyledChevronRight = styled(ChevronRight)(props => ({
  cursor: props['aria-disabled'] ? 'default' : 'pointer',
  borderRadius: '50%',
  color: props['aria-disabled'] ? props.theme.palette.gray.main : props.theme.palette.black.light2,
  width: 25,
  height: 25,
  transitionProperty: 'color, background-color',
  transitionDuration: '200ms',

  ':hover': {
    backgroundColor: props['aria-disabled'] ? 'unset' : props.theme.palette.gray.light2,
  },
}));