import { forwardRef, useMemo } from 'react';
import { cx } from '@/utils';
import type { ButtonProps } from './interfaces';
import styles from './style.module.css';

type Props = ButtonProps;

const ButtonBase = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const attributes = extractHTMLAttributes(props);
  const className = useResolveButtonClass(props);

  return (
    <button
      className={className}
      ref={ref}
      {...attributes}>
      {props.title || props.children}
    </button>
  );
});

type CSSProps =
  Pick<ButtonProps,
    | 'className'
    | 'color'
    | 'implicitDisable'
    | 'size'
    | 'variant'>;

const useResolveButtonClass = (props: CSSProps) => {

  return useMemo(() => {
    const colorStyles = {
      affirmative: styles.affirmative,
      destructive: styles.destructive,
      primary: styles.primary,
      secondary: styles.secondary,
      transparent: styles.transparent,
      delete: styles.delete,
    }[props.color] || cx(styles.primary, {
      [styles.transparent]: props.variant === 'link',
    });

    const variantStyles = {
      brick: styles.brick,
      pill: styles.pill,
      link: styles.link,
      text: styles.text,
    }[props.variant] || styles.pill;

    const implicitStyles = {
      [styles.implicit]: props.implicitDisable,
    };

    const sizeStyles = {
      small: styles.smallSize,
    }[props.size];

    return cx(
      colorStyles,
      variantStyles,
      implicitStyles,
      props.className,
      sizeStyles,
    );
  }, [props]);

};

function extractHTMLAttributes(props: Props): React.ButtonHTMLAttributes<HTMLButtonElement> {
  const { children, className, color, implicitDisable, variant, title, ...other } = props;

  return other;
}

export default ButtonBase;