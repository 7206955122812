import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams, type Location } from 'react-router-dom';

type Props<T extends string> = {
  tabParam: string | null;
  formatPath: (tab: T, location: Location) => string;
  routes: T[];
};

type Params = {
  tab: string;
};

export const useTabViewState = <T extends string>({ formatPath, routes, tabParam }: Props<T>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const resolvedTab = useMemo(() => {
    if (!tabParam) return null;
    return tabParam.split('?')[0] as T;
  }, [tabParam]);

  const tabIndex = useMemo(() => {
    const idx = routes.indexOf(resolvedTab);

    return idx >= 0 && idx < routes.length
      ? idx
      : 0;
  }, [
    resolvedTab,
    routes,
  ]);

  const [index, setIndex] = useState(tabIndex);

  const setTabIndex = useCallback(() => {

    setIndex(tabIndex);
  }, [
    setIndex,
    tabIndex,
  ]);

  const setLocation = useCallback((idx: number) => {
    const key = routes[idx];
    const path = formatPath(key, location);

    navigate(path);
  }, [
    formatPath,
    navigate,
    location,
    routes,
  ]);

  useEffect(() => {

    if (resolvedTab) {
      setTabIndex();
    }

  }, [
    resolvedTab,
    setTabIndex,
  ]);

  const state = {
    index,
    routes,
  };

  return [state, setLocation] as const;
};

export default useTabViewState;