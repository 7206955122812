/** @jsxImportSource @emotion/react */
import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AnalysisQueryHint } from '@/components/presentation/Message.QueryHint';
import { Spinner } from '@/components/presentation/Spinner';
import { KolSearchStatus } from '@/enums/kol';
import { useIsDemoGroup } from '@/store/selectors';
import {
  DispatchKolSearchFormContext,
  KolSearchFormContext,
  KolSearchRecommendationProfileSelectedContext,
  KolSearchRecommendationsContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
} from './context';
import { KolSearchInputForm } from './KolSearch.Form';
import { KolResultsTable } from './KolSearch.Table';
import { messageLaneRightPadding, messageLaneWidth, messageLaneLeftPadding } from './Message.styles';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolRecommendations = () => {
  const status = useContext(KolSearchStatusContext);
  const selectProfile = useContext(KolSearchRecommendationProfileSelectedContext);
  const isDemoGroup = useIsDemoGroup();

  const recommendations = useContext(KolSearchRecommendationsContext);

  const formState = useContext(KolSearchFormContext);
  const setFormState = useContext(DispatchKolSearchFormContext);
  const inputFocused = useRef(false);
  const handleSubmit = useContext(KolSearchSubmitContext);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  const copy = getCopy(isDemoGroup);

  const disabled = useMemo(() => ({
    profileSelection: status !== KolSearchStatus.ProfileRecommendations,
    search: status !== KolSearchStatus.ProfileRecommendations,
    input: status !== KolSearchStatus.ProfileRecommendations,
    submit: status !== KolSearchStatus.ProfileRecommendations || !formState.name,
  }), [status, formState.name]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {

    if (!inputFocused.current) return;

    if (disabled.submit) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }, [disabled, handleSubmit]);

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={css({
        minWidth: messageLaneWidth,
        maxWidth: messageLaneWidth,
      })}>
        <Body>
          {copy.header}
          {!recommendations || ['uninitialized', 'loading'].includes(recommendations.status)
            ? <StyledSpinner />
            : <StyledRecommendationsTable
              selectionDisabled={disabled.profileSelection}
              onSelectProfile={selectProfile}
              records={recommendations?.items} />}

          <Divider />
          {copy.alt}

          <StyledForm
            formState={formState}
            inputDisabled={disabled.input}
            onKeyDown={handleKeyDown}
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            setFormState={setFormState} />
          <StyledAnalysisQueryHint
            disabled={disabled.submit}
            onClick={handleSubmit}
            value="Search for profiles." />


        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

function getCopy(isDemoGroup: boolean) {
  return {
    header: isDemoGroup
      ? `We recommend following up with the following HCPs, given it has been a while since you last interacted with them.`
      : `These are the potential HCPs that you can engage with in your territory.`,
    alt: `Or if you're looking for a different HCP, get started with an HCP search.`,
  };
}

const MessageContainer = styled.div({
  paddingLeft: messageLaneLeftPadding,
  paddingRight: messageLaneRightPadding,
  // padding: `15px ${messageLaneRightPadding}px 15px ${messageLaneLeftPadding}px`,
});

const Body = styled.div({
  // width: 725,
});

const StyledRecommendationsTable = styled(KolResultsTable)({
  marginTop: 15,
  marginBottom: 15,
});

const StyledAnalysisQueryHint = styled(AnalysisQueryHint)({
  marginTop: 15,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const StyledSpinner = styled(Spinner)({
  margin: '50px 0',
});

const StyledForm = styled(KolSearchInputForm)({
  marginTop: 15,
  width: 500,
});