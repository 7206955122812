import * as React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { config } from '@/config';
import { isProdEnv } from './config/utils';

Sentry.init({
  dsn: config().SENTRY_DSN,
  enabled: config().SENTRY_ENABLED,
  environment: config().APP_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.dedupeIntegration(),
    Sentry.replayIntegration(),
  ],
  sampleRate: 1,
  tracesSampleRate: isProdEnv() ? 0.5 : 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: config().SENTRY_RELEASE,
  initialScope: scope => {
    scope.setTag('platform', config().APP_PLATFORM);
    return scope;
  },
});