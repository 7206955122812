import type { Ref } from 'react';
import { memo, forwardRef } from 'react';
import { ChevronRight } from 'react-feather';
import { cx } from '@/utils';
import styles from './style.module.css';

export type PopperMenuProps = {
  className?: string;
} & ChildrenProps;

export const PopperMenu = memo(forwardRef((props: PopperMenuProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div
      className={cx(styles.menu, props.className)}
      ref={ref}>
      {props.children}
    </div>
  );
}));

export type PopperMenuItemProps = {
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & ChildrenProps;

export const PopperMenuItem = memo(forwardRef((props: PopperMenuItemProps, ref: Ref<HTMLDivElement>) => {

  const className = cx(styles.item, props.className, {
    [styles.disabled]: props.disabled,
  });

  const onClick = props.disabled
    ? undefined
    : props.onClick;

  return (
    <div
      ref={ref}
      className={className}
      onClick={onClick}>
      {props.children}
    </div>
  );
}));

export const ParentPopperMenuItem = memo(forwardRef(({
  children,
  ...props
}: PopperMenuItemProps, ref: Ref<HTMLDivElement>) => {

  const className = cx(styles.parent, props.className);

  return (
    <PopperMenuItem
      {...props}
      className={className}
      ref={ref}>
      {children}
      <ChevronRight className={styles.chevron} />
    </PopperMenuItem>
  );
}));

export default PopperMenu;