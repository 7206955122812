import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { config } from '@/config';
import { getAuthProvider } from './auth-provider';

let client: AxiosInstance = null;
export function getClient() {
  if (client) return client;

  client = axios.create({
    baseURL: config().BRAND_INSIGHTS_API_URL,
  });

  client.interceptors.request.use((config) => {
    const $provider = getAuthProvider();
    switch ($provider.authSource) {
      case 'cookie': {
        config.withCredentials = true;
        break;
      }
      case 'jwt': {
        if ($provider.isAuthenticated) {
          config.headers.set('Authorization', `Bearer ${$provider.accessToken}`);
        }
        break;
      }
    }
    return config;
  });

  return client;
}