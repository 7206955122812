/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CancelAction } from '@/components/presentation/Message.QueryHint';
import { KolSearchStatus } from '@/enums/kol';
import {
  KolSearchFormContext,
  KolSearchResultsContext,
  KolSearchCancelContext,
  KolSearchStatusContext,
  UpdateKolSearchProfileContext,
  KolResetSearchContext,
} from './context';
import { KolResultsTable } from './KolSearch.Table';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolResultsMessage = () => {
  const status = useContext(KolSearchStatusContext);
  const formState = useContext(KolSearchFormContext);
  const results = useContext(KolSearchResultsContext);
  const cancelSearch = useContext(KolSearchCancelContext);
  const selectProfile = useContext(UpdateKolSearchProfileContext);
  const handleNewSearch = useContext(KolResetSearchContext);

  const copy = `Sure, I found the following HCPs matching the name "${formState.name}". Select a profile by clicking "View Profile" and ask additional questions.`;

  const isInteractive = status === KolSearchStatus.MultipleResultsReturned;

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={css({
        // minWidth: '100%',
        maxWidth: '100%',
      })}>
        <Body>
          {copy}
          <StyledResultsTable
            onSelectProfile={selectProfile}
            selectionDisabled={!isInteractive}
            pageSize={10}
            records={results} />
          {isInteractive && (
            <StyledCancelButton
              displayValue="Start a new search"
              onClick={handleNewSearch} />
          )}
          {isInteractive && (
            <StyledCancelButton
              displayValue="View recommended HCPs"
              onClick={cancelSearch} />
          )}
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

const StyledCancelButton = styled(CancelAction)({
  marginTop: 15,
});

const StyledResultsTable = styled(KolResultsTable)({
  marginTop: 15,
});

const Body = styled.div({
  // width: 725,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});