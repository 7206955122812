/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import styled from '@emotion/styled';
import { InteractionType } from '@/enums';
import type { Chat } from '@/types';
import { toTitleCase } from '@/utils';

type CrmNoteProps<T extends InteractionType = InteractionType> = {
  data: Chat.CrmNoteCitationMetadata<T>;
};

export const CrmNote = memo(({ data }: CrmNoteProps) => {

  switch (data.type) {
    case InteractionType.CallCenterInteraction:
      return <CallCenterInteraction data={data} />;
    case InteractionType.MedicalInsight:
      return <MedicalInsight data={data} />;
    case InteractionType.DocumentedInterest:
      return <DocumentedInterest data={data} />;
    case InteractionType.FbmsInteraction:
      return <FbmsInteraction data={data} />;
    default:
      throw new UnreachableCaseError(data);
  }

});

const FbmsInteraction = memo(({ data }: CrmNoteProps<InteractionType.FbmsInteraction>) => {
  const educationGapValue = data.munQuestion1
    ? data.munQuestion3
      ? `${data.munQuestion1} - ${data.munQuestion3}`
      : data.munQuestion1
    : null;

  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`MSL`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.accountName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`HCP Name`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.kolName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Product`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.productName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Franchise`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.franchise}</Attribute></AttributeContainer>
      </ItemContainer>
      { }
      {educationGapValue &&
        <ItemContainer>
          <AttributeContainer><LabelAttribute>{`Education Gap`}</LabelAttribute></AttributeContainer>
          <AttributeContainer><Attribute>{educationGapValue}</Attribute></AttributeContainer>
        </ItemContainer>}
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Source`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.source}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Table`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.table}</Attribute></AttributeContainer>
      </ItemContainer>
    </Container>
  );
});

const CallCenterInteraction = memo(({ data }: CrmNoteProps<InteractionType.CallCenterInteraction>) => {

  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`HCP Name`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.kolName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Product`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.productName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Topic`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.therapeuticArea}</Attribute></AttributeContainer>
      </ItemContainer>
      {data.interactionSubType === 'MIR' && (
        <ItemContainer>
          <AttributeContainer><LabelAttribute>Request</LabelAttribute></AttributeContainer>
          <AttributeContainer><Attribute>{data.request}</Attribute></AttributeContainer>
        </ItemContainer>
      )}
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Source`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.src} ({data.subSrc})</Attribute></AttributeContainer>
      </ItemContainer>
      {data.category && (
        <ItemContainer>
          <AttributeContainer><LabelAttribute>{`Category`}</LabelAttribute></AttributeContainer>
          <AttributeContainer><Attribute>{data.category} {data.subCategory ? `(${data.subCategory})` : ''}</Attribute></AttributeContainer>
        </ItemContainer>
      )}
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Table`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.table}</Attribute></AttributeContainer>
      </ItemContainer>
    </Container>
  );
});

const DocumentedInterest = memo(({ data }: CrmNoteProps<InteractionType.DocumentedInterest>) => {

  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`MSL`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.accountName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`HCP Name`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.kolName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Product`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.productName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Interest Areas`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.interestAreas}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Interest Verbatim`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.interest}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Source`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.source}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Table`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.table}</Attribute></AttributeContainer>
      </ItemContainer>
    </Container>
  );
});

const MedicalInsight = memo(({ data }: CrmNoteProps<InteractionType.MedicalInsight>) => {

  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`MSL`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.accountName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`HCP Name`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.kolName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Topics`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{[data.therapeuticArea, data.therapeuticSubArea].join(', ')}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Product`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.product}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Sentiment`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{toTitleCase(data.sentiment)}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Insight`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.note}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Source`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.source}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Table`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.table}</Attribute></AttributeContainer>
      </ItemContainer>
    </Container>
  );
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '125px auto',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AttributeContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
}));

const Container = styled.ol(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,
}));

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const LabelAttribute = styled(Attribute)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light3,
  fontFamily: theme.fonts.semiBold,
  fontSize: 14,
}));