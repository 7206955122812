import { AppReadyStateContainer } from '@/containers/AppReadyState';
import { AppStateEffectContainer } from '@/containers/AppStateEffect';
import { AppThemingContainer } from '@/containers/AppTheming';
import { QueryDownloaderContainer } from '@/containers/QueryDownloader/QueryDownloader.Container';
import { MuiThemeProvider } from './MuiThemeProvider';

export const AppSubscriber = (props: ChildrenProps) => {

  return (
    <QueryDownloaderContainer>
      <AppReadyStateContainer>
        <AppStateEffectContainer>
          <AppThemingContainer>
            <MuiThemeProvider>
              {props.children}
            </MuiThemeProvider>
          </AppThemingContainer>
        </AppStateEffectContainer>
      </AppReadyStateContainer>
    </QueryDownloaderContainer>
  );
};