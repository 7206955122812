import { Folder } from 'react-feather';
import { type Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { isAfter } from 'date-fns';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import { WorkspaceObjectType } from '@/enums';
import { Tooltip } from '@/components/presentation/Tooltip';
import { FileIcon } from '@/components/icons/File';
import type { IWorkspaceFileItem, IWorkspaceFolderItem, WorkspaceObjectItem } from '@/types/workspace.table';
import { getLocationFor } from '@/utils';
import styles from './style/Files.Table.Cells.module.css';

export function NameCell(props: Cell<WorkspaceObjectItem>) {
  switch (props.row.original.object.typeId) {
    case WorkspaceObjectType.File:
      return <FileNameCell {...(props as Cell<IWorkspaceFileItem>)} />;

    case WorkspaceObjectType.Folder:
      return <FolderNameCell {...(props as Cell<IWorkspaceFolderItem>)} />;

    default:
      // @ts-expect-error
      throw new UnreachableCaseError(props.row.original.object.typeId);
  }
}

function FolderNameCell({ row: { original: data } }: Cell<IWorkspaceFolderItem>) {

  const to = getLocationFor.workspaces.folder({
    folderId: data.folder.id,
  });

  return (
    <div className={styles.name}>
      <Folder
        className={styles.icon}
        color="#737373"
        size={26} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.object.name}</div>
      </Link>
    </div>
  );
}

function FileNameCell(props: Cell<IWorkspaceFileItem>) {
  const data = props.row.original;

  const to = getLocationFor.workspaces.file({ fileId: data.file.id });

  return (
    <div className={styles.name}>
      <FileIcon
        className={styles.icon}
        extension={data.file.extension} />
      <div className={styles.fileName}>
        <Link to={to}>
          <div className={styles.trunc}>{data.object.name}</div>
        </Link>
        <ExpiredStatus expiresOn={data.object?.expiresOn} />
      </div>
    </div>
  );
}

const ExpiredStatus = (props: { expiresOn: Date }) => {
  if (props.expiresOn && !isAfter(props.expiresOn, new Date())) {
    return (
      <Tooltip title="Expired">
        <div className={styles.expired}>
          <WarningIcon />
        </div>
      </Tooltip>
    );
  }

  return null;
};