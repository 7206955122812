import { memo } from 'react';
import styled from '@emotion/styled';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';
import { shouldForwardProp } from '@/utils';

type Props = {
  className?: string;
  value: string;
  selected: boolean;
  onClick: () => unknown;
};

type RootProps = Pick<Props, 'selected'>;

export const MessageCitationSnippetGroupSelector = memo(({ className, value, selected, onClick }: Props) => {
  return (
    <Root
      className={className}
      selected={selected}
      onClick={onClick}>
      <Wrap>
        {value}
      </Wrap>
    </Root>
  );
});

const Root = styled('button', { shouldForwardProp: shouldForwardProp('selected') })<RootProps>(({ theme, selected }) => {
  const { Text } = Presentation.Colors(theme);

  return {
    boxSizing: 'border-box',
    backgroundColor: selected ? Text.enabled.hover.backgroundColor : Text.enabled.backgroundColor,
    borderRadius: 8,
    color: theme.palette.black.light1,
    display: 'inline-block',
    padding: '4px 8px',
    fontSize: 12,
    '&:hover': {
      backgroundColor: Text.enabled.hover.backgroundColor,
    },
  };
});

const Wrap = styled.div({
  display: 'flex',
  alignItems: 'center',
});