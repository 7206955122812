import { Outlet } from 'react-router-dom';
import { NavBar } from '@/components/Nav/NavBar';
import Toast from '@/components/Toast';
import { DocumentHelmet } from './Helmet';

export const Layout = () => {
  return (
    <>
      <DocumentHelmet />
      <div>
        <NavBar />
        <Outlet />
      </div>
      <Toast />
    </>
  );
};