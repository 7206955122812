import { createSlice } from '@reduxjs/toolkit';
import type { GroupState } from './interfaces';
import { sharedActions } from './shared-actions';

export const groupSlice = createSlice({
  name: 'group',
  initialState: null as GroupState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(sharedActions.appStatedLoaded, (state, action) => {
      return action.payload.group;
    });
  },
});

export default groupSlice.reducer;