import { useCallback, useState } from 'react';
import { ChatCustomFilter } from '@/enums/chat.filters';
import type { CustomFilter } from '@/types/chat.filters';
import * as $cf from './custom-filters';
import { CustomFilterDateFilter } from './CustomFilter.DateSelect';
import { CustomFilterMultiselect } from './CustomFilter.Multiselect';
import { useFilterSearchQuery } from './hooks/useFilterSearch';

type Props<T extends ChatCustomFilter = ChatCustomFilter> = {
  onSelect: (value: CustomFilter.Item['value']) => void;
  value: CustomFilter.Item<T>;
};

export function CustomFilterValue(props: Props) {

  switch (props.value.type) {

    case ChatCustomFilter.PracticeSetting:
      return (
        <CustomFilterMultiselect
          placeholder="Select Practice Setting"
          onSelect={props.onSelect}
          options={$cf.getPracticeSettings()}
          value={props.value.value} />
      );

    case ChatCustomFilter.InsightType:
      return (
        <CustomFilterMultiselect
          placeholder="Select Insight Type"
          isSearchable={false}
          onSelect={props.onSelect}
          options={$cf.getInsightTypes()}
          value={props.value.value} />
      );

    case ChatCustomFilter.Product:
      return (
        <ProductFilterSelect
          onSelect={props.onSelect}
          value={props.value} />
      );

    case ChatCustomFilter.Source:
      return null;

    case ChatCustomFilter.TherapeuticArea:
      return (
        <TherapeuticAreasSelect
          onSelect={props.onSelect}
          value={props.value} />
      );

    case ChatCustomFilter.TherapeuticSubArea:
      return (
        <TherapeuticSubAreaFilterSelect
          onSelect={props.onSelect}
          value={props.value} />
      );

    case ChatCustomFilter.Sentiment:
      return (
        <CustomFilterMultiselect
          placeholder="Select Sentiment"
          isSearchable={false}
          onSelect={props.onSelect}
          options={$cf.getSentimentValues()}
          value={props.value.value} />
      );

    case ChatCustomFilter.InteractionDate:
      return (
        <CustomFilterDateFilter
          onSelect={props.onSelect}
          value={props.value.value} />
      );

    case ChatCustomFilter.InterestArea:
      return (
        <InterestAreaFilterSelect
          onSelect={props.onSelect}
          value={props.value} />
      );

    case ChatCustomFilter.InteractionType:
      return (
        <CustomFilterMultiselect
          placeholder="Select Interaction Type"
          onSelect={props.onSelect}
          options={$cf.getInteractionTypes()}
          value={props.value.value} />
      );

    case ChatCustomFilter.InteractionSubType:
      return (
        <CustomFilterMultiselect
          placeholder="Select Interaction Sub Type"
          onSelect={props.onSelect}
          options={$cf.getInteractionSubTypes()}
          value={props.value.value} />
      );

    case ChatCustomFilter.InteractionDetail:
      return (
        <CustomFilterMultiselect
          placeholder="Select Interaction Detail"
          onSelect={props.onSelect}
          options={$cf.getInteractionDetails()}
          value={props.value.value} />
      );

    case ChatCustomFilter.CallCenterSource:
      return (
        <CustomFilterMultiselect
          placeholder="Select Call Center Source"
          onSelect={props.onSelect}
          options={$cf.getCallCenterSources()}
          value={props.value.value} />
      );

    case ChatCustomFilter.CallCenterSubSource:
      return (
        <CustomFilterMultiselect
          placeholder="Select Call Center Sub Source"
          onSelect={props.onSelect}
          options={$cf.getCallCenterSubSources()}
          value={props.value.value} />
      );

    default:
      throw new UnreachableCaseError(props.value);
  }
}

type TSAFilterSelectProps = {
  onSelect: (value: CustomFilter.TherapeuticSubArea['value']) => void;
  value: CustomFilter.TherapeuticSubArea;
};

const TherapeuticSubAreaFilterSelect = (props: TSAFilterSelectProps) => {

  const [input, setInput] = useState('');

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const query = useFilterSearchQuery({
    excluded: props.value.value.map(m => m.id),
    type: ChatCustomFilter.TherapeuticSubArea,
    value: input,
  });

  const loadingMessage = useCallback(() => {
    return '...Searching';
  }, []);

  return (
    <CustomFilterMultiselect
      components={{
        DropdownIndicator: null,
      }}
      placeholder="Select Therapeutic Sub Areas"
      inputValue={input}
      onInputChange={handleInputChange}
      loadingMessage={loadingMessage}
      isLoading={query.isLoading}
      isSearchable={true}
      onSelect={props.onSelect}
      options={query.data.results}
      value={props.value.value} />
  );
};

type ProductFilterSelectProps = {
  onSelect: (value: CustomFilter.Product['value']) => void;
  value: CustomFilter.Product;
};

const ProductFilterSelect = (props: ProductFilterSelectProps) => {

  const [input, setInput] = useState('');

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const query = useFilterSearchQuery({
    excluded: props.value.value.map(m => m.id),
    type: ChatCustomFilter.Product,
    value: input,
  });

  const loadingMessage = useCallback(() => {
    return '...Searching';
  }, []);

  return (
    <CustomFilterMultiselect
      components={{
        DropdownIndicator: null,
      }}
      placeholder="Select Products"
      inputValue={input}
      onInputChange={handleInputChange}
      loadingMessage={loadingMessage}
      isLoading={query.isLoading}
      isSearchable={true}
      onSelect={props.onSelect}
      options={query.data.results}
      value={props.value.value} />
  );
};

type IAFilterSelectProps = {
  onSelect: (value: CustomFilter.InterestArea['value']) => void;
  value: CustomFilter.InterestArea;
};

const InterestAreaFilterSelect = (props: IAFilterSelectProps) => {

  const [input, setInput] = useState('');

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const query = useFilterSearchQuery({
    excluded: props.value.value.map(m => m.id),
    type: ChatCustomFilter.InterestArea,
    value: input,
  });

  const loadingMessage = useCallback(() => {
    return '...Searching';
  }, []);

  return (
    <CustomFilterMultiselect
      components={{
        DropdownIndicator: null,
      }}
      placeholder="Select Interest Areas"
      inputValue={input}
      onInputChange={handleInputChange}
      loadingMessage={loadingMessage}
      isLoading={query.isLoading}
      isSearchable={true}
      onSelect={props.onSelect}
      options={query.data.results}
      value={props.value.value} />
  );
};

type TherapeuticAreasSelectProps = {
  onSelect: (value: CustomFilter.InterestArea['value']) => void;
  value: CustomFilter.TherapeuticArea;
};

const TherapeuticAreasSelect = (props: TherapeuticAreasSelectProps) => {

  const [input, setInput] = useState('');

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const query = useFilterSearchQuery({
    excluded: props.value.value.map(m => m.id),
    type: ChatCustomFilter.TherapeuticArea,
    value: input,
  });

  const loadingMessage = useCallback(() => {
    return '...Searching';
  }, []);

  return (
    <CustomFilterMultiselect
      components={{
        DropdownIndicator: null,
      }}
      placeholder="Select Therapeutic Areas"
      inputValue={input}
      onInputChange={handleInputChange}
      loadingMessage={loadingMessage}
      isLoading={query.isLoading}
      isSearchable={true}
      onSelect={props.onSelect}
      options={query.data.results}
      value={props.value.value} />
  );
};