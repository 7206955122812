import { useCallback, useMemo, useState } from 'react';


type TablePaginationProps = {
  index: number;
  pageSize: number;
  total: number;
};

export const useTablePagination = (props: TablePaginationProps) => {
  const [index, setIndex] = useState(props.index);
  const [size, setSize] = useState(props.pageSize);
  const [total, setTotal] = useState(props.total);

  const pageCount = useMemo(() => Math.ceil(total / size), [total, size]);

  const nextPage = useCallback(() => {
    setIndex(prev => Math.min(prev + 1, pageCount - 1));
  }, [pageCount]);

  const previousPage = useCallback(() => {
    setIndex(prev => Math.max(prev - 1, 0));
  }, []);

  return useMemo(() => ({
    index,
    size,
    total,
    setPageSize: setSize,
    setTotal,
    nextPage,
    previousPage,
    canNextPage: index + 1 < pageCount,
    canPreviousPage: index - 1 >= 0,
  }), [
    index, size, total, pageCount,
    setSize, setTotal, nextPage, previousPage,
  ]);
};