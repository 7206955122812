import type { ChangeEvent } from 'react';
import { forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { cx, getLocationFor } from '@/utils';
/* import * as api from '@api'; */
/* import { useQueryDownloader } from '@containers/QueryDownloader'; */
/* import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context'; */
/* import { RateLimitingContext } from '@containers/RateLimiting/Context'; */
import { Button } from '@/components/presentation/Button';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
/* import { useCreateFolderInsideParentModal } from '@/components/Workspace.Folder/NewFolderModal'; */
import Toast from '@/components/Toast';
import { WorkspaceObjectType } from '@/enums';
/* import { useCanUpload } from '@/containers/WorkspaceFileUpload/hooks'; */
/* import { useDownloadAllModal } from './DownloadAll.Modal'; */
/* import { usePublishModal } from './PublishModal'; */
/* import { useApplyTagsModal } from './ApplyTags.Modal'; */
import { FilesTableContext, FilesTableMetaContext } from './context';
import { useSelectedObjects, useSelectObjectsEnabled } from './hooks';
import styles from './style/Files.Table.Actions.module.css';

type Props = {
  className?: string;
};

const AnchorButton = memo(forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { palette, theme: wl } = useContext(AppThemingContext);

  if (wl?.enabled) {
    return (
      <Button
        ref={ref}
        color="transparent"
        className={props.className}
        style={{ backgroundColor: palette.primary.main, color: palette.primary.text }}
        variant="brick">
        Actions
      </Button>
    );
  }

  return (
    <Button
      ref={ref}
      color="primary"
      className={props.className}
      variant="brick">
      Actions
    </Button>
  );
}));

type ButtonProps = {
  className?: string;
};

type MenuItem = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => unknown;
};

export const FilesActionsButton = ({ className }: ButtonProps) => {

  const [open, setOpen] = useState(false);

  const selectObjectsEnabled = useSelectObjectsEnabled();
  const filesTableContext = useContext(FilesTableContext);
  const selectedObjects = useSelectedObjects();
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const meta = useContext(FilesTableMetaContext);
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const items = useMemo(() => {
    return [
      {
        children: `Upload File(s)`,
        disabled: true,
        onClick: () => {},
      },
      {
        children: `Add Folder`,
        disabled: true,
        onClick: () => {},
      },
      {
        children: `Download All`,
        disabled: true,
        onClick: () => {},
      },
      {
        children: `Apply Tags`,
        disabled: true,
        onClick: () => {},
      },
    ];
  }, []);

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <AnchorButton />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => {
                  return (
                    <PopperMenuItem
                      key={i}
                      {...m}
                      className={styles.menuItem}
                      onClick={m.onClick} />
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const DownloadableObjectTypes = [WorkspaceObjectType.File, WorkspaceObjectType.CallTranscript, WorkspaceObjectType.Folder];