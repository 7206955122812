import styled from '@emotion/styled';
import type {
  CSSObjectWithLabel,
  StylesConfig as SelectStylesConfig,
  Theme as SelectTheme,
  ThemeConfig as SelectThemeConfig,
} from 'react-select';

export const Row = styled.div({
  display: 'flex',
  boxSizing: 'border-box',

  '&:not(:last-child)': {
    marginBottom: '10px',
  },
});

export const Label = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontSize: '14px',
  marginRight: '12px',
  width: '110px',
});

export const Value = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  flex: '1',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildSelectStyles(theme: Theme): SelectStylesConfig<any> {
  return {
    menu: base => ({
      ...base,
      fontSize: '14px',
      fontFamily: theme.fonts.regular,
      width: 'max-content',
      minWidth: '100%',
      zIndex: 2,
    } as CSSObjectWithLabel),
    option: (base, state) => {
      const value = {
        ...base,
        fontFamily: theme.fonts.regular,
        color: state.isSelected && !state.isFocused
          ? theme.palette.text.primary
          : state.isFocused
            ? theme.palette.wl ? theme.palette.wl.primary.main : theme.palette.hyperlink
            : undefined,
        backgroundColor: state.isFocused
          ? theme.palette.wl ? theme.palette.wl.prompt.light6 : theme.palette.blue.light3
          : theme.palette.white.main,
        '&:active': {
          backgroundColor: state.isFocused
            ? theme.palette.wl ? theme.palette.wl.prompt.light6 : theme.palette.blue.light3
            : theme.palette.white.main,
        },
      } as CSSObjectWithLabel;
      return value;
    },
    container: base => ({
      ...base,
      width: '100%',
    } as CSSObjectWithLabel),
    valueContainer: base => ({
      ...base,
      maxHeight: '400px',
      overflowY: 'auto',
    } as CSSObjectWithLabel),
    singleValue: base => ({
      ...base,
      fontSize: '14px',
      fontFamily: theme.fonts.regular,
    } as CSSObjectWithLabel),
    multiValue: base => ({
      ...base,
      fontFamily: theme.fonts.regular,
    } as CSSObjectWithLabel),
    multiValueLabel: base => ({
      ...base,
      fontSize: '14px',
    } as CSSObjectWithLabel),
    multiValueRemove: (base, state) => ({
      ...base,
      fontSize: '14px',
      display: state.isDisabled ? 'none' : base.display,
    } as CSSObjectWithLabel),
    placeholder: base => ({
      ...base,
      fontSize: '14px',
      fontFamily: theme.fonts.regular,
    } as CSSObjectWithLabel),
  };
}

export function buildSelectTheme(theme: Theme): SelectThemeConfig {
  return (selectTheme: SelectTheme) => {
    const updatedTheme = {
      ...selectTheme,
      colors: {
        ...selectTheme.colors,
        primary: theme.palette.primary.main,
        primary25: theme.palette.primary.light4,
        primary50: theme.palette.primary.light3,
        primary75: theme.palette.primary.light2,
        danger: theme.palette.red.main,
      },
    };
    return updatedTheme;
  };
}