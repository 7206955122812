import { ChatInstanceItemType } from '@/enums/chat';
import type { Chat } from '@/types';
import { KolSearchItem } from './Item.KolSearch';
import { QueryItem } from './Item.Query';
import { KolSearchContainer } from './KolSearch.Containers';

type Props = {
  canRegenerate: boolean;
  item: Chat.InstanceItem;
};

export const ChatItem = ({ canRegenerate, item }: Props) => {
  switch (item.type) {
    case ChatInstanceItemType.Query:
      return (
        <QueryItem
          canRegenerate={canRegenerate}
          item={item} />
      );

    case ChatInstanceItemType.KolSearch:
      return (
        <KolSearchContainer item={item}>
          <KolSearchItem />
        </KolSearchContainer>
      );

    default:
      return null;
    // throw new UnreachableCaseError(item);
  }
};