import { RouterProvider } from 'react-router-dom';
import { withProfiler } from '@sentry/react';
import { createRouter } from '@/router';
import { AppInitializing } from '@/screens/Initializing';
import '@/static/css/global/style.module.css';

const UnprofiledApp = () => {
  const router = createRouter();

  return (
    <RouterProvider
      router={router}
      fallbackElement={<AppInitializing />}
      future={{
        v7_startTransition: true,
      }} />
  );
};

export const App = withProfiler(UnprofiledApp);