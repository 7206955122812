import { memo, useContext } from 'react';
import { KolSearchStatus } from '@/enums/kol';
import {
  KolSearchStatusContext,
  KolSearchFormContext,
  KolSearchErrorTraceIdContext,
  KolSearchResultsContext,
  KolSearchRecommendationsContext,
} from './context';
import { KolSearchFormMessage } from './KolSearch.Form';
import { KolProfileMessage } from './KolSearch.Profile';
import { QuestionsBank } from './KolSearch.QuestionsBank';
import { KolRecommendations } from './KolSearch.Recommendations';
import { KolResultsMessage } from './KolSearch.Results';
import { ErrorTrace } from './Message.ErrorTrace';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { SystemUserPlaceholderMessage } from './Message.System.Placeholder';
import { MesasageWrapper } from './Message.System.Wrapper';
import { UserMessage } from './Message.User';

export const KolSearchItem = () => {
  const searchStatus = useContext(KolSearchStatusContext);
  const results = useContext(KolSearchResultsContext);
  const recommendations = useContext(KolSearchRecommendationsContext);

  const showSearchForm = searchStatus == KolSearchStatus.FormEntry;
  const showUserMessage = ![KolSearchStatus.ProfileRecommendations, KolSearchStatus.FormEntry, KolSearchStatus.QuestionsBank].includes(searchStatus);
  const showSearchResultsLoadingMessage = searchStatus === KolSearchStatus.FormSubmitted;
  const showResultsMessage = [
    KolSearchStatus.MultipleResultsReturned,
    KolSearchStatus.SearchCancelled,
    KolSearchStatus.ProfileSelected,
    KolSearchStatus.ProfileSelectionSubmitted,
  ].includes(searchStatus) && results && results.length > 1;
  const showNoResultsMessage = searchStatus === KolSearchStatus.NoResultsReturned;
  const showProfile = searchStatus === KolSearchStatus.ProfileSelected;
  const showProfileLoadingMessage = searchStatus === KolSearchStatus.ProfileSelectionSubmitted;
  const showSearchErrorMessage = searchStatus === KolSearchStatus.FormSubmissionErrored;
  const showProfileErrorMessage = searchStatus === KolSearchStatus.ProfileSelectionErrored;
  const showRecommendations = recommendations.status !== 'skipped';
  const showQuestionsBank = searchStatus === KolSearchStatus.QuestionsBank;

  return (
    <>
      {showQuestionsBank && <QuestionsBank />}
      {showRecommendations && <KolRecommendations />}
      {showSearchForm && <KolSearchFormMessage />}
      {showUserMessage && <UserSearchMessage />}
      {showSearchResultsLoadingMessage && <SystemUserPlaceholderMessage />}
      {showResultsMessage && <KolResultsMessage />}
      {showNoResultsMessage && <NoResultsMessage />}
      {showProfileLoadingMessage && <SystemUserPlaceholderMessage />}
      {showProfile && <KolProfileMessage />}
      {showSearchErrorMessage && <SearchErrorMessage />}
      {showProfileErrorMessage && <ProfileSelectionErrorMessage />}
    </>
  );
};

const SearchErrorMessage = memo(() => {
  const traceId = useContext(KolSearchErrorTraceIdContext);
  const copy = `I'm sorry, there was an error while searching for HCPs. Please try again.`;
  return (
    <MesasageWrapper>
      <BasicSystemUserMessage>
        {copy}
        {traceId && <ErrorTrace traceId={traceId} />}
      </BasicSystemUserMessage>
    </MesasageWrapper>
  );
});

const ProfileSelectionErrorMessage = memo(() => {
  const traceId = useContext(KolSearchErrorTraceIdContext);
  const copy = `I'm sorry, there was an error while selecting a HCP. Please try again.`;
  return (
    <MesasageWrapper>
      <BasicSystemUserMessage>
        {copy}
        {traceId && <ErrorTrace traceId={traceId} />}
      </BasicSystemUserMessage>
    </MesasageWrapper>
  );
});

const NoResultsMessage = memo(() => {
  const copy = `I'm sorry, I couldn't find any HCPs with the name you provided. Please try again.`;
  return (
    <MesasageWrapper>
      <BasicSystemUserMessage>
        {copy}
      </BasicSystemUserMessage>
    </MesasageWrapper>
  );
});

const UserSearchMessage = memo(() => {
  const formState = useContext(KolSearchFormContext);
  const copy = formState.kolId
    ? `Show me the profile for "${formState.name}".`
    : `Find any matching HCPs with the name "${formState.name}".`;
  return (
    <UserMessage value={copy} />
  );
});