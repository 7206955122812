/** @jsxImportSource @emotion/react */
import { memo, useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { AnalysisQueryHint } from '@/components/presentation/Message.QueryHint';
import { TablePagination } from '@/components/presentation/TablePagination';
import { useTablePagination } from '@/hooks/useTablePagination';
import { messageLaneRightPadding, messageLaneWidth, messageLaneLeftPadding } from './Message.styles';
import { BasicSystemUserMessage } from './Message.System.Basic';

type Props = unknown;

export const QuestionsBank = memo((props: Props) => {

  const questions = getQuestions();

  const dispatch = useContext(ChatStateDispatchContext);

  const pageSize = 10;

  const handleQuestionClick = useCallback((value: string) => {
    dispatch({
      type: 'kol-search/question-bank-question-selected',
      payload: { value },
    });
  }, [dispatch]);

  const { setTotal, ...pagination } = useTablePagination({
    index: 0,
    pageSize,
    total: questions.length,
  });

  const page = useMemo(() => {
    const start = pagination.index * pagination.size;
    const end = start + pagination.size;

    return questions.slice(start, end);
  }, [
    questions,
    pagination.index,
    pagination.size,
  ]);

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={css({
        minWidth: messageLaneWidth,
        maxWidth: messageLaneWidth,
      })}>
        <Container>
          <ItemContainer>
            <CategoryAttributeContainer>
              <Attribute>Topic Categories</Attribute>
            </CategoryAttributeContainer>
            <TopicAttributeContainer>
              <Attribute>Topic</Attribute>
            </TopicAttributeContainer>
            <QuestionsAttributeContainer>
              <Attribute>Questions</Attribute>
            </QuestionsAttributeContainer>
          </ItemContainer>
          {page.map((q, index) => (
            <ItemContainer key={index}>
              <CategoryAttributeContainer>
                <Attribute>{q.category}</Attribute>
              </CategoryAttributeContainer>
              <TopicAttributeContainer>
                <Attribute>{q.topic}</Attribute>
              </TopicAttributeContainer>
              <QuestionsAttributeContainer>
                <Attribute>
                  <AnalysisQueryHint
                    value={q.value}
                    onClick={() => handleQuestionClick(q.value)} />
                </Attribute>
              </QuestionsAttributeContainer>
            </ItemContainer>
          ))}
          {pagination.total > pageSize && (
            <Footer>
              <TablePagination
                canNextPage={pagination.canNextPage}
                canPreviousPage={pagination.canPreviousPage}
                nextPage={pagination.nextPage}
                previousPage={pagination.previousPage}
                pageIndex={pagination.index}
                pageSize={pagination.size}
                totalCount={pagination.total} />
            </Footer>
          )}
        </Container>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
});

function getQuestions(): QuestionItem[] {

  const profileQuestions = [
    `I'd like to learn more about the publications this HCP has appeared in.`,
    `I'd like to learn more about the clinical trials this HCP participated in.`,
    `I'd like to learn more about this HCP's conference presence.`,
    `I'd like to learn more about publications where this HCP was the lead author.`,
    `Please summarize this HCP's experience.`,
    `Are there any specific journals where this HCP frequently publishes?`,
    `Are there any notable collaborations mentioned in this HCP's publications?`,
    `What are some themes I could engage this HCP on?`,
  ];

  return [
    ...profileQuestions.map(value => ({
      topic: 'HCP 360 Profile',
      category: 'HCP Attributes',
      value,
    })),
    {
      topic: 'MSL Observations & Questions',
      category: 'Voice of Customer',
      value: 'What has the HCP previously communicated regarding unmet needs in rheumatology?',
    },
    {
      topic: 'MSL Observations & Questions',
      category: 'Voice of Customer',
      value: 'Give me recent notes from last conversation',
    },
  ];
}

type QuestionItem = {
  category: string;
  topic: string;
  value: string;
};

const MessageContainer = styled.div({
  paddingLeft: messageLaneLeftPadding,
  paddingRight: messageLaneRightPadding,
});

const Container = styled.ol(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,

  [`> ${String(ItemContainer)}:first-of-type`]: {
    backgroundColor: theme.palette.gray.light2,
    fontFamily: theme.fonts.semiBold,
    fontSize: 14,
  },
}));

const ItemContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '150px 150px 1.25fr',
}));

const AttributeContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
}));

const CategoryAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});
const TopicAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});
const QuestionsAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 4,
});