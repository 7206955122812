import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import styles from '@/screens/Workspace.File/style/Header.module.css';
import { FileBreadcrumbs } from '@/screens/Workspace.File/Header.Breadcrumbs';
import { FolderLoadingContext, FolderStateContext } from './Context';
/* import { Actions } from './Header.Actions'; */

export const Header = () => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <FileBreadcrumbs />

          <div className={styles.details}>
            <div className={styles.info}>

              <div className={styles.header}>
                <Title />
              </div>

            </div>

            {/* <Actions /> */}
          </div>

          <div className={styles.subtitle}>

          </div>

        </div>
      </div>
    </div>
  );
};

const Title = (props: unknown) => {
  const isLoading = useContext(FolderLoadingContext);
  const { folder } = useContext(FolderStateContext);

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={folder.name} />
      <h1 className={styles.title}>{folder.name}</h1>
    </>
  );
};

Title.displayName = 'Folder.Header.Title';