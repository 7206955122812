import { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { useToggle } from '@/hooks/useToggle';
import { cx } from '@/utils';
import * as Landscape from './Logo.Landscape';
import * as Square from './Logo.Square';
import styles from './style/Settings.Branding.Logo.Cropper.module.css';

type Props = {
  onClose: () => void;
  open: boolean;
};

export const Cropper = (props: Props) => {
  const { query } = useContext(AppThemingContext);
  const [on, toggle] = useToggle(false);

  const classes = {
    square: cx({ [styles.hidden]: on }),
    landscape: cx({ [styles.hidden]: !on }),
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <h1 className={styles.header}>Upload a logo</h1>
            <ViewSwitch
              on={on}
              toggle={toggle} />
            <div className={classes.square}>
              <Square.Cropper
                imageSrc={query.data?.logo?.square}
                onClose={props.onClose} />
            </div>
            <div className={classes.landscape}>
              <Landscape.Cropper
                imageSrc={query.data?.logo?.horizontal}
                onClose={props.onClose} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

Cropper.displayName = 'Settings.Branding.Logo.Cropper';

type ViewSwitchProps = {
  on: boolean;
  toggle: () => void;
};

const ViewSwitch = ({ on, toggle }: ViewSwitchProps) => {

  const thumbclasses = cx({
    [styles.on]: on,
  }, styles.thumb);

  const classes = {
    off: cx(styles.control, { [styles.active]: !on }),
    on: cx(styles.control, { [styles.active]: on }),
  };

  return (
    <div className={styles.switch}>
      <div className={styles.btns}>
        <button
          onClick={toggle}>
          <div className={styles.track}>
            <div className={classes.off}>
              Square
            </div>
            <div className={classes.on}>
              Horizontal
            </div>
          </div>
        </button>
        <div className={thumbclasses} />
      </div>
    </div>
  );
};