/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChevronLeft } from 'react-feather';
import { Constants } from '@/components/Theme';
import { shouldForwardProp } from '@/utils/emotion';

type Props = {
  className?: string;
  onClick: () => void;
  open: boolean;
  size?: number;
};

export const SidebarArrowToggler = memo(({ className, onClick, open }: Props) => {
  const anim = open
    ? css`transform: rotate(0);`
    : css`transform: rotate(180deg);`;

  return (
    <Root
      className={className}
      onClick={onClick}
      open={open}>
      <Chevron
        css={anim}
        size={34}
        strokeWidth={4}
        open={open} />
    </Root>
  );
});

type RootProps = {
  open: boolean;
};

const basePadding = 4;

const Chevron = styled(ChevronLeft, { shouldForwardProp: shouldForwardProp('open') }) <RootProps>`
color: ${({ theme }) => theme.palette.gray.dark1};
  transition: transform 0.25s linear, stroke 0.3s;
`;

const Root = styled('div', { shouldForwardProp: shouldForwardProp('open') }) <RootProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${Constants.Sidebar.ArrowTogglerSize}px;
  height: ${Constants.Sidebar.ArrowTogglerSize}px;
  box-sizing: border-box;

  padding: ${basePadding}px;
  padding-left: ${props => props.open ? basePadding : 6}px;
  padding-right: ${props => props.open ? 6 : basePadding}px;
  border-radius: 50%;
  cursor: pointer;

  background-color: ${props => props.theme.palette.secondary.main};
  box-shadow: 1px 0px 5px 3px rgba(0, 0, 0, 0.1);

  transition: stroke 0.3s;

 
`;