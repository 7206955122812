/* eslint-disable no-process-env */
import { BaseConfig } from './base';

export function createConfig(): BaseConfig {
  const config = BaseConfig.safeParse({
    ...process.env, // note: these should be set by webpack via config/env.js
  });

  if (!config.success) {
    throw new Error(`Couldn't create app config!\n${JSON.stringify(config.error, null, 2)}`);
  }

  return config.data;
}

let configInstance: BaseConfig;
export function config(): BaseConfig {
  if (configInstance) return configInstance;

  configInstance = createConfig();

  return configInstance;
}