import { useState } from 'react';
import styled from '@emotion/styled';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';

export const KolBadgeTooltip = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  const copy = `Based off of this HCP's thought leadership activities, this HCP has been flagged as a Key Opinion Leader (KOL)`;

  return (
    <>
      <Anchor ref={refs.setReference} {...getReferenceProps()}>
        <Badge>KOL</Badge>
      </Anchor>
      {isOpen && (
        <PopperMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {copy}
        </PopperMenu>
      )}
    </>
  );
};


const Badge = styled.span(({ theme }) => {
  const { Text } = Presentation.Colors(theme);
  return {
    fontSize: 12,
    fontFamily: theme.fonts.semiBold,
    padding: '1px 4px',
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: Text.enabled.backgroundColor,
  };
});

const Anchor = styled.div({
  display: 'inline-flex',
});

const PopperMenu = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 15,
  borderRadius: 4,
  boxShadow: `0 2px 6px 2px rgba(60, 64, 67, .15)`,
  zIndex: 4,
  width: 400,

  textAlign: 'left',
}));