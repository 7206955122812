import { useContext } from 'react';
import type * as App from '@/api/interfaces/app';
import { ColorInput } from '@/components/presentation/ColorInput';
import { OptimisticIndicator } from '@/components/presentation/OptimisticIndicator';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { useUpdateColorMutation } from './hooks';
import styles from './style/Theme.Color.module.css';

type Props = {
  PreviewElement?: React.ElementType<{ value: string }>;
  initialValue?: string;
  label: string;
  name: keyof App.UpdateThemeColor.Request;
};

export const Color = (props: Props) => {
  const { query, theme } = useContext(AppThemingContext);
  const mutation = useUpdateColorMutation();

  return (
    <div className={styles.row}>
      <div className={styles.label}>
        <div className={styles.text}>{props.label}</div>
        <OptimisticIndicator
          classes={{ root: styles.indicator }}
          isError={mutation.isError}
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess} />
      </div>
      <div className={styles.main}>
        <ColorInput
          className={styles.preview}
          PreviewElement={props.PreviewElement}
          initialValue={props.initialValue}
          onChangeValidHex={value => mutation.mutate({
            ...query.data.color,
            [props.name]: value,
          })} />
      </div>
    </div>
  );
};