import { FilePreview } from '@/components/Workspace.File.Preview';
/* import Sidebar from './Sidebar'; */
import styles from './style/Tab.module.css';

const StateDependent = () => {

  return (
    <div className={styles.content}>
      <div className={styles.col}>
        <FilePreview />
      </div>
      <div className={styles.col}>
        {/* <Sidebar forehead={false} /> */}
      </div>
    </div>
  );
};

export const FileTabPreview = () => {
  return (
    <StateDependent />
  );
};