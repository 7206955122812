/* eslint-disable no-process-env */
import { config } from './index';

export function isDevEnv() {
  try {
    return config().APP_ENVIRONMENT === 'development';
  } catch {
    return !process.env.APP_ENVIRONMENT || process.env.APP_ENVIRONMENT === 'development';
  }
}

export function isSandboxEnv() {
  try {
    return config().APP_ENVIRONMENT === 'sandbox';
  } catch {
    return process.env.APP_ENVIRONMENT === 'sandbox';
  }
}

export function isProdEnv() {
  try {
    return config().APP_ENVIRONMENT === 'production';
  } catch {
    return process.env.APP_ENVIRONMENT === 'production';
  }
}

export function isLowerEnv() {
  return isDevEnv() || isSandboxEnv();
}