import { memo, useState } from 'react';
import styled from '@emotion/styled';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import { BasicMarkdown } from '@/components/presentation/Markdown';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';
import type { RecommendationReason } from '@/types/kol';

type Props = {
  values: RecommendationReason[];
};

export const KolRecommendationTooltip = ({ values }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  const copy = `Recommended because patients have the following unmet medical needs:`;

  return (
    <>
      <Anchor ref={refs.setReference} {...getReferenceProps()}>
        <MunBadge />
      </Anchor>
      {isOpen && (
        <PopperMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <Title>{copy}</Title>
          <Items>{values.map((v, i) => <KolRecommendationReason key={i} value={v} />)}</Items>
        </PopperMenu>
      )}
    </>
  );
};

type SingleProps = {
  value: RecommendationReason;
};

export const KolRecommendationReason = memo(({ value }: SingleProps) => {
  return (
    <div>
      <Label>{value.reason}</Label>
      <StyledList>
        {value.items.map((item, index) => (
          <StyledListItem key={index}>
            <BasicMarkdown>{item}</BasicMarkdown>
          </StyledListItem>
        ))}
      </StyledList>
    </div>
  );
});

const MunBadge = memo(() => (
  <Badge>MUN</Badge>
));

const Badge = styled.span(({ theme }) => {
  const { Text } = Presentation.Colors(theme);
  return {
    fontSize: 12,
    fontFamily: theme.fonts.semiBold,
    padding: '1px 4px',
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: Text.enabled.backgroundColor,
  };
});

const Items = styled.div({
  marginBottom: 15,

  '&:last-child': {
    marginBottom: 0,
  },
});

const Anchor = styled.div({
  display: 'inline-flex',
});

const PopperMenu = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 15,
  borderRadius: 4,
  boxShadow: `0 2px 6px 2px rgba(60, 64, 67, .15)`,
  zIndex: 4,
  width: 400,

  textAlign: 'left',
}));

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  marginBottom: 10,
}));

const Title = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  marginBottom: 10,
}));

const StyledList = styled.ul({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 30,
});

const StyledListItem = styled.li({
  marginBottom: 5,
});