import { memo } from 'react';
import styled from '@emotion/styled';
import { ExternalLink } from 'react-feather';
import { Tooltip } from '@/components/presentation/Tooltip';
import { buildGoogleMapsAddressLink, trunc } from '@/utils';

type Props = {
  city: string;
  state: string;
  zip: string;
  country: string;
  street: string;
  maxAddressLength?: number;
};

export const KolAddress = memo((props: Props) => {

  const address = [props.street, props.city, props.state, props.zip, props.country].filter(Boolean).join(', ');
  const displayAddress = props.maxAddressLength
    ? trunc(address, props.maxAddressLength)
    : address;

  const includeLink = props.street && props.city && props.state && props.zip && props.country;
  const hasNoAddress = !props.street && !props.city && !props.state && !props.zip && !props.country;

  if (hasNoAddress) {
    return (
      <PlaceholderText>
        None
      </PlaceholderText>
    );
  }

  if (!includeLink) {
    return (
      <Tooltip
        title={address}
        enterDelay={1000}
        enterNextDelay={500}>
        <div>
          {displayAddress}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={address}
      enterDelay={1000}
      enterNextDelay={500}>
      <StyledAddressLink
        href={buildGoogleMapsAddressLink({ address })}
        target="_blank"
        rel="noreferrer">
        <AddressLinkText>{displayAddress}</AddressLinkText>
        <StyledLinkIcon size={14} />
      </StyledAddressLink>
    </Tooltip>
  );
});

const AddressLinkText = styled.div({
  height: '100%',
});

const StyledAddressLink = styled.a(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  overflow: 'hidden',
  height: '100%',

  ':hover': {
    color: theme.palette.hyperlink,
  },

  [`> ${String(StyledLinkIcon)}`]: {
    opacity: 0,
  },

  [`:hover > ${String(StyledLinkIcon)}`]: {
    opacity: 1,
  },
}));

const StyledLinkIcon = styled(ExternalLink)(({ theme }) => ({
  color: theme.palette.hyperlink,
  transition: 'all 150ms',
  flexShrink: 0,
  marginLeft: 4,
}));

const PlaceholderText = styled.div(({ theme }) => ({
  color: theme.palette.gray.main,
}));