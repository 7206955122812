import { getLocalStorage } from '@/lib/storage';
import type { LastLoginStrategy } from './interfaces';

export const LocalStorageKeys = Object.freeze({
  LastLoginStrategy: 'msl-copilot:last-login-strategy',
});

export function setLastLoginStrategy(data: LastLoginStrategy.Set.Params) {
  getLocalStorage().setItem(LocalStorageKeys.LastLoginStrategy, JSON.stringify(data));
}

export function getLastLoginStrategy() {
  const $storage = getLocalStorage();

  try {
    const value = $storage.getItem(LocalStorageKeys.LastLoginStrategy);
    if (!value) return null;
    const strategy = JSON.parse(value) as LastLoginStrategy.LocalStorage;
    return strategy;
  } catch {
    $storage.removeItem(LocalStorageKeys.LastLoginStrategy);
    return null;
  }
}