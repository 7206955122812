import { memo, useMemo } from 'react';
import { File as GenericFileIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { FileIcon } from '@/components/icons/File';
/* import useProjectState from '@/containers/GroupProject/hooks/useProjectState'; */
import { cx, getLocationFor } from '@/utils';
import type { IWorkspaceFileItem } from '@/types/workspace.table';
import { formatCreatedOnDate } from './Files.Table.Grid.utils';
import styles from './style/Files.Table.Grid.module.css';

type Props = {
  item: IWorkspaceFileItem;
};

export const File = ({ item }: Props) => {
  const to = getLocationFor.workspaces.file({
    fileId: item.file.id,
  });

  const createdOn = useMemo(() => {
    return formatCreatedOnDate(item.object.createdOn);
  }, [item.object.createdOn]);

  return (
    <Link
      className={cx(styles.link, styles.file)}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericFileThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <FileIcon
            className={styles.icon}
            extension={item.file.extension} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.file.name}</div>
          <div className={styles.lastModified}>{createdOn}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericFileThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <GenericFileIcon className={styles.icon} />
    </div>
  );
});