import { useCallback, useEffect, useMemo } from 'react';
import type { Socket } from 'socket.io-client';
import type { ChatSocketEvent, SocketNamespace } from '@/enums/websocket';
import { LifeCycleSocketEvent } from '@/enums/websocket';
import { createLogger } from '../utils';

export const useSetupDefaultSocketListeners = (socket: Socket, namespace: SocketNamespace) => {

  const logger = useMemo(() => createLogger(namespace), [namespace]);

  const logEvents = useCallback((event: ChatSocketEvent, payload: unknown) => logger.event(event, payload), []);
  const handleConnect = useCallback(() => logger.event(LifeCycleSocketEvent.Connect), []);
  const handleConnectError = useCallback((err: Error) => logger.event(LifeCycleSocketEvent.ConnectError, err), []);
  const handleDisconnect = useCallback(() => logger.event(LifeCycleSocketEvent.Disconnect), []);

  useEffect(() => {
    if (!socket) return;

    socket.on(LifeCycleSocketEvent.Connect, handleConnect);
    socket.on(LifeCycleSocketEvent.ConnectError, handleConnectError);
    socket.on(LifeCycleSocketEvent.Disconnect, handleDisconnect);

    socket.prependAny(logEvents);

    return () => {
      socket.off(LifeCycleSocketEvent.Connect, handleConnect);
      socket.off(LifeCycleSocketEvent.ConnectError, handleConnectError);
      socket.off(LifeCycleSocketEvent.Disconnect, handleDisconnect);

      socket.offAny(logEvents);
    };
  }, [
    handleConnect,
    handleConnectError,
    handleDisconnect,
    logEvents,
    socket,
  ]);
};