import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
// import { ChatContextType } from '@/enums/chat';
import { ChatSource } from '@/enums/chat.filters';
import { ActiveChatSessionContext, ChatSessionFiltersContext } from '@/components/Chat/context';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { generateEmptyChatSessionFiltersState } from '@/components/Chat.State/state.initial-state';
import { Source } from './Source';
import { PublishedDate } from './PublishedDate';
// import { Tag } from './Tag';

type Props = {
  active: boolean;
};

export function FilterPaneWorkspace(props: Props) {
  return (
    <Root>
      <Header>
        <HeaderLabel>Source Filters</HeaderLabel>
        <ResetController {...props} />
      </Header>
      <Filters>
        <PublishedDateController />
        {/* <TagController /> */}
        <SourceController />
      </Filters>
    </Root>
  );
}

function SourceController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const options = useMemo(() => [{ id: ChatSource.Text, name: 'Text' }], []);

  if (!options.length) return null;

  return (
    <Source
      options={options}
      value={filters.sources}
      onChange={sources => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              sources: sources.length ? sources : options,
            },
          },
        });
      }} />
  );
}

function PublishedDateController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const defaultValue = useMemo(() => generateEmptyChatSessionFiltersState({ context }).minDocumentDate, [context]);

  return (
    <PublishedDate
      defaultValue={defaultValue}
      value={filters.minDocumentDate}
      onChange={minDocumentDate => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              minDocumentDate,
            },
          },
        });
      }} />
  );
}
/*
function TagController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  return (
    <Tag
      context={context}
      value={filters.tags}
      onChange={tags => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              tags,
            },
          },
        });
      }} />
  );
}
*/
function ResetController({ active }: Props) {
  const dispatch = useContext(ChatStateDispatchContext);

  return (
    <HeaderReset
      disabled={!active}
      onClick={() => dispatch({ type: 'filters/reset' })}>
      Reset
    </HeaderReset>
  );
}

const Root = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '5px',
  border: `1px solid ${theme.palette.gray.light1}`,
  boxShadow: '0 2px 6px 2px rgba(60, 64, 67, .15)',
  boxSizing: 'border-box',
  padding: '12px',
  minWidth: '200px',
  maxWidth: '600px',
}));

const Header = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const HeaderLabel = styled.h1(({ theme }) => ({
  fontSize: '18px',
  fontFamily: theme.fonts.semiBold,
}));

const HeaderReset = styled.button(({ disabled, theme }) => ({
  color: !disabled ? theme.palette.text.primary : theme.palette.gray.light1,
  fontSize: '14px',
  transition: 'text-decoration 150ms, color 150ms',

  ':hover': {
    textDecoration: disabled ? 'underline' : 'none',
  },
}));

const Filters = styled.div({
  boxSizing: 'border-box',
  padding: '0 2px',
});