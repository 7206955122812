import { memo, useCallback, useMemo } from 'react';
import type { GroupBase, SelectComponentsConfig, Props as SelectProps, OptionProps } from 'react-select';
import type { ActionMeta, MultiValue } from 'react-select';
import { components } from 'react-select';
import Select from 'react-select';
import { useTheme } from '@/components/Theme';
import type { CustomFilter } from '@/types/chat.filters';
import { buildSelectStyles, buildSelectTheme } from './Presentation';

type Props = {
  components?: SelectComponentsConfig<Item, true, GroupBase<Item>>;
  inputValue?: string;
  loadingMessage?: SelectProps['loadingMessage'];
  isLoading?: boolean;
  isSearchable?: boolean;
  onInputChange?: SelectProps['onInputChange'];
  onSelect: (value: Item[]) => void;
  options: Item[];
  placeholder: string;
  value: Item[];
};
type Item = CustomFilter.SingleSelectFilter;

const Option = memo((props: OptionProps<Item>) => {

  if (props.data.parent2) {
    return (
      <components.Option {...props}>
        <div><strong>{props.data.name}</strong></div>
        <div style={{ fontSize: 13 }}>{props.data.parent1} ({props.data.parent2})</div>
      </components.Option>
    );
  }

  if (props.data.parent1) {
    return (
      <components.Option {...props}>
        <div><strong>{props.data.name}</strong></div>
        <div style={{ fontSize: 13 }}>{props.data.parent1}</div>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <div>{props.data.name}</div>
    </components.Option>
  );
});

export function CustomFilterMultiselect({
  components,
  loadingMessage,
  inputValue,
  isLoading,
  isSearchable,
  onInputChange,
  onSelect,
  options,
  placeholder,
  value,
}: Props) {

  const theme = useTheme();

  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const handleChange = useCallback((value: MultiValue<Item>, meta: ActionMeta<Item>) => {
    onSelect(value as Item[]);
  }, [onSelect]);

  const reslvedComponents = useMemo(() => {
    return {
      Option,
      ...components,
    };
  }, [components]);

  return (
    <Select<Item, true>
      components={reslvedComponents}
      styles={selectStyles}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isSearchable={isSearchable}
      theme={selectTheme}
      isLoading={isLoading}
      loadingMessage={loadingMessage}
      isMulti={true}
      value={value}
      options={options}
      placeholder={placeholder}
      getOptionLabel={o => o.name}
      getOptionValue={o => `${o.id}`}
      onChange={handleChange}
      menuPlacement="auto" />
  );
}