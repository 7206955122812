import EventEmitter from 'events';
import { z } from 'zod';
import { MyInsightsMock, type MyInsights } from '@/lib/myinsights';
import { getLocalStorage } from '@/lib/storage';
import type { AuthSource, AuthProviderEvent, AuthenticateJWT } from './auth-provider.base';
import { BaseAuthProvider } from './auth-provider.base';

export class VeevaAuthProvider extends BaseAuthProvider {
  static #tokenKey = 'msl-copilot:veeva:token';

  #eventEmitter: EventEmitter;
  #myInsights: MyInsights;

  #isAuthenticated: boolean;
  #expiry: Date;

  constructor() {
    super();
    this.#isAuthenticated = false;
    this.#eventEmitter = new EventEmitter();
    this.#myInsights = new MyInsightsMock();
  }

  get isAuthenticated(): boolean {
    return this.#isAuthenticated;
  }

  get expiry(): Date {
    return this.#expiry;
  }

  async authenticate(data: AuthenticateJWT) {
    try {
      const previousIsAuthenticated = this.isAuthenticated;

      const token = await this.#myInsights.getSSOToken();
      const parsedToken = this.#decodeToken(token);
      this.#storeToken({ token });
      this.#expiry = parsedToken.exp;
      this.#isAuthenticated = true;

      if (!previousIsAuthenticated) {
        this.#emit({ name: 'auth/login' });
      }
    } catch (e) {
      console.error(e);
      this.logout();
    }
  }

  async error(error: Error) {
    this.#emit({ name: 'auth/error', error });
  }

  #decodeToken(token: string) {
    const [_, body] = token.split('.');

    const parsed = ParsedJWT.safeParse(JSON.parse(atob(body)));
    if (!parsed.success) throw new Error(`Could not parse decoded token: ${JSON.stringify(parsed.error, null, 2)}`);
    return parsed.data;
  }

  get authSource(): AuthSource {
    return 'jwt';
  }

  async refreshToken() {
    return;
  }

  get isRefreshingToken() {
    return false;
  }

  get accessToken() {
    const data = this.#getToken();
    if (!data) return null;
    return data.token;
  }

  logout() {
    const previousIsAuthenticated = this.isAuthenticated;

    this.#isAuthenticated = false;
    this.#expiry = null;
    this.#destroy();

    if (previousIsAuthenticated) {
      this.#emit({ name: 'auth/logout' });
    }
  }

  #emit(e: AuthProviderEvent) {
    return this.#eventEmitter.emit('auth', e);
  }

  addListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.addListener('auth', listener);

  }
  removeListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.removeListener('auth', listener);
  }

  #storeToken(token: TokenData) {
    const $storage = getLocalStorage();
    $storage.setItem(VeevaAuthProvider.#tokenKey, JSON.stringify(TokenData.parse(token)));
  }

  #getToken() {
    const $storage = getLocalStorage();
    try {
      return TokenData.parse(JSON.parse($storage.getItem(VeevaAuthProvider.#tokenKey)));
    } catch {
      $storage.removeItem(VeevaAuthProvider.#tokenKey);
      return null;
    }
  }

  #destroy() {
    const $storage = getLocalStorage();
    $storage.removeItem(VeevaAuthProvider.#tokenKey);
  }
}

const TokenData = z.object({
  token: z.string(),
});
type TokenData = z.infer<typeof TokenData>;

const ParsedJWT = z.object({
  email: z.string(),
  exp: z.coerce.number().transform(u => new Date(u * 1000)),
});