import * as Sentry from '@sentry/react';
import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { getAuthProvider } from '@/auth/auth-provider';
import { config as appConfig } from '@/config';

export * from './interceptors.logging';

export const sessionValidationInterceptor = (config: InternalAxiosRequestConfig) => {
  if (!getAuthProvider().isAuthenticated) {
    console.log('Aborting request: Session is expired or absent.');
    getAuthProvider().error(new Error('Session is expired or absent.'));
    return {
      ...config,
      cancelToken: new axios.CancelToken(c => c('Aborting request: Session is expired or absent.')),
    };
  }
  return config;
};

export const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const $provider = getAuthProvider();
  switch ($provider.authSource) {
    case 'cookie': {
      const url = new URL(config.baseURL);
      const BRAND_INSIGHTS_BASE_URL = new URL(appConfig().BRAND_INSIGHTS_API_URL);
      if (url.hostname === BRAND_INSIGHTS_BASE_URL.hostname) {
        config.withCredentials = true;
      }
      return config;
    }
    case 'jwt': {
      const url = new URL(config.baseURL);
      const BRAND_INSIGHTS_BASE_URL = new URL(appConfig().BRAND_INSIGHTS_API_URL);
      if (url.hostname === BRAND_INSIGHTS_BASE_URL.hostname) {
        if ($provider.isAuthenticated) {
          config.headers.set('Authorization', `Bearer ${$provider.accessToken}`);
        }
      }
      return config;
    }
    default: return config;
  }
};

export const sentryTraceRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const activeSpan = Sentry.getActiveSpan();

  if (activeSpan) {
    const rootSpan = Sentry.getRootSpan(activeSpan);
    const sentryTraceHeader = Sentry.spanToTraceHeader(rootSpan);
    const sentryBaggageHeader = Sentry.spanToBaggageHeader(rootSpan);
    config.headers['sentry-trace'] = sentryTraceHeader;
    config.headers['baggage'] = sentryBaggageHeader;
  } else {
    const spanOptions = {
      op: 'axios.request',
      name: `${config.method.toUpperCase()} ${config.url}`,
    };
    Sentry.startSpan(spanOptions, () => {
      if (config.data) {
        Sentry.getCurrentScope().addAttachment({
          filename: 'request-data.json',
          data: JSON.stringify(config.data),
          contentType: 'application/json',
        });
      }
      const activeSpan = Sentry.getActiveSpan();
      const rootSpan = Sentry.getRootSpan(activeSpan);
      config.headers['sentry-trace'] = Sentry.spanToTraceHeader(rootSpan);
      config.headers['baggage'] = Sentry.spanToBaggageHeader(rootSpan);
    });
  }

  return config;
};