import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthProvider } from '@/auth/auth-provider';
import type { AuthProviderEvent } from '@/auth/auth-provider.base';
import { AppInitializing } from '@/screens/Initializing';
import { actions } from '@/store/actions';

export function VeevaLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAuthEvent = useCallback((e: AuthProviderEvent) => {
    if (e.name === 'auth/login') {
      dispatch(actions.authChange());
      navigate('/');
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const $provider = getAuthProvider();
    $provider.addListener(handleAuthEvent);
    $provider.authenticate({
      authSource: 'jwt',
    });
    return () => {
      $provider.removeListener(handleAuthEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppInitializing />;
}