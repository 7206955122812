import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import {
  WorkspaceFileStateContext,
  WorkspaceFilePreviewContext,
  WorkspaceFilePreviewReloadContext,
  WorkspaceFilePreviewLoadingContext,
} from './Context';

type Props =
  ChildrenProps;

type RouteParams = {
  fileId: string;
};

const usePageParams = () => {
  const params = useParams<RouteParams>();
  return useMemo(() => ({
    fileId: +params.fileId,
  }), [params.fileId]);
};

export const WorkspaceFilePreviewContainer = (props: Props) => {
  const params = usePageParams();

  const query = useQuery({
    queryKey: [
      `get:workspaces/files`,
      params.fileId,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFile({
        fileId: params.fileId,
      });
    },
  });

  const state = {
    file: query.data?.file,
    object: query.data?.object,
  };

  return (
    <WorkspaceFilePreviewReloadContext.Provider value={query.refetch}>
      <WorkspaceFileStateContext.Provider value={state}>
        <WorkspaceFilePreviewContext.Provider value={query.data?.preview}>
          <WorkspaceFilePreviewLoadingContext.Provider value={query.isInitialLoading}>
            {/* <GroupFileTagsContainer fileId={params.fileId}> */}
            <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs || []}>
              {/* <FileSummaryContainer fileId={params.fileId}> */}
              {props.children}
              {/* </FileSummaryContainer> */}
            </WorkspaceBreadcrumbsContext.Provider>
            {/* </GroupFileTagsContainer> */}
          </WorkspaceFilePreviewLoadingContext.Provider>
        </WorkspaceFilePreviewContext.Provider>
      </WorkspaceFileStateContext.Provider>
    </WorkspaceFilePreviewReloadContext.Provider>
  );
};