import type {
  // Workbook,
  WorkspaceFile,
  WorkspaceFilePreviewData,
  WorkspaceFileSummaryData,
  WorkspaceObject,
} from '@/types';
import { createNamedContext } from '@/utils';

type FileStateContextValue = {
  // capabilities: FileCapabilities;
  // features: {
  //   insightsChat: boolean;
  // };
  file: WorkspaceFile;
  // meta: {
  //   accessCount: number;
  // };
  object: WorkspaceObject;
};

type FetchPreview = () => Promise<unknown>;

export const WorkspaceFilePreviewReloadContext = createNamedContext<FetchPreview>(undefined, 'Workspace.File.Preview.Reload.Context');
export const WorkspaceFilePreviewLoadingContext = createNamedContext<boolean>(false, 'Workspace.File.Preview.Loading.Context');
export const WorkspaceFilePreviewContext = createNamedContext<WorkspaceFilePreviewData>(undefined, 'Workspace.File.Preview.Data.Context');

export const WorkspaceFileStateContext = createNamedContext<FileStateContextValue>(undefined, 'Workspace.File.State.Context');

export const WorkbookDataContext = createNamedContext<Record<string, unknown>>(undefined, 'Workbook.Data.Context');
export const WorkbookDataLoadingContext = createNamedContext<boolean>(undefined, 'Workbook.Data.Loading.Context');
