import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PrimaryButton } from '@/components/presentation/Button.Primary';
import { SecondaryButton } from '@/components/presentation/Button.Secondary';
import type { ChatCustomFilter } from '@/enums/chat.filters';
import { ChatFilterOperator } from '@/enums/chat.filters';
import type { CustomFilter } from '@/types/chat.filters';
import * as $cf from './custom-filters';
import { CustomFilterOperatorSelect } from './CustomFilter.OperatorSelect';
import { CustomFilterTypeSelect } from './CustomFilter.TypeSelect';
import { CustomFilterValue } from './CustomFilter.ValueSelect';
import * as Presentation from './Presentation';

type Props = {
  className?: string;
  onCancel: () => void;
  onSubmit: (filter: CustomFilter.Item) => void;
  initialValue?: CustomFilter.Item;
};

export function CustomFilterForm({
  className,
  onCancel, onSubmit,
  initialValue = {
    operator: ChatFilterOperator.Eq,
    type: null,
    value: null,
  },
}: Props) {

  const isNewFilter = useMemo(() => {
    return initialValue.type === null;
  }, [initialValue]);

  const [filter, setFilter] = useState<CustomFilter.Item>(initialValue);

  const handleTypeSelect = useCallback((type: CustomFilter.Item['type']) => {
    setFilter($cf.getDefaultCustomFilterValue(type));
  }, []);

  const handleValueSelect = useCallback((value: CustomFilter.Item['value']) => {
    // @ts-ignore
    setFilter(s => ({
      operator: s.operator,
      type: s.type,
      value,
    }));
  }, []);

  const handleOperatorSelect = useCallback((operator: ChatFilterOperator) => {
    // @ts-ignore
    setFilter(s => ({
      operator,
      type: s.type,
      value: s.value,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(filter);
  }, [filter, onSubmit]);

  const canSubmit = useMemo(() => {
    return !!filter.type && !!filter.operator
      && $cf.validateCustomFilterValue(filter);
  }, [filter]);

  return (
    <Root className={className}>
      <StyledPresentationRow>
        <StyledLabel>{isNewFilter ? 'New Filter' : 'Edit Filter'}</StyledLabel>
        <Presentation.Value>
          <FilterValues>
            <CustomFilterTypeSelect
              value={filter.type}
              onSelect={handleTypeSelect} />
            {filter.type && (
              <CustomFilterOperatorSelect
                options={$cf.getCustomFilterOperators(filter.type)}
                value={filter.operator}
                onSelect={handleOperatorSelect} />
            )}
            {filter.type && filter.operator && (
              <CustomFilterValue
                onSelect={handleValueSelect}
                value={filter} />
            )}
          </FilterValues>
        </Presentation.Value>
      </StyledPresentationRow>
      <Footer>
        <StyledSecondaryButton onClick={onCancel}>Cancel</StyledSecondaryButton>
        <StyledPrimaryButton
          disabled={!canSubmit}
          onClick={handleSubmit}>
          Save
        </StyledPrimaryButton>
      </Footer>
    </Root>
  );
}

const Root = styled.div({
  padding: 12,
});

const buttonSize = 80;

const StyledPresentationRow = styled(Presentation.Row)({
  alignItems: 'flex-start',
});

const StyledLabel = styled(Presentation.Label)(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 15,
  color: theme.palette.gray.dark1,
}));

const FilterValues = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  flex: 1,
});

const StyledSecondaryButton = styled(SecondaryButton)({
  width: buttonSize,
  minWidth: buttonSize,
  marginRight: 8,
  fontSize: 14,
  padding: 6,
});
const StyledPrimaryButton = styled(PrimaryButton)({
  width: buttonSize,
  minWidth: buttonSize,
  fontSize: 14,
  padding: 6,
});

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 10,
}));