import { useCallback } from 'react';
import { memo } from 'react';
import styled from '@emotion/styled';
import { Upload } from 'react-feather';
import { ActivityIndicator } from '@/components/presentation/ActivityIndicator';

type Props = {
  className?: string;
  isLoading: boolean;
  onSelect: (files: File[]) => void;
};

export const UploadFilesButton = memo(({ className, isLoading, onSelect }: Props) => {

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      onSelect(Array.from(files));
    }
  }, [onSelect]);

  const acceptedFileTypes = [
    'pdf',
    'docx',
    'ppt',
    'pptx',
  ].map(type => `.${type}`).join(',');

  return (
    <Root className={className}>
      <StyledLabel>
        <StyledInput
          disabled={false}
          type="file"
          accept={acceptedFileTypes}
          multiple={true}
          onChange={handleChange} />

        <Button>
          {isLoading && <ActivityIndicator />}
          {!isLoading && (
            <Row>
              <StyledIcon size={18} />
              <UploadText>Upload</UploadText>
            </Row>
          )}
        </Button>
      </StyledLabel>
    </Root>
  );
});

const DisabledStyle = {
  backgroundColor: '#d3d3d3',
  cursor: 'not-allowed',
  borderRadius: 18,
  borderColor: '#d3d3d3',
  color: `var(--gray-d)`,
};

const Root = styled('div')({
  position: 'relative',
});

export const uploadButtonHeight = 36;

const Button = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  height: uploadButtonHeight,
  cursor: 'pointer',
  borderRadius: 18,
  padding: 8,
  backgroundColor: theme.palette.btnPrimary.bg,
  border: `1px solid ${theme.palette.btnPrimary.border}`,

  ':hover': {
    backgroundColor: theme.palette.btnPrimary.active.bg,
  },
}));

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledLabel = styled('label')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'block',
  height: 35,
  cursor: 'pointer',
  ':disabled': {
    backgroundColor: theme.palette.gray.light1,
    cursor: 'not-allowed',
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  display: 'none',
  ':disabled': {
    backgroundColor: theme.palette.gray.light1,
    cursor: 'not-allowed',
  },
}));

const StyledIcon = styled(Upload)(({ theme }) => ({
  color: theme.palette.btnPrimary.color,
  marginRight: 6,
}));

const UploadText = styled('span')(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 13,
  color: theme.palette.btnPrimary.color,
}));