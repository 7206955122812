import type { HeaderGroup as HeaderGroupV7 } from 'react-table';
import type { ColumnDef, HeaderGroup as HeaderGroupV8 } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { cx } from '@/utils';
import styles from './style/Table.module.css';
import v8Styles from './style/V8Table.module.css';

type Props<T extends ObjectLike> = {
  classes?: {
    thead?: string;
    th?: string;
    tr?: string;
  };
  headerGroups?: HeaderGroupV7<T>[];
  getHeaderGroups?: () => HeaderGroupV8<T>[];
  getHeaderCellStyles?: (columnDef: ColumnDef<T>) => string;
};

export const Header = <T extends ObjectLike>({ classes = {}, ...props }: Props<T>) => {
  /*
  if (typeof props.getHeaderGroups === 'function') {
    return (
      <Head className={classes.thead}>
        {props.getHeaderGroups().map(headerGroup =>
          <Row
            className={cx(classes.tr, v8Styles.tr)}
            key={headerGroup.id}>
            {headerGroup.headers.map(header =>
              <Cell
                className={cx(classes.th, props.getHeaderCellStyles?.(header.column.columnDef))}
                key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Cell>)}
          </Row>
        )}
      </Head>
    );
  }
  */
  return (
    <Head className={classes.thead}>
      {props.headerGroups.map((headerGroup, i) => (
        <Row
          className={classes.tr}
          key={i}
          style={headerGroup?.getHeaderGroupProps?.()?.style}>

          {headerGroup.headers.map((column, ii) =>
            <Cell
              className={classes.th}
              key={column.id}
              style={column?.getHeaderProps?.()?.style}>
              {column.Filter ? column.render('Filter') : column.render('Header')}
            </Cell>)}

        </Row>))}
    </Head>
  );
};

Header.displayName = 'Table.Header';

type HeadProps = {
  children: React.ReactNode;
  className?: string;
};

const Head = (props: HeadProps) => {
  return (
    <div
      className={cx(styles.head, props.className)}
      role="rowgroup">
      {props.children}
    </div>
  );
};

Head.displayName = 'Table.Header.Head';

type RowProps<T extends ObjectLike> = {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Row = <T extends ObjectLike>({ style = {}, ...props }: RowProps<T>) => {
  return (
    <div
      className={cx(styles.tr, props.className)}
      role="row"
      style={style}>
      {props.children}
    </div>
  );
};

Row.displayName = 'Table.Header.Row';

type CellProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const Cell = ({ style = {}, ...props }: CellProps) => {
  return (
    <div
      className={cx(styles.th, props.className)}
      role="columnheader"
      style={style}>
      {props.children}
    </div>
  );
};

Cell.displayName = 'Table.Header.Cell';

Header.THead = Head;
Header.TH = Cell;
Header.TR = Row;