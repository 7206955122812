import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { colors } from '@/components/Theme/theme';
import { useAppSelector } from '@/store';

export const AuthStatus = () => {
  const navigate = useNavigate();
  const appState = useAppSelector(state => state.appState);

  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  if (!appState.authenticated) {
    return null;
  }

  const style = {
    btn: {
      display: `flex`,
      alignItems: `center`,
      gap: 3,
      color: colors.gray.dark1,
      fontFamily: `var(--font-bold)`,
      fontSize: 15,
    },
    icon: {
      color: colors.gray.dark1,
    },
  };

  return (
    <button
      onClick={handleLogout}
      style={style.btn}>
      <LogOut
        size={18}
        style={style.icon} />
      <div>Log Out</div>
    </button>
  );
};
