import type { HTMLAttributes } from 'react';
import { memo } from 'react';
import { cx } from '@/utils';
import styles from './style/GroupTable.module.css';

type Props = {
  className?: string;
} & ChildrenProps
 & HTMLAttributes<HTMLDivElement>;

export const GroupTableHeader = memo(({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={cx(styles.header, className)}>
      {children}
    </div>
  );
});

export const GroupTableHeaderRow = memo(({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={cx(styles.hr, className)}>
      {children}
    </div>
  );
});

export const GroupTableHeaderCell = memo(({
  children,
  className,
  ...props
}: Props) => {
  return (
    <div
      {...props}
      className={cx(styles.th, className)}>
      {children}
    </div>
  );
});