import { useCallback, useMemo } from 'react';
import type { ActionMeta, GroupBase, MultiValue, OptionProps } from 'react-select';
import Select, { components as ReactSelectComponents } from 'react-select';
import { useTheme } from '@/components/Theme';
import type { SourceFilter } from '@/types/chat.filters';
import * as Presentation from './Presentation';

type Props = {
  readOnly?: boolean;
  options: SourceFilter.Item[];
  value: SourceFilter.Item[];
  onChange: (value: SourceFilter.Item[], meta: ActionMeta<SourceFilter.Item>) => unknown;
};

export function Source({ options, readOnly, value, onChange }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const selected = useMemo(() => value, [value]);

  const handleChange = useCallback((value: MultiValue<SourceFilter.Item>, meta: ActionMeta<SourceFilter.Item>) => {
    onChange(Array.from(value), meta);
  }, [onChange]);

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    return obj.inputValue?.length ? `No source found` : null;
  }, []);

  return (
    <Presentation.Row>
      <Presentation.Label>Record Type</Presentation.Label>
      <Presentation.Value>
        <Select<SourceFilter.Item, true>
          styles={selectStyles}
          components={{
            Option: MenuOption,
          }}
          isDisabled={readOnly}
          theme={selectTheme}
          value={selected}
          isMulti
          options={options}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isSearchable={false}
          isClearable={false}
          placeholder={`Select sources...`}
          noOptionsMessage={handleNoOptionsMessage}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id}
          onChange={handleChange}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
}

function MenuOption(props: OptionProps<SourceFilter.Item, true, GroupBase<SourceFilter.Item>>) {
  return (
    <ReactSelectComponents.Option {...props}>
      <input
        name={props.data.id}
        type="checkbox"
        checked={props.isSelected} />
      {props.label}
    </ReactSelectComponents.Option>
  );
}