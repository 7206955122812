import { useCallback, useContext, memo } from 'react';
import styled from '@emotion/styled';
import { CapabilitiesContext } from '@/components/BrandInsights/context';
import { ChatResponsePane } from '@/components/Chat/ResponsePane';
import { StarRating, useUpdateQueryRatingMutation, OpenQueryFeedbackModalContext } from '@/components/Chat.Feedback';
import { MessageCitations } from '@/components/Chat.Message.Citations';
import { ChatHintType } from '@/enums/chat';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import type { Chat } from '@/types';
import { getQueryResponseAsText } from '@/utils';
import { ActiveChatSessionContext, MessageCitationsToggleContext } from './context';
import { useChatPermissions } from './hooks';
import { CopyButton } from './Message.CopyToClipboard';
import { GoToTraceUrl } from './Message.GoToTraceUrl';
import { RelatedQuestionsPane } from './QueryHints';

type Props = {
  item: Chat.Query;
  numCitations: number;
};

export const SystemUserMessageFooter = ({ item, numCitations }: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const chatPermissions = useChatPermissions();
  const capabilities = useContext(CapabilitiesContext);
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);

  const { mutateAsync: updateQueryRating } = useUpdateQueryRatingMutation({
    chatInstanceId: chat.id,
    queryIdentifier: item.identifier,
  });

  const openFeedbackModal = useContext(OpenQueryFeedbackModalContext);

  const copyToClipboard = useCopyToClipboard();

  const handleRatingClicked = useCallback((value: Chat.Rating) => {
    if (value <= 3) {
      return openFeedbackModal({
        queryIdentifier: item.identifier,
        chatInstanceId: chat.id,
        rating: value,
      });
    }

    return updateQueryRating(value);
  }, [
    item.identifier,
    updateQueryRating,
    openFeedbackModal,
    chat.id,
  ]);

  const handleCopy = useCallback(() => {
    copyToClipboard(getQueryResponseAsText(item.response));
  }, [copyToClipboard, item.response]);

  const view = {
    rating: chatPermissions.canModify,
    followUps: chatPermissions.canModify && !!item.suggestedFollowups.length,
    trace: capabilities.canViewTraces && !!item.langfuseTraceUrl,
  };

  return (
    <>
      <Layer2>
        <ActionsContainer>
          <ActionsLeft>
            <CopyButton onClick={handleCopy} />
          </ActionsLeft>

          <ActionsRight>
            {view.rating && (
              <StarRating
                value={item.rating}
                onChange={handleRatingClicked} />
            )}
            {view.trace && <GoToTraceUrl url={item.langfuseTraceUrl} />}
          </ActionsRight>
        </ActionsContainer>
      </Layer2>
      {!!numCitations && (
        <StyledCitationsPane
          title={`Citations (${item.citations.length})`}
          open={citationsOpen}
          toggleOpen={toggleCitationsOpen}>
          <StyledMessageCitations
            items={item.citations} />
        </StyledCitationsPane>
      )}
      {view.followUps && (
        <StyledRelatedQuestions hints={item.suggestedFollowups.map(f => ({
          displayValue: f,
          type: ChatHintType.Text,
        }))} />
      )}
    </>
  );
};

type ContainerProps = {
  className?: string;
} & ChildrenProps;

export const SystemUserMessageFooterContainer = memo(({ className, children }: ContainerProps) => {
  return (
    <Root className={className}>
      <Wrap>
        {children}
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  margin-top: 15px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRelatedQuestions = styled(RelatedQuestionsPane)`
  margin-top: 15px;
`;

const StyledCitationsPane = styled(ChatResponsePane)`
  margin-top: 15px;
`;

const StyledMessageCitations = styled(MessageCitations)`
  margin-top: 10px;
`;

const Layer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ActionsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const ActionsLeft = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const ActionsRight = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});