import { forwardRef, type Ref, memo } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { cx } from '@/utils';

type Props = {
  classes?: {
    link?: string;
    notlink?: string;
  };
  children:   React.ReactNode;
  to:         LinkProps['to'] | undefined;
} & Omit<LinkProps, 'to'>;

type RefProps = HTMLAnchorElement | HTMLSpanElement;

export const MaybeLink = memo(forwardRef<RefProps, Props>(({ classes = {}, ...props }: Props, ref: Ref<RefProps>) => {
  return props.to
    ? <Link
        ref={ref as Ref<HTMLAnchorElement>}
        {...props}
        className={cx(classes.link, props.className)} />
    : (
      <span
        ref={ref as Ref<HTMLSpanElement>}
        {...props}
        className={cx(classes.notlink, props.className)}>
        {props.children}
      </span>
    );
}));

MaybeLink.displayName = 'MaybeLink';