import qs from 'query-string';
import { config } from '@/config';
import { isDevEnv } from '@/config/utils';

const generateCookieTag = (data: SetCookieProps) => {
  const { expires, ...rest } = data;
  const srcUrl = `${config().FRONT_BASE_URL}/cookie.html?${qs.stringify(rest)}`;
  const tag = document.createElement('iframe');
  tag.setAttribute('src', srcUrl);
  tag.setAttribute('style', 'display:none');
  tag.setAttribute('height', '1');
  tag.setAttribute('width', '1');

  document.documentElement.append(tag);
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length == 2) return parts.pop().split(';').shift();
};

export const setCookie = (data: SetCookieProps) => {
  if (isDevEnv() || document.URL.startsWith(config().FRONT_BASE_URL)) {
    setRealCookie({
      path: '/',
      ...data,
    });
  } else {
    generateCookieTag(data);
  }
};

const setRealCookie = ({
  key,
  value: valueParam,
  ...cookieOptions
}: SetCookieProps): void => {
  let value = valueParam;

  if (Array.isArray(value) || Object.prototype.toString.call(value) === '[object Object]') {
    value = JSON.stringify(value);
  }

  let cookie = `${key}=${value}`;
  if (cookieOptions.expires) {
    cookie += `; Expires=${cookieOptions.expires.toUTCString()}`;
  }
  if (cookieOptions.path) {
    cookie += `; Path=${cookieOptions.path}`;
  }
  if (cookieOptions.domain) {
    cookie += `; Domain=${cookieOptions.domain}`;
  }
  if (cookieOptions.maxAge) {
    cookie += `; Max-Age=${cookieOptions.maxAge}`;
  }
  if (cookieOptions.sameSite) {
    cookie += `; SameSite=${cookieOptions.sameSite}`;
  }
  if (cookieOptions.secure) {
    cookie += '; Secure';
  }
  if (cookieOptions.httpOnly) {
    cookie += '; HttpOnly';
  }

  document.cookie = cookie;
};

export type SetCookieProps = {
  domain?: string;
  expires: Date;
  httpOnly?: boolean;
  maxAge?: number;
  key: string;
  path?: string;
  sameSite?: string;
  secure?: boolean;

  value: string;
};