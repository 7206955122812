import EventEmitter from 'events';
import { z } from 'zod';
import { getLocalStorage } from '@/lib/storage';
import type { AuthenticateCookie, AuthSource, AuthProviderEvent } from './auth-provider.base';
import { BaseAuthProvider } from './auth-provider.base';

export class MicrositeAuthProvider extends BaseAuthProvider {
  static #sessionMetaKey = 'msl-copilot:session-meta';
  #eventEmitter: EventEmitter;

  constructor() {
    super();
    this.#eventEmitter = new EventEmitter();
  }

  get isAuthenticated(): boolean {
    const session = this.#getSessionMeta();
    if (!session) return false;
    return session.authenticated && (Date.now() + (5 * 60 * 1000)) < session.expiry.getTime();
  }

  get expiry(): Date {
    const session = this.#getSessionMeta();
    if (!session) return null;
    return session.expiry;
  }

  async authenticate(data: AuthenticateCookie) {
    const previousIsAuthenticated = this.isAuthenticated;

    this.#storeSessionMeta({
      authenticated: data.sessionMeta.authenticated,
      expiry: data.sessionMeta.expiry,
    });

    if (!previousIsAuthenticated && data.sessionMeta.authenticated) {
      this.#emit({ name: 'auth/login' });
    }
  }

  async error(error: Error) {
    this.#emit({ name: 'auth/error', error });
  }

  get authSource(): AuthSource {
    return 'cookie';
  }

  async refreshToken() {
    return;
  }

  get isRefreshingToken() {
    return false;
  }

  get accessToken(): string {
    return null;
  }

  logout() {
    this.#destroy();
  }

  #emit(e: AuthProviderEvent) {
    return this.#eventEmitter.emit('auth', e);
  }

  addListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.addListener('auth', listener);

  }
  removeListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.removeListener('auth', listener);
  }

  #storeSessionMeta(session: SessionData) {
    const $storage = getLocalStorage();
    $storage.setItem(MicrositeAuthProvider.#sessionMetaKey, JSON.stringify(SessionData.parse(session)));
  }

  #getSessionMeta() {
    const $storage = getLocalStorage();
    try {
      return SessionData.parse(JSON.parse($storage.getItem(MicrositeAuthProvider.#sessionMetaKey)));
    } catch {
      $storage.removeItem(MicrositeAuthProvider.#sessionMetaKey);
      return null;
    }
  }

  #destroy() {
    const previousIsAuthenticated = this.isAuthenticated;

    const $storage = getLocalStorage();
    $storage.removeItem(MicrositeAuthProvider.#sessionMetaKey);

    if (previousIsAuthenticated) {
      this.#emit({ name: 'auth/logout' });
    }
  }
}

const SessionData = z.object({
  authenticated: z.boolean(),
  expiry: z.coerce.date(),
});
type SessionData = z.infer<typeof SessionData>;