import { File } from 'react-feather';
import styles from './style/PreviewUnavailable.module.css';

export const PreviewUnavailable = () => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <File className={styles.icon} />
        <div className={styles.text}>No preview available</div>
      </div>
    </div>
  );
};

export default PreviewUnavailable;