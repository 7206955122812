import { useContext } from 'react';
import { Skeleton } from '@mui/material';
import { cx } from '@/utils';
import { TableContext } from './Context';
import styles from './style/Table.module.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  loading?:   boolean;
  rowIndex?:  number;
  style?:     React.CSSProperties;
};

export const Cell = ({ style = {}, ...props }: Props) => {
  const ctx = useContext(TableContext);

  /**
   * Allow loading to be overridden.
   *
   * Use the table context loading value which is set
   * by the `loading` prop on <Table.Root /> as a convenience fallback.
   */
  const loading = typeof props.loading === 'boolean'
    ? props.loading
    : ctx?.loading;

  if (loading) {
    return (
      <Lazy
        className={props.className}
        rowIndex={props.rowIndex}
        style={style} />
    );
  }

  return (
    <Data
      className={props.className}
      style={style}>
      {props.children}
    </Data>
  );
};

Cell.displayName = 'Table.Body.Cell';

const Data = (props: Omit<Props, 'loading'>) => {
  return (
    <div
      className={cx(styles.td, props.className)}
      role="cell"
      style={props.style}>
      {props.children}
    </div>
  );
};

Data.displayName = 'Table.Body.Cell.Data';

const Lazy = (props: Omit<Props, 'children'>) => {
  return (
    <div
      className={cx(styles.c, props.className)}
      role="cell"
      style={props.style}>
      <Skeleton
        className={styles.cs}
        animation="pulse"
        height={20}
        sx={{
          animationDelay: `${props.rowIndex * 80}ms`,
          animationDuration: `1400ms`,
          width: '70%',
        }}
        variant="rounded" />
    </div>
  );
};

Lazy.displayName = 'Table.Body.Cell.Lazy';