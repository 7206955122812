import { config } from '@/config';
import type { BaseAuthProvider } from './auth-provider.base';
import { MicrositeAuthProvider } from './auth-provider.microsite';
import { VeevaAuthProvider } from './auth-provider.veeva';

let authProviderInstance: BaseAuthProvider;
export function getAuthProvider() {
  if (authProviderInstance) return authProviderInstance;

  const platform = config().APP_PLATFORM;
  switch (platform) {
    case 'microsite': {
      authProviderInstance = new MicrositeAuthProvider();
      break;
    }
    case 'veeva': {
      authProviderInstance = new VeevaAuthProvider();
      break;
    }
  }

  return authProviderInstance;
}