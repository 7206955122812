import cuid2 from '@paralleldrive/cuid2';
import { getLocalStorage } from '@/lib/storage';
import { getAuthProvider } from './auth-provider';
import type { OAuth2 as IOAuth2 } from './interfaces';
import * as $session from './session';
import * as $sessionHttp from './session-http';

export function authorize({ url }: IOAuth2.Authorize.Params) {
  window.location.href = url;
}

export function callback({ identifier, url }: IOAuth2.AuthorizeCallback.Params): Promise<IOAuth2.AuthorizeCallback.Result> {
  return $sessionHttp.oauth2({
    identifier,
    url,
    fingerprint: getOrCreateOAuthFlowFingerprint(),
  })
    .then(session => {
      clearOAuthFlowFingerprint();
      getAuthProvider().authenticate({
        authSource: 'cookie',
        sessionMeta: {
          authenticated: session.authenticated,
          expiry: session.expiry,
          // userId: session.userId,
        },
      });
      $session.setLastLoginStrategy({
        strategy: 'enterprise',
        identifier,
        endSessionUrl: session.endSessionUrl,
      });
      return Promise.resolve({
        type: 'redirect',
        returnUrl: session.returnUrl,
      });
    });
}

export const LocalStorageKeys = Object.freeze({
  OAuthFlowFingerprint: 'msl-copilot:oauth-fingerprint',
});

export function getOrCreateOAuthFlowFingerprint() {
  const $storage = getLocalStorage();
  try {
    const value = $storage.getItem(LocalStorageKeys.OAuthFlowFingerprint);
    if (value) return value;
    const fingerprint = cuid2.createId();
    $storage.setItem(LocalStorageKeys.OAuthFlowFingerprint, fingerprint);
    return fingerprint;
  } catch {
    const fingerprint = cuid2.createId();
    $storage.setItem(LocalStorageKeys.OAuthFlowFingerprint, fingerprint);
    return fingerprint;
  }
}

export function clearOAuthFlowFingerprint() {
  getLocalStorage().removeItem(LocalStorageKeys.OAuthFlowFingerprint);
}