import { Component } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { cssTransition, toast, ToastContainer } from 'react-toastify';
import type { ToastOptions, ToastComponentType } from './interfaces';
import styles from './style.module.css';
import { Error } from './Toast.Error';
import { Info } from './Toast.Info';
import { Success } from './Toast.Success';
import { Warn } from './Toast.Warn';

const Slide = cssTransition({
  enter: styles.enter,
  exit: styles.exit,
  collapse: false,
});

class Toast extends Component {
  private static render = (ToastComponent: ToastComponentType, options: ToastOptions) => {

    const run = () => {

      const autoClose = options.autoClose ?? 5000;

      const toastId = createId();

      const timer = setTimeout(() => toast.dismiss(toastId), autoClose);

      toast(<ToastComponent body={options.body} title={options.title} />, {
        toastId,
        autoClose,
        onClose: () => clearTimeout(timer),
        closeButton: false,
        transition: Slide,
      });

      return toastId;
    };

    return run();
  };

  static alert = (options: ToastOptions) => {
    return Toast.success(options);
  };

  static error = (options: ToastOptions) => {
    return Toast.render(Error, options);
  };

  static info = (options: ToastOptions) => {
    return Toast.render(Info, options);
  };

  static success = (options: ToastOptions) => {
    return Toast.render(Success, options);
  };

  static warn = (options: ToastOptions) => {
    return Toast.render(Warn, options);
  };

  render() {
    return (
      <div className={styles.root}>
        <ToastContainer closeOnClick />
      </div>
    );
  }
}

export default Toast;