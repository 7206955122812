import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { useDebounceValue } from '@/hooks/useDebounceValue';
import { ChatContextType } from '@/enums';
import { ContextSearchContext, HeaderContextContext } from './context';
import type { ContextSearchResultItem } from './interfaces';

type Props = ChildrenProps;

export const ChatContextSearchContainer = (props: Props) => {

  const [autocompleteValue, setAutoCompleteValue] = useState<string>('');
  const debouncedValue = useDebounceValue(autocompleteValue, 250);

  const {
    cancelPendingSearch,
    pendingContextType,
    pendingEverythingSearch,
  } = useContext(HeaderContextContext);
  const dispatch = useContext(ChatStateDispatchContext);

  useEffect(() => {
    if (pendingContextType || pendingEverythingSearch) {
      setAutoCompleteValue('');
    }
  }, [pendingContextType, pendingEverythingSearch]);

  const { data: results } = useQuery({
    queryKey: ['get:chat/context-search', debouncedValue],
    queryFn: ({ queryKey }) => {
      const q = queryKey[1];

      if (q.length < 2) return [];

      return api.contextSearch({
        q,
        type: pendingEverythingSearch
          ? ChatContextType.Global
          : pendingContextType,
      }).then(res => res.data?.results);
    },
    initialData: [] as ContextSearchResultItem[],
  });

  const handleItemSelected = useCallback((item: ContextSearchResultItem) => {
    return api.lookupContext({
      entityId: item.entityId,
      objectId: item.objectId,
      contextType: item.type,
    }).then(res => {
      dispatch({
        type: 'instance-context-changed',
        payload: {
          context: res.context,
        },
      });
      cancelPendingSearch();
    });
  }, [cancelPendingSearch, dispatch]);

  const ctxValue = {
    updateValue: setAutoCompleteValue,
    handleItemSelected,
    value: autocompleteValue,
    results,
  };

  return (
    <ContextSearchContext.Provider value={ctxValue}>
      {props.children}
    </ContextSearchContext.Provider>
  );
};