export enum KolSearchStatus {
  FormEntry = 'form-entry',
  FormSubmitted = 'form-submitted',
  FormSubmissionErrored = 'form-submission-errored',
  NoResultsReturned = 'no-results-returned',
  SingleResultReturned = 'single-result-returned',
  MultipleResultsReturned = 'results-returned',
  SearchCancelled = 'search-cancelled',
  ProfileSelectionSubmitted = 'profile-selection-submitted',
  ProfileSelected = 'profile-selected',
  ProfileSelectionErrored = 'profile-selection-errored',
  ProfileRecommendations = 'profile-recommendations',
  QuestionsBank = 'questions-bank',
}

export enum StrategicImperativeEnum {
  Empower = 'empower',
  Ignite = 'ignite',
  Activate = 'activate',
}

export enum KolProfileDataType {
  Associations = 'associations',
  Affiliations = 'affiliations',
  ClinicalTrials = 'clinical-trials',
  Collaborations = 'collaborations',
  // Conferences = 'conferences',
  ContactInfo = 'contact-info',
  Events = 'events',
  HCPInteractions = 'hcp-interactions',
  LeaderTopics = 'leader-topics',
  MunData = 'mun-data',
  Publications = 'publications',
  Sponsorships = 'sponsorships',
}

export const KolProfileDataTypeLabels: Record<KolProfileDataType, string> = {
  [KolProfileDataType.Associations]: 'Associations',
  [KolProfileDataType.Affiliations]: 'Affiliations',
  [KolProfileDataType.ContactInfo]: 'Contact Info',
  [KolProfileDataType.ClinicalTrials]: 'Clinical Trials',
  [KolProfileDataType.Collaborations]: 'Collaborations',
  // [KolProfileDataType.Conferences]: 'Conferences',
  [KolProfileDataType.Events]: 'Events',
  [KolProfileDataType.HCPInteractions]: 'HCP Interactions',
  [KolProfileDataType.LeaderTopics]: 'Leader Topics',
  [KolProfileDataType.MunData]: 'MUN Data',
  [KolProfileDataType.Publications]: 'Publications',
  [KolProfileDataType.Sponsorships]: 'Sponsorships',
};

export const KolProfileDataTypeTables: Record<KolProfileDataType, string[]> = {
  [KolProfileDataType.Associations]: ['vw_se_association'],
  [KolProfileDataType.Affiliations]: ['vw_se_address'],
  [KolProfileDataType.ContactInfo]: ['vw_physician_profile', 'vw_se_scientific_expert'],
  [KolProfileDataType.ClinicalTrials]: ['vw_se_clinical_trial', 'vw_se_clinical_trial_rltn'],
  [KolProfileDataType.Collaborations]: ['vw_se_company_collaboration'],
  // [KolProfileDataType.Conferences]: [],
  [KolProfileDataType.Events]: ['vw_se_medical_event', 'vw_se_event_attendee'],
  [KolProfileDataType.HCPInteractions]: [
    'vw_documented_interest_account',
    'vw_mdcl_insigt',
    'vw_micc_interactions',
    'vw_physician_msl_interactions',
  ],
  [KolProfileDataType.LeaderTopics]: [
    'vw_se_dgt_rch_focus_area',
    'vw_se_focus_area',
    'vw_se_clinical_leader',
  ],
  [KolProfileDataType.MunData]: ['vw_aggregated_mun_data', 'mun_call_pln_trgt'],
  [KolProfileDataType.Publications]: ['vw_se_publication', 'vw_se_publication_rltn'],
  [KolProfileDataType.Sponsorships]: ['vw_se_cmpny_sponsorship'],
};

export enum KolDataSource {
  HcpContactInfo = 'hcp-contact-info',
  IConnect = 'iConnect',
  MeAi = 'me-ai',
  VeevaLink = 'veeva-link',
}

export const KolDataSourceLabels: Record<KolDataSource, string> = {
  [KolDataSource.HcpContactInfo]: 'HCP Contact Info',
  [KolDataSource.IConnect]: 'iConnect',
  [KolDataSource.MeAi]: 'ME.AI',
  [KolDataSource.VeevaLink]: 'Veeva Link',
};

export const KolProfileDataTypeSourcesMap: Record<KolProfileDataType, KolDataSource> = {
  [KolProfileDataType.Associations]: KolDataSource.VeevaLink,
  [KolProfileDataType.Affiliations]: KolDataSource.VeevaLink,
  [KolProfileDataType.ClinicalTrials]: KolDataSource.VeevaLink,
  [KolProfileDataType.Collaborations]: KolDataSource.VeevaLink,
  [KolProfileDataType.ContactInfo]: KolDataSource.HcpContactInfo,
  // [KolProfileDataType.Conferences]: KolDataSource.VeevaLink,
  [KolProfileDataType.Events]: KolDataSource.VeevaLink,
  [KolProfileDataType.HCPInteractions]: KolDataSource.IConnect,
  [KolProfileDataType.LeaderTopics]: KolDataSource.VeevaLink,
  [KolProfileDataType.MunData]: KolDataSource.MeAi,
  [KolProfileDataType.Publications]: KolDataSource.VeevaLink,
  [KolProfileDataType.Sponsorships]: KolDataSource.VeevaLink,
};