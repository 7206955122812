import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { FilesTable, FilesTableDataContext, FilesTableLoadingContext } from '@/screens/Workspace.FilesTable';
import { TabViewTableSkeleton } from '@/components/TabView';
import type { AppLoadedPayload } from '@/store/interfaces';
import styles from './style/Workspace.Folder.module.css';

const FolderTabFiles = () => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : (
          <div className={styles.root}>
            <div className={styles.wrap}>
              <FilesTable />
            </div>
          </div>
          )
      }
    </div>
  );
};

type Params = {
  folderId: string;
};

const WithState = () => {
  const { entity } = useSelector((state: AppLoadedPayload) => state.workspace);
  const params = useParams<Params>();

  const query = useQuery({
    queryKey: [
      `get:workspaces/objects`,
      entity.id,
      +params.folderId,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFiles({
        workspaceId: entity.id,
        objectId: +params.folderId,
      });
    },
  });

  return (
    // @ts-ignore
    <FilesTableDataContext.Provider value={query.data?.items || []}>
      <FilesTableLoadingContext.Provider value={query.isInitialLoading}>
        <FolderTabFiles />
      </FilesTableLoadingContext.Provider>
    </FilesTableDataContext.Provider>
  );
};

export { WithState as FolderTabFiles };
export default WithState;