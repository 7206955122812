import type { ChangeEvent } from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { X } from 'react-feather';
import { Search } from 'react-feather';
import { usePopper } from 'react-popper';
import { Portal } from '@/components/Portal';
import { DropdownMenu, DropdownMenuItem } from '@/components/presentation';
import { useSameWidthModifier, useZIndexModifier } from '@/components/presentation/popper-modifiers';
import { Constants } from '@/components/Theme';
import { ChatContextType } from '@/enums/chat';
import { toTitleCase, trunc } from '@/utils';
import { ContextSearchContext, HeaderContextContext } from './context';
import type { ContextSearchResultItem } from './interfaces';

type Props = {
  className?: string;
};

export const ContextSearch = ({ className }: Props) => {

  const {
    handleItemSelected: onItemSelected,
    updateValue,
    value,
    results,
  } = useContext(ContextSearchContext);
  const { pendingContextType, cancelPendingSearch, pendingEverythingSearch } = useContext(HeaderContextContext);

  const [focused, setFocused] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const sameWidthModifier = useSameWidthModifier();
  const zIndexModifier = useZIndexModifier();

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [sameWidthModifier, zIndexModifier],
    placement: 'bottom-start',
  });

  const popperVisible = useMemo(() => {
    if (!focused) return false;

    return !!results.length;
  }, [
    focused,
    results,
  ]);

  const handleItemSelected = useCallback((item: ContextSearchResultItem) => (e: React.MouseEvent) => {
    onItemSelected(item);
    setFocused(false);
  }, [onItemSelected]);

  const handleBlur = useCallback((e: React.FocusEvent) => {
    if (dropdownMenuRef.current?.contains(e.relatedTarget as Node)) {
      return;
    }

    cancelPendingSearch();
  }, [cancelPendingSearch]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    updateValue(e.target.value);
  }, [updateValue]);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setFocused(false);
    }
  }, [referenceElement]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleCancel = useCallback(() => {
    cancelPendingSearch();
  }, [cancelPendingSearch]);

  const placeholder = useMemo(() => {
    if (!pendingContextType) return '';

    if (pendingContextType === ChatContextType.Global) {
      return 'Search Anything';
    }

    return `Search ${toTitleCase(pendingContextType)}s`;
  }, [pendingContextType]);

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}>
        <Input
          placeholder={placeholder}
          onBlur={handleBlur}
          onCancel={handleCancel}
          onChange={handleInputChange}
          onFocus={handleFocus}
          value={value} />
      </div>
      {popperVisible &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <DropdownMenu ref={dropdownMenuRef}>
                {results.map(result => (
                  <StyledDropdownMenuItem
                    key={result.entityId}
                    onClick={handleItemSelected(result)}>
                    {trunc(result.name, 75)}
                    {pendingEverythingSearch && (
                      <DropdownItemSubText>{toTitleCase(result.type)}</DropdownItemSubText>
                    )}
                  </StyledDropdownMenuItem>
                ))}
              </DropdownMenu>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};

type InputProps = {
  onCancel: () => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

function Input(props: InputProps) {

  return (
    <InputRoot>
      <InputLeft>
        <StyledSearchIcon size={IconSize} />
        <StyledInput {...props} autoFocus />
      </InputLeft>
      <StyledXIcon onClick={props.onCancel} size={IconSize} />
    </InputRoot>
  );
}

const IconSize = 19;
const InputMarginLeft = 6;
const RootHorizontalPadding = 8;

const InputRoot = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  color: theme.palette.black.main,
  backgroundColor: theme.palette.gray.light2,
  borderRadius: 4,
  height: Constants.Header.ItemHeight,
  width: 300,
  padding: `0 ${RootHorizontalPadding}px`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const InputLeft = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const StyledXIcon = styled(X)(({ theme }) => ({
  color: theme.palette.black.main,
  cursor: 'pointer',
  paddingLeft: 5,
  display: 'flex',
  flexShrink: 0,
}));

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.black.main,
  display: 'flex',
  flexShrink: 0,
}));

const StyledInput = styled.input(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: 14,
  lineHeight: 20,
  marginLeft: InputMarginLeft,
  textOverflow: 'ellipsis',
  transition: 'all 750ms ease',
  height: '100%',
  width: '100%',
  color: theme.palette.black.main,
  fontFamily: theme.fonts.regular,
}));

const StyledDropdownMenuItem = styled(DropdownMenuItem)(({ theme }) => ({
  display: 'block',
  fontFamily: theme.fonts.semiBold,
}));

const DropdownItemSubText = styled.div(({ theme }) => ({
  fontSize: 12,
  fontFamily: theme.fonts.semiBold,
  color: theme.palette.gray.main,
  marginTop: 3,
  // color: theme.palette.blue.main,
}));