import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, focusManager } from '@tanstack/react-query';
import { getAuthProvider } from '@/auth/auth-provider';
import type { AuthProviderEvent } from '@/auth/auth-provider.base';
import * as $sessionHttp from '@/auth/session-http';
import { useAppSelector } from '@/store';

type Props = {
  enabled: boolean;
};

export function useValidSessionChecker({ enabled }: Props) {
  const navigate = useNavigate();
  const userId = useAppSelector(state => state.user?.id);
  const [focused, setFocused] = useState<boolean>(focusManager.isFocused());

  const query = useQuery({
    queryKey: ['valid-session-checker'],
    queryFn: () => $sessionHttp.getSessionInfo(),
    refetchInterval: () => focused ? false : 15 * 60 * 1000,
    refetchIntervalInBackground: true,
    staleTime: 1000,
    enabled,
  });

  const authErrorListener = useCallback((e: AuthProviderEvent) => {
    if (e.name === 'auth/error') {
      navigate('/logout');
    }
  }, [navigate]);

  useEffect(() => {
    if (!query.data) return;

    if (!query.data.authenticated || !query.data.userId || query.data.userId !== userId) {
      navigate('/logout');
    }
  }, [query.data, userId, navigate]);

  const handleFocusChange = useCallback(() => setFocused(focusManager.isFocused()), []);

  useEffect(() => {
    const unsubscribe = focusManager.subscribe(handleFocusChange);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const $provider = getAuthProvider();
    $provider.addListener(authErrorListener);
    return () => {
      $provider.removeListener(authErrorListener);
    };
  }, [authErrorListener]);
}