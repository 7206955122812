import { useCallback } from 'react';

export const useCopyToClipboard = () => {
  return useCallback((text: string) => {
    return new Promise<boolean>((resolve, reject) => {
      navigator.clipboard.writeText(text)
        .then(
          () => resolve(true),
          err => reject(err),
        );
    });
  }, []);
};