import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
/* import { Pdf } from '@/components/Pdf'; */
import useFilePreviewData from '@/containers/Workspace.File.Preview/hooks/useFilePreviewData';
import useFilePreviewLoading from '@/containers/Workspace.File.Preview/hooks/useFilePreviewLoading';
/* import { WorkbookContainer } from '@/containers/Workspace.File.Preview/WorkbookContainer'; */
import { Card } from '@/components/presentation/Main';
/* import Workbook from './Workbook'; */
import ImagePreview from './ImagePreview';
/* import PreviewInProgress from './InProgress'; */
/* import { AudioPreview, VideoPreview } from './Preview.Media'; */
import PreviewUnavailable from './Preview.Unavailable';
import styles from './style/File.Preview.module.css';

const useQueryOptions = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return {
    page: +query.get('p') || undefined,
  };
};

export const FilePreview = () => {
  const data = useFilePreviewData();
  const loading = useFilePreviewLoading();
  const queryOptions = useQueryOptions();

  const renderBody = useCallback(() => {
    if (loading) return null;

    if (data.inProgress) {
      return null;
      /*
      return <PreviewInProgress />;
      */
    }

    if (data.workbookDataUrl) {
      return null;
      /*
      return (
        <WorkbookContainer url={data.workbookDataUrl}>
          <Workbook />
        </WorkbookContainer>
      );
      */
    }

    if (data.pdfPreviewUrl) {
      return null;
      /*
      return (
        <Pdf url={data.pdfPreviewUrl} page={queryOptions.page} />
      );
      */
    }

    if (data.imgPreviewUrl) {
      return (
        <ImagePreview url={data.imgPreviewUrl} />
      );
    }

    if (data.audioUrl) {
      return null;
      /*
      return (
        <AudioPreview url={data.audioUrl} />
      );
      */
    }

    if (data.videoUrl) {
      return null;
      /*
      return (
        <VideoPreview url={data.videoUrl} />
      );
      */
    }

    return <PreviewUnavailable />;
  }, [
    queryOptions,
    data,
    loading,
  ]);

  return (
    <Card className={styles.root}>
      <div className={styles.body}>
        {renderBody()}
      </div>
    </Card>
  );
};