import { getAuthenticatedClient } from './client';
import type * as Workspaces from './interfaces/workspaces';
import { safeJsonDate } from './transformers/date';

export const fetchWorkspaceFiles = (data: Workspaces.FetchWorkspaceFiles.Request) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFiles.Response<string>>(`/workspaces/${data.workspaceId}/objects/${data.objectId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeJsonDate(x.expiresOn),
      })),
      items: res.data.items.map(x => ({
        bookmarked: x.bookmarked,
        object: {
          ...x.object,
          createdOn: safeJsonDate(x.object.createdOn),
          expiresOn: safeJsonDate(x.object.expiresOn),
        },
        file: x.file ? {
          ...x.file,
          createdOn: safeJsonDate(x.file.createdOn),
        } : null,
        folder: x.folder ? {
          ...x.folder,
          createdOn: safeJsonDate(x.folder.createdOn),
        } : null,
        meta: x.meta,
        tags: x.tags,
      })),
    }));
};

export const fetchWorkspaceFile = (data: { fileId: number }) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFile.Response<string>>(`/workspaces/files/${data.fileId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeJsonDate(x.expiresOn),
      })),
      object: {
        ...res.data.object,
        createdOn: safeJsonDate(res.data.object.createdOn),
        expiresOn: safeJsonDate(res.data.object.expiresOn),
      },
      file: {
        ...res.data.file,
        createdOn: safeJsonDate(res.data.file.createdOn),
        lastDownloadedOn: safeJsonDate(res.data.file.lastDownloadedOn),
        lastModifiedOn: safeJsonDate(res.data.file.lastModifiedOn),
        publishedOn: safeJsonDate(res.data.file.publishedOn),
      },
      preview: {
        audioUrl: null,
        imgPreviewUrl: null,
        inProgress: false,
        pdfPreviewUrl: '',
        videoUrl: null,
        workbookDataUrl: null,
      },
    }));
};

export const fetchWorkspaceFolder = (data: { folderId: number }) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFolder.Response<string>>(`/workspaces/folders/${data.folderId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeJsonDate(x.expiresOn),
      })),
      object: {
        ...res.data.object,
        createdOn: safeJsonDate(res.data.object.createdOn),
        expiresOn: safeJsonDate(res.data.object.expiresOn),
      },
      folder: {
        ...res.data.folder,
        createdOn: safeJsonDate(res.data.folder.createdOn),
      },
    }));
};