import { config } from '@/config';
import * as $microsite from '@/router/microsite.router';
import * as $veeva from '@/router/veeva.router';

export function createRouter() {
  const platform = config().APP_PLATFORM;
  switch (platform) {
    case 'veeva': return $veeva.createRouter();
    default: return $microsite.createRouter();
  }
}