import { forwardRef, useLayoutEffect, useMemo } from 'react';
import { cx } from '@/utils';
import { TableContext } from './Context';
import styles from './style/Table.module.css';

type Props = {
  EmptyComponent?:  React.ComponentType;
  RootProps?:       unknown;
  children:         React.ReactNode;
  className?:       string;
  empty?:           boolean;
  loading?:         boolean;
  maxScrollHeight?: number;
  pageSize?:        number;
};

export const Root = forwardRef(({ EmptyComponent = null, RootProps = {}, children, className, empty = false, loading = false, pageSize = 25, ...props }: Props, ref: React.Ref<HTMLDivElement>) => {
  const value = useMemo(() => ({
    EmptyComponent,
    empty: loading ? false : !!empty,
    loading,
    pageSize,
  }), [EmptyComponent, empty, loading, pageSize]);
  useLayoutEffect(() => {
    const scrollheight = props.maxScrollHeight ? `${props.maxScrollHeight}px` : undefined;

    document.documentElement.style.setProperty('--top-row-head', scrollheight ?? 'var(--navbar-height)');

    return () => {
      document.documentElement.style.removeProperty('--top-row-head');
    };
  }, [props.maxScrollHeight]);

  const { className: rootClass, ...root } = RootProps as React.HTMLAttributes<HTMLDivElement>;

  return (
    <div
      {...root as React.HTMLAttributes<HTMLDivElement>}
      className={cx(styles.table, className, rootClass)}
      ref={ref}
      role="table">
      <TableContext.Provider value={value}>
        {children}
      </TableContext.Provider>
    </div>
  );
});

Root.displayName = 'Table.Root';