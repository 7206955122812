import { useContext } from 'react';
import { Search } from 'react-feather';
import { Lightbulb } from '@/components/Chat.Widget/Lightbulb';
import { colors } from '@/components/Theme/theme';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { color } from '@/utils';
import styles from './style/Theme.Color.module.css';
import { Color } from './Theme.Color';

type Props = unknown;

export const Theme = (props: Props) => {
  const { query, theme } = useContext(AppThemingContext);

  if (query.isInitialLoading) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Buttons</h2>
        <div className={styles.main}>
          <div className={styles.cols}>
            <div className={styles.name}>Elements</div>
            <div className={styles.hex}>Hex</div>
            <div className={styles.pre}>Preview</div>
          </div>
          <Color
            initialValue={theme?.color?.primary || colors.blue.main}
            label="Chat Buttons & Icons"
            name="primary"
            PreviewElement={Button} />
          <Color
            initialValue={theme?.color?.bubble || colors.purple.main}
            label="Chat Bubble"
            name="bubble"
            PreviewElement={Bubble} />
          <Color
            initialValue={theme?.color?.prompt || colors.gray.light1}
            label="Chat Followup Prompts"
            name="prompt"
            PreviewElement={Prompt} />
        </div>
      </div>
    </div>
  );
};

Theme.displayName = 'Branding.Theme';

type ColorProps = {
  value: string;
};

const Button = (props: ColorProps) => {
  return (
    <button
      className={styles.btn}
      style={{
        backgroundColor: props.value,
        color: color.isDark(props.value) ? `var(--pri-01)` : `var(--black-l)`,
      }}>
      New Chat
    </button>
  );
};

const Bubble = (props: ColorProps) => {
  return (
    <div
      className={styles.bubble}
      style={{ backgroundColor: props.value }}>
      <Lightbulb
        color={props.value}
        size={18} />
    </div>
  );
};

const Prompt = (props: ColorProps) => {
  const { theme } = useContext(AppThemingContext);

  const style = {
    btn: {
      backgroundColor: props.value,
      color: color.isDark(props.value) ? `var(--pri-01)` : `var(--black-l)`,
    },
    icon: {
      color: theme?.color?.primary || colors.blue.main,
    },
  };

  return (
    <div
      className={styles.prompt}
      style={style.btn}>
      <Search
        className={styles.icon}
        style={style.icon} />
      {`Search for Profiles`}
    </div>
  );
};