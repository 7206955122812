import * as $api from '@/api/queries';
import { useQueryDownloader } from '@/containers/QueryDownloader/hooks';

type Params = {
  chatInstanceId: number;
  queryIdentifier: string;
};

export const useDownloadQueryTable = (params: Params) => {

  return useQueryDownloader({
    queryKey: [
      `query:download-table`,
      params,
    ],
    queryFn: ({ queryKey: [, data] }) => {
      return $api.downloadQueryTable({
        chatInstanceId: data.chatInstanceId,
        queryIdentifier: data.queryIdentifier,
      });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.fileName,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

};