export const cx = (...names: ClassValue[]): string => {
  const reduce = (obj: ClassDictionary) => Object.keys(obj)
    .reduce((acc, key) => obj[key] ? acc.concat(key) : acc, [] as string[]);

  return names.reduce((acc: string[], arg) => {
    if (!arg) {
      return acc;
    } else if (isValid(arg)) {
      return [...acc, arg];
    } else if (isArray(arg)) {
      return [...acc, cx(...arg)];
    } else if (isObject(arg)) {
      return [...acc, ...reduce(arg)];
    } else {
      return acc;
    }
  }, [])
    .join(' ');
};

function isObject(arg: ClassValue): arg is ClassDictionary {
  return typeof arg === 'object';
}

function isArray(arg: ClassValue | ClassValue[]): arg is ClassValue[] {
  return !!(Array.isArray(arg) && arg.length);
}

function isValid(arg: ClassValue): arg is string | number {
  const type = typeof arg;
  return type === 'string'
    || type === 'number';
}

type ClassValue =
  string
  | number
  | ClassDictionary
  | string[]
  | undefined
  | null
  | false;

type ClassDictionary = {
  [key: string]: boolean | undefined | null;
};