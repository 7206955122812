/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { compile } from 'html-to-text';
import ReactMarkdown from 'react-markdown';
import { StyledAnchor } from '@/components/presentation/Link';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';
import { ChatCitationType, ChatHintType } from '@/enums';
import { KolProfileDataType } from '@/enums/kol';
import { ReportFormat } from '@/enums/reports';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useHover } from '@/hooks/useHover';
import type { Chat } from '@/types';
import type { KolProfile } from '@/types/kol';
import { possessive, pluralize } from '@/utils/format';
import { MessageCitations } from '../Chat.Message.Citations';
import { ChatStateDispatchContext } from '../Chat.State';
import { TextHintIcon, TextHintWrap } from '../presentation';
import { CitationsContext, KolFollowupQuestionsContext, KolProfileContext, MessageCitationsToggleContext } from './context';
import { useDownloadKolProfile } from './hooks/useDownloadKolProfile';
import { CopyButton } from './Message.CopyToClipboard';
import { DownloadButton } from './Message.Download';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { SystemMessageContainer } from './Message.System.Container';
import { RelatedQuestionsPane } from './QueryHints';
import { ChatResponsePane } from './ResponsePane';

const ProfileItem = styled.div({
  marginBottom: 20,
});

const htmlToText = compile({
  preserveNewlines: true,
  wordwrap: false,
  selectors: [{

    selector: `div${ProfileItem}`,
    format: 'block',
    options: { leadingLineBreaks: 2, trailingLineBreaks: 2 },
  }],
});

const KolProfileMessage = () => {

  const profile = useContext(KolProfileContext);
  const profileRef = useRef<HTMLDivElement>(null);
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);
  const citations = useContext(CitationsContext);
  const followups = useContext(KolFollowupQuestionsContext);
  const { download } = useDownloadKolProfile({
    kol: {
      id: profile.id,
      name: profile.name,
    },
  });

  const copy = `Sure, here is the HCP profile for "${profile.name}"`;

  const renderProfileLink = useCallback(() => {
    if (!profile.profileLink) return null;

    return (
      <>
        (<StyledAnchor
          href={profile.profileLink}
          target="_blank"
          rel="noreferrer">Link
        </StyledAnchor>)
      </>
    );
  }, [profile.profileLink]);

  const copyToClipboard = useCopyToClipboard();

  const handleCopy = useCallback(() => {
    if (!profileRef.current) return;
    const value = htmlToText(profileRef.current.innerHTML);
    copyToClipboard(value);
  }, [copyToClipboard]);

  const handleDownload = useCallback(() => {
    download({
      title: `Generating Export`,
      name: `${profile.name} Profile`,
      extension: ReportFormat.Word,
    });
  }, [download, profile.name]);

  return (
    <MessageContainer>
      <BasicSystemUserMessage>
        <MessageBody>

          <Copy>{copy} {renderProfileLink()}</Copy>

          <KolProfileDetails ref={profileRef} profile={profile} />

          <Footer>
            <DownloadButton onClick={handleDownload} />
            <CopyButton onClick={handleCopy} />
          </Footer>

          <StyledCitationsPane
            title={`Citations (${citations.length})`}
            open={citationsOpen}
            toggleOpen={toggleCitationsOpen}>
            <StyledMessageCitations
              items={citations} />
          </StyledCitationsPane>

          <StyledRelatedQuestions hints={followups.map(f => ({
            displayValue: f,
            type: ChatHintType.Text,
          }))} />

        </MessageBody>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

const KolProfileMessageContainer = () => {

  const profile = useContext(KolProfileContext);

  const citations = useMemo((): Chat.Citation<ChatCitationType.KolProfile>[] => {

    const sources: KolProfileDataType[] = [KolProfileDataType.ContactInfo];

    if (profile.numClinicalTrials > 0) {
      sources.push(KolProfileDataType.ClinicalTrials);
    }
    if (profile.numPublications > 0) {
      sources.push(KolProfileDataType.Publications);
    }
    if (profile.events?.length) {
      sources.push(KolProfileDataType.Events);
    }
    if (profile.affiliations?.length) {
      sources.push(KolProfileDataType.Affiliations);
    }
    if (profile.interactions.total) {
      sources.push(KolProfileDataType.HCPInteractions);
    }
    if (profile.recommendations?.length) {
      sources.push(KolProfileDataType.MunData);
    }
    if (profile.numDigitalFocusAreas || profile.numClinicalFocusAreas || profile.numScientificFocusAreas) {
      sources.push(KolProfileDataType.LeaderTopics);
    }

    return [{
      id: profile.id.toString(),
      link: profile.profileLink,
      metadata: {
        kolId: profile.id,
        kolName: profile.name,
        sources: sources.map(type => ({
          lastUpdatedOn: new Date(),
          type,
        })),
      },
      ordinal: 1,
      score: null,
      snippets: [],
      title: `${profile.name} 360 Profile`,
      type: ChatCitationType.KolProfile,
    }];
  }, [profile]);

  return (
    <SystemMessageContainer citations={citations}>
      <KolProfileMessage />
    </SystemMessageContainer>
  );
};

export { KolProfileMessageContainer as KolProfileMessage };

type DetailsProps = {
  profile: KolProfile;
};

const KolProfileDetails = memo(forwardRef<HTMLDivElement, DetailsProps>(({ profile }, ref) => {

  const [hoveringSection, setHoveringSection] = useState<string>(null);

  const handleMouseEnterSection = useCallback((label: string) => () => {
    setHoveringSection(label);
  }, []);

  const handleMouseLeaveSection = useCallback(() => {
    setHoveringSection(null);
  }, []);

  const renderTextField = useCallback((label: string, value: string) => {
    if (!value) return null;

    return (
      <ProfileItem>
        <Label>{label}</Label>
        {value}
      </ProfileItem>
    );
  }, []);

  const renderTextListField = useCallback(({ label, items, learnMore, aiGenerated = false }: RenderTextListProps) => {

    if (!items.length) return null;

    const isHoveringOver = hoveringSection === label;

    return (
      <ProfileItem onMouseEnter={handleMouseEnterSection(label)} onMouseLeave={handleMouseLeaveSection}>
        <Label>
          {label}
          {learnMore && <LearnMore open={isHoveringOver} question={learnMore} />}
          {aiGenerated && <AISymbol />}
        </Label>
        <StyledList>
          {items.map((item, index) => (
            <TextListItem
              key={index}
              value={item.value}
              learnMore={item.learnMore} />
          ))}
        </StyledList>
      </ProfileItem>
    );
  }, [hoveringSection, handleMouseEnterSection, handleMouseLeaveSection]);

  const renderLeadershipField = useCallback(() => {

    const label = 'Clinical & Research Activity';

    const congressAbstractionCutoffs = [50, 100, 150, 250, 300].reverse();

    const estAbstractions = congressAbstractionCutoffs.find(cutoff => profile.numCongressAbstracts >= cutoff);

    const items = [
      profile.numClinicalTrials && {
        value: `${profile.numClinicalTrials} Clinical Trials`,
        learnMore: `I'd like to learn more about the clinical trials ${profile.name} participated in.`,
      },

      profile.numPublications && profile.numCongressAbstracts && {
        value: `${profile.numPublications} Publications plus ${estAbstractions ? `> ${estAbstractions}` : profile.numCongressAbstracts} congress abstracts`,
        learnMore: `I'd like to learn more about the publications ${profile.name} has appeared in.`,
      },
      profile.numPublications && !profile.numCongressAbstracts && {
        value: `${profile.numPublications} Publications`,
        learnMore: `I'd like to learn more about the publications ${profile.name} has appeared in.`,
      },
      profile.numLeadAuthorPublications && {
        value: `${profile.numLeadAuthorPublications} Lead Author Publications`,
        learnMore: `I'd like to learn more about publications where ${profile.name} was the lead author.`,
      },
    ].filter(Boolean);

    return renderTextListField({ label, items });
  }, [profile, renderTextListField]);

  const renderInteractionsSummary = useCallback(() => {
    if (!profile.interactions) return null;

    const label = 'Interactions Summary';
    const displayName = 'HCP';

    const hasFbmsInteractions = !!profile.interactions.numFbmsInteractions;
    const hasContactCenterInteractions = !!profile.interactions.numContactCenterInteractions;
    const hasMedicalInsights = !!profile.interactions.numMedicalInsights;
    const hasDocumentedInterests = !!profile.interactions.numDocumentedInterests;

    const items = [
      {
        value: hasFbmsInteractions
          ? `${displayName} has had ${profile.interactions.numFbmsInteractions} interactions with MSLs.`
          : `${displayName} has not had any MSL interactions.`,
        learnMore: hasFbmsInteractions && `I would like to learn more about the interactions ${profile.name} has had with MSLs.`,
      },
      {
        value: hasContactCenterInteractions
          ? `${displayName} has had ${profile.interactions.numContactCenterInteractions} interactions with the contact center.`
          : `${displayName} has not had any interactions with the contact center.`,
        learnMore: hasContactCenterInteractions && `I would like to learn more about the interactions ${profile.name} has had with the contact center.`,
      },
      {
        value: hasMedicalInsights
          ? `There are ${profile.interactions.numMedicalInsights} records of medical insights ${displayName} has expressed.`
          : `${displayName} has not expressed any medical insights.`,
        learnMore: hasMedicalInsights && `I would like to learn more about the medical insights ${profile.name} has expressed.`,
      },
      {
        value: hasDocumentedInterests
          ? `${displayName} has ${profile.interactions.numDocumentedInterests} records expressing documented interest.`
          : `${displayName} has not expressed any documented interests.`,
        learnMore: hasDocumentedInterests && `I would like to learn more about the documented interests ${profile.name} has expressed.`,
      },
      {
        value: profile.interactions.withMe
          ? `You have interacted with ${displayName} ${profile.interactions.withMe} ${pluralize('time', profile.interactions.withMe)} in the last 3 months.`
          : `You have not interacted with ${displayName} in the last 3 months.`,
        learnMore: !!profile.interactions.withMe && `I would like to learn more about any recent interactions I've had with ${profile.name}.`,
      },
      // {
      //   value: profile.interactions.total
      //     ? `${displayName} has a total of ${profile.interactions.total} ${pluralize('interaction', profile.interactions.total)} across all channels.`
      //     : `${displayName} has no recorded interactions.`,
      //   learnMore: !!profile.interactions.total && `I would like to learn more about any recorded interactions with ${profile.name}.`,
      // },
    ];

    return renderTextListField({ label, items });
  }, [profile.interactions, profile.name, renderTextListField]);

  const renderTopics = useCallback(() => {

    if (!profile.numScientificFocusAreas && !profile.numClinicalFocusAreas && !profile.numDigitalFocusAreas) return null;

    const label = 'Leader Topics';

    const items = [
      profile.numScientificFocusAreas && {
        value: `${profile.numScientificFocusAreas} Scientific Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} scientific leader topics.`,
      },
      profile.numClinicalFocusAreas && {
        value: `${profile.numClinicalFocusAreas} Clinical Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} clinical leader topics.`,
      },
      profile.numDigitalFocusAreas && {
        value: `${profile.numDigitalFocusAreas} Digital Leader Topics`,
        learnMore: `I would like to learn more about ${possessive(profile.name)} digital leader topics.`,
      },
    ].filter(Boolean);

    return renderTextListField({ label, items });
  }, [profile.numScientificFocusAreas, profile.numClinicalFocusAreas, profile.numDigitalFocusAreas, profile.name, renderTextListField]);

  const displayName = useMemo(() => {
    if (!profile.qualifications.length) return profile.name;

    const sortedQualifications = profile.qualifications.sort((a, b) => a.localeCompare(b));
    return `${profile.name}, ${sortedQualifications.join(', ')}`;
  }, [profile.name, profile.qualifications]);

  const renderConferencePresence = useCallback(() => {
    const label = 'Conference Presence';
    const learnMore = `I'd like to learn more about ${possessive(profile.name)} conference presence.`;

    if (profile.upcomingEvents?.length) {
      return renderTextListField({
        label,
        items: profile.upcomingEvents.map(e => ({ value: e.name })),
        learnMore,
      });
    }

    if (profile.events?.length) {
      return renderTextListField({
        label,
        items: profile.events.map(e => {
          const roles = e.roles.map(r => r.position).map((i, v, a) => a.indexOf(i) === v ? i : null).filter(Boolean).join(', ');
          return {
            value: `${e.name} (${roles})`,
          };
        }),
        learnMore,
      });
    }

    return null;
  }, [
    profile.events,
    profile.name,
    profile.upcomingEvents,
    renderTextListField,
  ]);

  const renderGuidelines = useCallback(() => {
    const copy = `Metrics are based on available data sources, which may not fully represent HCP activity. Please leverage this information directionally to supplement your local market knowledge.`;

    return <Guidelines>{copy}</Guidelines>;
  }, []);

  const renderRecommendationReason = useCallback(() => {
    if (!profile.recommendations?.length) return null;

    const label = `Recommended because patients have the following unmet medical needs:`;

    return (
      <ProfileItem>
        <Label>{label}</Label>
        <Recommendations>
          <StyledList>
            {profile.recommendations.map((r, i) => (
              <Fragment key={i}>
                <StyledListItem><strong>{r.reason}</strong></StyledListItem>
                <StyledList>
                  {r.items.map((item, index) => (
                    <StyledListItem key={index}>
                      <ReactMarkdown css={markdownCss}>{item}</ReactMarkdown>
                    </StyledListItem>
                  ))}
                </StyledList>
              </Fragment>
            ))}
          </StyledList>
        </Recommendations>
      </ProfileItem>
    );
  }, [profile.recommendations]);

  return (
    <div ref={ref}>
      {renderTextField('Name', displayName)}
      {renderTextField('Designation', profile.title)}
      {renderTextField('Primary Affiliation', profile.affiliations.map(m => m.name).join('\n'))}
      {renderRecommendationReason()}
      {renderTopics()}
      {renderLeadershipField()}

      {renderTextListField({
        label: 'Key Insights',
        items: profile.keyInsights.map(m => ({ value: m })),
        aiGenerated: true,
      })}
      {renderConferencePresence()}
      {renderTextListField({
        label: 'Key Discussion Questions',
        items: profile.keyDiscussionQuestions.map(m => ({ value: m })),
        aiGenerated: true,
      })}
      {renderInteractionsSummary()}
      {renderGuidelines()}
    </div>
  );
}));

type RenderTextListProps = {
  label: string;
  items: ListItemProps[];
  learnMore?: string;
  aiGenerated?: boolean;
};

type LearnMoreProps = {
  open: boolean;
  question: string;
};

const LearnMore = memo(({ open, question }: LearnMoreProps) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const textRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    dispatch({
      type: 'input-changed',
      payload: { value: question },
    });
  }, [dispatch, question]);

  useEffect(() => {
    const textEl = textRef.current;

    const handleTransitionEnd = () => {
      textEl.style.opacity = '1';
    };

    const handleTransitionStart = () => {
      textRef.current.style.opacity = '0';
    };

    if (textEl) {
      textEl.addEventListener('transitionstart', handleTransitionStart);
      textEl.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (textEl) {
        textEl.removeEventListener('transitionstart', handleTransitionStart);
        textEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, []);

  return (
    <LearnMoreRoot onClick={handleClick}>
      <StyledLearnMoreWrap>
        <StyledTextHintIcon /> <LearnMoreText open={open} ref={textRef}>{open ? 'Learn More' : ''}</LearnMoreText>
      </StyledLearnMoreWrap>
    </LearnMoreRoot>
  );
});

type ListItemProps = {
  learnMore?: string;
  value: React.ReactNode;
};

const TextListItem = memo(({ learnMore, value }: ListItemProps) => {

  const [ref, isHovering] = useHover<HTMLLIElement>(!!learnMore);

  return (
    <StyledListItem ref={ref}>
      {value}
      {learnMore && <LearnMore open={isHovering} question={learnMore} />}
    </StyledListItem>
  );
});

const AIRoot = styled.div(({ theme }) => {
  const { Text } = Presentation.Colors(theme);
  return {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 22,
    width: 22,
    marginLeft: 5,
    padding: 6,
    border: `2px solid ${Text.enabled.hover.backgroundColor}`,
  };
});

const AIText = styled.div(({ theme }) => {
  const { TextIcon } = Presentation.Colors(theme);
  return {
    color: TextIcon.enabled.color,
    fontFamily: theme.fonts.bold,
    fontSize: 11,
    marginRight: 1,
    marginBottom: 1,
  };
});

const AISymbol = memo(() => (
  <AIRoot>
    <AIText>AI</AIText>
  </AIRoot>
));

const Guidelines = styled.em({
  marginTop: 15,
});

const StyledRelatedQuestions = styled(RelatedQuestionsPane)({
  marginTop: 15,
});

const MessageBody = styled.div({
  width: 600,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

const Copy = styled.div({
  marginBottom: 15,
});

const Label = styled.div(({ theme }) => ({
  marginBottom: 5,
  fontFamily: theme.fonts.semiBold,
  display: 'flex',
  alignItems: 'center',
}));

const StyledList = styled.ul({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 30,
});

const StyledListItem = styled.li({
  marginBottom: 5,
});

const Footer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  gap: 10,
});

const LearnMoreRoot = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: 5,
});

const StyledLearnMoreWrap = styled(TextHintWrap)({
  fontSize: 13,
  padding: `0 1px 0 3px`,
  alignItems: 'center',
  height: 22,
  borderRadius: 5,
});

const LearnMoreText = styled.span<{ open: boolean }>(({ open }) => ({
  marginLeft: 3,
  opacity: 0,
  width: open ? 70 : 0,
  transition: 'width .1s ease-in-out',
  overflowX: 'hidden',
}));

const StyledTextHintIcon = styled(TextHintIcon)({
  marginRight: 0,
});

const StyledCitationsPane = styled(ChatResponsePane)({
  marginTop: 15,
});

const StyledMessageCitations = styled(MessageCitations)({
  marginTop: 10,
});

const Recommendations = styled.div({
  marginTop: 10,
});

const markdownCss = css({
  whiteSpace: 'normal',
});
